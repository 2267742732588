import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { setActiveProjectId, setInnerNav, setPageTitle, loadSpecificProject, setActiveModelId, setProjectFormMode } from "../actions/context";
import ModelListSidebar from '../components/model/ModelListSidebar';
import ModelSummary from '../components/model/ModelSummary';
import GenericUtils from '../utility/generic.utils';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ModelListPage = (props: any) => {
  const [selectedModel, setSelectedModel] = useState();
  const [models, setModels] = useState([]);
  const { activeProject, activeProjectId, activeModelId, newModelId, projects } = useSelector((state: any) => state.context);
  const { projectId } = useParams() as any;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  // Set the page title when switching projects.
  useEffect(() => {
    if (projectId && Number.isInteger(parseInt(projectId))) {
      const uuid = GenericUtils.getProjectUuid(parseInt(projectId), projects);
      if (uuid) {
        dispatch(setActiveProjectId(uuid));
        dispatch(loadSpecificProject(uuid));
      }
    }
  }, [projectId])

  useEffect(() => {
    dispatch(setInnerNav(false));
  }, []);

  useEffect(() => {
    if (activeProject) {
      dispatch(setPageTitle(activeProject.projectName));
      setModels(activeProject.models);

      if (activeModelId) {
        setSelectedModel(activeProject.models.find((model: any) => model.uuid === activeModelId));
      }
    }
  }, [activeProject, activeModelId]);

  const goNewModel = () => {
    dispatch(setActiveModelId('empty'));
    dispatch(setProjectFormMode('createModel'));
    history.push(`/project/${projectId}/models/create`);
  }

  const changeModel = (val: number) => {
    setSelectedModel(models[val]);
    dispatch(setActiveModelId(models[val]['uuid']));
  }

  return (
    <>
      <Box sx={{ display: 'flex', width: 1 }}>
        <Box sx={{ width: '230px' }}>
          <ModelListSidebar
            models={models}
            changeModel={changeModel}
            activeModelId={activeModelId}
            goNewModel={goNewModel}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          {selectedModel &&
            <ModelSummary model={selectedModel} activeProject={activeProject} />
          }
        </Box>
      </Box>
    </>
  );
}

export default ModelListPage;
