import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

let PrivateRoute: ({ component, isSignedIn, ...rest }: { component: any; auth: any; [p: string]: any }) => any;
PrivateRoute = ({ component, isSignedIn, ...rest }) => {
	let ComponentToRender = component;

	return (
		<Route
			{...rest}
			render={props =>
				isSignedIn
					? (
					<ComponentToRender {...props} />
				)
					: (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

const mapStateToProps = (state: any, ownProps: any) => ({ isSignedIn: state.auth.isSignedIn });
export default withRouter(connect(mapStateToProps)(PrivateRoute));
