import * as React from "react";
import * as ReactDOM from "react-dom";
import ReduxRoot from "./ReduxRoot";
import { hotjar } from 'react-hotjar';

const rootEl = document.getElementById("root");
ReactDOM.render(<ReduxRoot />, rootEl);

if (process.env.NODE_ENV !== 'development') {
    hotjar.initialize(2029634, 6);
}

// comment in for PWA with service worker in production mode
// registerServiceWorker();
