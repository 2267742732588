import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider, createTheme} from "@mui/material";
import * as React from "react";
import {GlobalStyles} from "@mui/material";

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    body3: any;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    darker: PaletteOptions['primary'];
  }
  interface Palette {
    darker: Palette['primary'];
  }
}

// declare module '@mui/material/styles' {
//   interface BreakpointOverrides {
//     xs: true;
//     sm: true;
//     md: true;
//     lg: true;
//     mxl: true;
//     xl: true;
//     xxl: true;
//   }
// }

const primaryDark = "#454c5b";

const theme = createTheme({
  palette: {
    darker: {
      main: '#343A40'
    },
    primary: {
      light: "#e5e5e5",
      main: "#727272",
      dark: primaryDark,
      contrastText: "#fff",
    },
    secondary: {
      light: "#f3e5f5",
      main: "#e41e26",
      dark: "#a90000",
      contrastText: "#fff",
    },
    info: {
      main: "#75BE56"
    },
    success: {
      main: "#75BE56"
    },
    background: {
      default: "#fff"
    },
  },
  typography: {
    fontFamily: [
      "Lato",
      "Maven Pro",
      "sans-serif"
    ].join(','),
    h1: {
      fontSize: 96,
      fontWeight: "lighter"
    },
    h3: {
      fontSize: 34,
      fontWeight: 600
    },
    h4: {
      fontSize: 34,
      fontWeight: "normal"
    },
    h5: {
      fontSize: 25,
      fontWeight: 600
    },
    h6: {
      fontSize: 16,
      fontWeight: 600
    },
    body1: {
      fontFamily: "Maven pro",
      fontSize: 16
    },
    body2: {
      fontFamily: "Maven pro",
      fontSize: 14
    },
    body3: {
      fontFamily: "Maven pro",
      fontSize: 10
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          minHeight: '48px'
        },
      }
    },
  },
  breakpoints: {
    keys: [ "xs", "sm", "md", "lg", "xl" ],
    values: {
      xs: 0,
      sm: 992,
      md: 1200,
      lg: 1366,
      xl: 1690
    }
  }
});

const inputGlobalStyles = <GlobalStyles
  styles= {
    {
      body: {
        fontFamily: "Maven Pro"
      },
      '.hidden': {
        display: 'none !important'
      },
      '#root > .MuiBox-root': {
        height: '100vh'
      },
      '.mainMenu .MuiTypography-body1': {
        fontSize: 14
      },
      '.recentProjects .MuiTypography-body1': {
        fontSize: 14
      },
      ".MuiChip-root": {
        maxWidth: '100%'
      },
      '.MuiToolbar-root': {
        minHeight: '60px !important'
      },
      '.MuiDrawer-root .MuiList-root': {
        ".MuiListItemIcon-root": {
          color: "inherit",
          minWidth: 39,
        },
        ".MuiMenuItem-root": {
          paddingTop: "12px",
          paddingBottom: "12px",
          width: '100%',

          "&.Mui-selected": {
            backgroundColor: theme.palette.success.main,
            color: '#343A40',
            "& .MuiTypography-root": {
              fontWeight: 500
            },
            '&:hover': {
              backgroundColor: theme.palette.success.main
            }
          }
        },
        ".MuiMenuItem-root:hover": {
          backgroundColor: "#3D444B"
        },
        ".MuiListItemButton-root:hover": {
          backgroundColor: "#3D444B"
        },
        ".MuiListItemButton-root": {
          "&.Mui-selected": {
            backgroundColor: theme.palette.success.main,
            color: '#343A40',
            "& .MuiTypography-root": {
              fontWeight: 500
            },
            '&:hover': {
              backgroundColor: theme.palette.success.main
            }
          }
        },
        ".MuiLink-root": {
          color: "#a1a1a1"
        },
        "& .MuiMenuItem-gutters": {
          paddingLeft: "30px",
          paddingRight: "30px"
        },
      },
      '.MuiDrawer-root': {
        position: 'relative',

        '.MuiPaper-root': {
          width: 250,
          top: 60,
          color: '#a1a1a1',
          backgroundColor: '#343A40',
          paddingBottom: 60
        }
      },
      '*::-webkit-scrollbar': {
        width: '0.4em'
      },
      '*::-webkit-scrollbar-track': {
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(223, 223, 223, 0.3)',
        outline: '1px solid #dfdfdf'
      },
      '.MuiFormControl-root': {
        width: '100%',
      },
      MuiToolbar: {
        gutters: {
          "@media(min-width: 600px)": {
            paddingLeft: "30px",
            paddingRight: "30px"
          }
        }
      },
      '.MuiTab-root': {
        '&.Mui-disabled': {
          background: '#F8F8F8',
          color: 'rgba(0, 0, 0, 0.38)',
          opacity: 1 + ' !important'
        },
        '&.Mui-selected': {
          background: 'rgba(0, 0, 0, 0.1)',
          fontWeight: 700,
          color: theme.palette.darker.main + ' !important'
        }
      },
      '.MuiTypography-root.groupName': {
        marginTop: '10px'
      },
    }
  } />;

function withRoot(Component: any) {
	function WithRoot(props: object) {
		return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...props} />
          {inputGlobalStyles}
        </ThemeProvider>
		);
	}

	return WithRoot;
}

export default withRoot;
