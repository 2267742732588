import * as React from "react";
import GridWizard from "../components/microgrid/GridWizard";
import { useState, useEffect } from "react";
import {connect} from "react-redux";
import {
  Prompt,
} from "react-router-dom";
import {setActiveProjectId, setInnerNav, setPageTitle, setProjectFormMode, setLoadingType} from "../actions/context";
import {resetWizard} from "../actions/wizard";

function GridWizardPage(props: any) {

  const [isBlocking, setIsBlocking] = useState(true);
  const {setInnerNav, setProjectFormMode, progress, setPageTitle, setActiveProjectId, setLoadingType} = props;

  useEffect(() => {
    setInnerNav(true);
    setProjectFormMode('create');
    setPageTitle('New Project');
    setActiveProjectId('');
    setLoadingType('reload');
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e: any) => {
    setLoadingType('stay');
    e.preventDefault();
    e.returnValue = "";
  };

	return (
    <>
      <Prompt
        when={isBlocking && progress !== 'project_created'}
        message={location =>
          `Are you sure you want to leave?, Your changes will be discarded.`
        }
      />
      <GridWizard context="create" />
    </>
	);
}

const mapStateToProps = (state: any) => {
  return {
    activeProjectId: state.context.activeProjectId,
    projects: state.context.projects,
    progress: state.context.progress
  };
};

export default connect(mapStateToProps, {
  setInnerNav, setProjectFormMode, resetWizard, setPageTitle, setActiveProjectId, setLoadingType
})(GridWizardPage);
