import React, {useEffect, useState} from 'react';
import {
  Box, Grid,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material';
import BatteryOptimizer from "../optimization/BatteryOptimizer";
import {useSelector} from "react-redux";
import ModelParametersGroup from "./ModelParametersGroup";

const ModelOptimization = (props: any) => {
  const { model, project } = props;
  const detail = props.model?.parameters;
  const { optimizations } = useSelector((state: any) => state.context);
  const theme = useTheme();
  const [computed, setComputed] = useState({});

  useEffect(() => {
    if (optimizations && optimizations.length > 0) {
      setComputed(optimizations[0].data);
    }
  }, []);

  const renderOptimizationForm = () => {
    switch (model.soe) {
      case 'battery_optimizer':
        return <BatteryOptimizer />
      default:
        return <BatteryOptimizer />
    }
  }

  return (
    <Grid container spacing={2} sx={{ paddingTop: '4px' }}>
      <Grid item xs={12}>
        <Typography
          variant={"h5"}
          sx={{
            marginBottom: 4,
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.info.main,
            [theme.breakpoints.down("md")]: {
              fontSize: '20px'
            },
          }}>
          New Optimization for:
          <span style={{ marginLeft: 16 }}>{project.projectName}</span>
          <span style={{ marginLeft: 8 }}>{ model?.name }</span>
        </Typography>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Typography variant={"h6"} sx={{ marginBottom: 1 }}>Latest Results</Typography>
        {
          optimizations && optimizations.length > 0
          ?
            model && computed && <ModelParametersGroup model={model} group="computed" parameters={{computed}}/>
            :
            ''
        }
      </Grid>
      <Grid item lg={8} md={6} xs={12}>
        { renderOptimizationForm() }
      </Grid>
    </Grid>
  )
}

export default ModelOptimization;
