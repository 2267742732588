import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {Box, Button, Grid, LinearProgress, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {useSnackbar} from 'notistack';
import {setProjectFormMode, setLoading, deleteModel} from '../../actions/context';
import { setModalProps, setModalStatus } from '../../actions/modal';
import GMap from "../common/gMap.component";
import {useTheme} from "@mui/system";
import useSoe from "../../utility/optimization.hooks";
import Utils from "../../utility/optimization.utils";
import DynamicParameter from "../form/DynamicParameter";
import {reduxForm} from "redux-form";
import {LoadingButton} from "@mui/lab";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import {ActionType} from "../../model/model";
import ModelParametersGroup from "./ModelParametersGroup";

/**
 * Form Validation.
 * @param values
 */
const validate = (values: any) => {
  const errors: any = {};
  Object.keys(values).forEach((key, index) => {
    if (isNaN(Number(values[key]))) {
      errors[key] = 'Must be a number';
    }
  });
  return errors;
};

const ModelButtons = (props: any) => {
  const { theme, isDeletable, onHandleDeleteModel, onHandleDuplication, goToQuestionnaire } = props;
  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Button
          variant="text"
          color="info"
          size="medium"
          className="button"
          sx={{
            width: 1/1,
            padding: '6px 0',
            justifyContent: 'flex-start',
            textTransform: 'capitalize',
            fontWeight: 600
          }}
          onClick={goToQuestionnaire}
          endIcon={<ArrowForwardIcon />}
        >
          Link to Questionnaire
        </Button>
      </Box>
      <Box sx={{ maxWidth: '300px', marginTop: 6 }}>
        <Button
          variant="contained"
          color="info"
          size="medium"
          className="button"
          disabled
          sx={{
            width: 1,
            padding: '6px 0',
            textTransform: 'capitalize',
            fontWeight: 600,
            marginBottom: 2
          }}
          onClick={goToQuestionnaire}
          startIcon={<FileUploadOutlinedIcon />}
        >
          Export Model
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className="button"
          sx={{
            width: 1,
            padding: '6px 0',
            textTransform: 'capitalize',
            fontWeight: 600,
            marginBottom: 2,
            backgroundColor: theme.palette.darker.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark
            }
          }}
          onClick={onHandleDuplication}
          startIcon={<ContentCopyOutlinedIcon />}
        >
          Duplicate Model
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          className="button"
          sx={{
            width: 1,
            padding: '6px 0',
            textTransform: 'capitalize',
            fontWeight: 600,
            border: '2px solid #343A40',
            '&:hover': {
              border: '2px solid #343A40'
            }
          }}
          onClick={onHandleDeleteModel}
          startIcon={<DeleteOutlineOutlinedIcon />}
          disabled={isDeletable}
        >
          Delete Model
        </Button>
      </Box>
    </>
  );
}

const ModelConfiguration = (props: any) => {
  const { theme, matches, name, model, isLoading, valid, initialized, onSubmit, handleSubmit} = props;
  return (
    <>
      <Typography variant={"h6"} component="h6" sx={{ color: theme.palette.info.main, mb: 1 }}>{name} Model Configuration</Typography>
      <Grid container spacing={1}>
        {
          model.soeModelId && model.soeConfig
          ?
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    textAlign: "left",
                  }}
            >
              <Grid container spacing={1} sx={{mt: '10px'}}>
                <DynamicParameter
                  content={model.soeConfig}
                  gridBase={6}
                  format={true}
                  labelPositionTop={true}
                />
              </Grid>
              <LoadingButton
                loading={isLoading === true && initialized}
                disabled={!initialized || !valid}
                loadingPosition="start"
                variant="contained"
                color="info"
                size="medium"
                type="submit"
                className="button"
                sx={{ padding: "6px 20px", textTransform: 'capitalize', width: 1/1, mt: '20px', ml: '-8px' }}
                startIcon={<ModelTrainingIcon/>}
              >
                {
                  isLoading === true && initialized
                    ?
                    "Updating simulation..."
                    :
                    "Run Simulation"
                }
              </LoadingButton>
            </form>
            :
            <Typography variant={"body1"} >
              The model is being processed, we will contact you once it is complete.
            </Typography>
        }
      </Grid>
      {!matches && <ModelButtons {...props} />}
    </>
  );
}

const ModelSimulationOutput = (props: any) => {
  const { theme, isLoading, apiError, computed, model} = props;

  return (
    <>
      <Typography variant={"h6"} component="h6" sx={{ color: theme.palette.info.main, mb: 1 }}>Model Simulation Output</Typography>
      <Typography variant={"h6"} sx={{ marginBottom: 1 }}>Computed Variables</Typography>
      {isLoading ? <Box>Calculating...<LinearProgress/></Box> : model && computed && <ModelParametersGroup model={model} group="computed" parameters={{computed}} />}
      {!isLoading && apiError && <Typography variant={"body1"} sx={{color: '#D32F2F'}}>An error occurred, please contact Support.</Typography> }
    </>
  );
}

const ModelDetailsTab = (props: any) => {
  const { userData } = useSelector((state: any) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { project, model, valid } = props;
  const name = model.name;
  const { projectId } = useParams() as any;
  const { enqueueSnackbar } = useSnackbar();
  const isDeletable = project.models.findIndex((model: any) => model.uuid === props.model.uuid) < 2;
  const theme = useTheme();
  const [isLoading, sendRequest] = useSoe();
  const [computed, setComputed] = useState({});
  const [computedMeta, setComputedMeta] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [apiError, setApiError] = useState('');
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  useEffect(() => {
      setInitialized(false);
      if (model.soeModelId && model.soeConfig) {
        // Metadata for formatting the simulation output.
        setComputedMeta(Utils.getSoeOutputMeta(model.soeConfig));

        // Initialize simulation form values.
        sendRequest(Utils.getSimulationPayload(model, true)).then((res: any) => {
          if (res.error) {
            setApiError(res.error);
          }
          else {
            setApiError('');
            // Format input params before applying them to dynamic inputs - @todo Find a better way to handle this.
            //Utils.formatInputParams(res.data, model.soeConfig);
            props.initialize(res.data);
            sendRequest(Utils.getSimulationPayload(model)).then((res: any) => {
              setComputed(res.data);
              setInitialized(true);
              dispatch({ type: ActionType.SAVE_OPTIMIZATION_RESULT, payload: res });
            });
          }
        });
      }
    }, [props.model.uuid]
  );

  const goToQuestionnaire = () => {
    dispatch(setProjectFormMode('edit'));
    history.push(`/project/${projectId}/models/create`);
  }

  const onHandleDuplication = () => {
    dispatch(setProjectFormMode('createModel'));
    history.push(`/project/${projectId}/models/create`);
  }

  const onHandleDeleteModel = () => {
    if (userData?.current_user?.uid === props.model.user_id) {
      dispatch(setModalStatus(true))
      dispatch(setModalProps({
        title: `Delete ${name}?`,
        body: 'Your model will be removed from your project.',
        yesBtnText: 'Yes, delete it',
        noBtnText: 'No, keep it',
        type: 'delete',
        handleSubmit: async () => {
          dispatch(setLoading(true));
          try {
            await dispatch(deleteModel(props.model.id));
            enqueueSnackbar("Model has been successfully deleted.", { variant: 'success' });
          } catch (error) {
            enqueueSnackbar(".", { variant: 'error' });
          }
          dispatch(setLoading(false));
          dispatch(setModalStatus(false));
        }
      }));
    } else {
      enqueueSnackbar("You don't have permission.", { variant: 'error' });
    }
  }

  // Simulation form submission handler.
  const onSubmit = async (formValues: any) => {
    if (model.soeModelId && model.soeConfig) {
      sendRequest(Utils.getSimulationPayload(model, false, Utils.formValuesToParams(formValues))).then((res: any) => {
        setComputed(res.data);
        dispatch({ type: ActionType.SAVE_OPTIMIZATION_RESULT, payload: res });
      });
    }
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ paddingTop: '4px' }}>
        {model.soeModelId ? (
          <>
            <Grid item xl={4} md={6} xs={12} order={{ xl: 1, sm: 2 }}>
              <ModelConfiguration
                theme={theme}
                matches={matches}
                name={name}
                model={model}
                isLoading={isLoading}
                valid={valid}
                initialized={initialized}
                isDeletable={isDeletable}
                onHandleDeleteModel={onHandleDeleteModel}
                onHandleDuplication={onHandleDuplication}
                goToQuestionnaire={goToQuestionnaire}
                onSubmit={onSubmit}
                handleSubmit={props.handleSubmit}
              />
            </Grid>
            <Grid item xl={4} md={6} xs={12} key="computed_variables" order={{ xl: 2, sm: 3 }}>
              <ModelSimulationOutput
                theme={theme}
                isLoading={isLoading}
                apiError={apiError}
                computed={computed}
                model={model}
              />
              {matches && <ModelButtons
                isDeletable={isDeletable}
                onHandleDeleteModel={onHandleDeleteModel}
                onHandleDuplication={onHandleDuplication}
                goToQuestionnaire={goToQuestionnaire}
                theme={theme}
              />}
            </Grid>
            <Grid item xl={4} xs={12} order={{ xl: 3, sm: 1 }}>
              <GMap
                lat={project.location.lat}
                lon={project.location.lon}
                height={300}
              />
            </Grid>
          </>
        ): (
          <>
            <Grid item lg={6} xs={12} order={{ lg: 1, sm: 2 }}>
              <ModelConfiguration
                theme={theme}
                name={name}
                model={model}
                isLoading={isLoading}
                valid={valid}
                initialized={initialized}
                isDeletable={isDeletable}
                onHandleDeleteModel={onHandleDeleteModel}
                onHandleDuplication={onHandleDuplication}
                goToQuestionnaire={goToQuestionnaire}
                onSubmit={onSubmit}
                handleSubmit={props.handleSubmit}
              />
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, sm: 1 }}>
              <GMap
                lat={project.location.lat}
                lon={project.location.lon}
                height={300}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state: any) => {

  let activeModel = null;
  if (state.context.activeProject && state.context.activeModelId) {
    activeModel = state.context.activeProject.models.find((el: any) => el.uuid === state.context.activeModelId);
  }

  return {
    activeProjectId: state.context.activeProjectId,
    activeModelId: state.context.activeModelId,
    project: state.context.activeProject,
    model: activeModel
  }
}

export default connect(mapStateToProps, {})
(reduxForm({
  form: 'SimulationForm',
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ModelDetailsTab));
