import React, {useState} from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from './SortableItem';
import {List} from "@mui/material";

export default function Sortable(props: any) {

  const [items, setItems] = useState(props.items);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <List>
          {items.map((item: any, id: number) => {
            return <SortableItem key={id} id={item} handle={props.handle ? "true" : "false"}/>
          })}
        </List>
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items:any) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        // Update host.
        if (props.hasOwnProperty('handleChange')) {
          props.handleChange(arrayMove(items, oldIndex, newIndex));
        }
        return arrayMove(items, oldIndex, newIndex);
      });
    }

  }
}
