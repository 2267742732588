import React, {useEffect, useState} from 'react';
import {LoadingButton} from "@mui/lab";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { useSnackbar, VariantType } from 'notistack';
import GenericUtils from "../../utility/generic.utils";
import ComponentUtils from "../../utility/components.utils";
import {
	loadCategories,
	resetWizard,
	setActiveStep,
	setWizardSteps,
} from '../../actions/wizard';
import {
  loadUserInputSchema,
  setActiveProjectId,
  setInnerNav,
  setPageTitle,
	setProjectFormMode,
	setProgress,
	saveNewModel,
	setLoading,
	setInitialGridFormValues,
	loadComponentsByType
} from '../../actions/context';
import {
	setModalProps,
	setModalStatus,
	setProgressModal,
	setShowExtraButton
} from '../../actions/modal';
import {Box, useTheme} from "@mui/system";
import StepContent from './StepContent';
import WizardControls from "./WizardControls";

function getSteps(context: any):any {
	return ['Project Details', 'Business Goals', 'Load', 'D-Gen', 'Storage', 'Financial'];
}

function GridWizard(props: any) {

  const {context, loadCategories, setWizardSteps, setActiveStep, setInnerNav, loadUserInputSchema, userId} = props;
  const [steps, setSteps] = useState(getSteps(context));
  const theme = useTheme();
	const data = useSelector((state: any) => state.form ? state.form?.GridWizardForm : {});
	const history = useHistory();
	const dispatch = useDispatch();
	const { activeProjectId, activeModelId, activeProject, progress, formMode } = useSelector((state: any) => state.context);
	const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSteps((steps:any) => steps);
    loadCategories();
    loadUserInputSchema(userId);
    setWizardSteps(steps);
    setActiveStep(0);
    setInnerNav(true);
  }, [loadCategories, loadUserInputSchema, setWizardSteps]);

	useEffect(() => {
		const fetch = async () => {
			if (activeProject && activeProjectId) {
				const {address, location} = activeProject;

				// Business Goal
				const defaultObjectivePriorities: any = [
					"Increase Cost Saving",
					"Increase Affordability",
					"Increase Capacity",
					"Improve Service Quality",
					"Improve Reliability and Resiliency",
					"Increase Accessibility",
					"Reduce Emissions"
				];

				// D-Gen
				const producers: any = ComponentUtils.getProducers(await dispatch(loadComponentsByType('producer')));
				const filteredExistingProducers = producers.filter((producer: any) => producer.isExisting);
				const existingProducers = filteredExistingProducers.length > 0 ? filteredExistingProducers : [{}];
				const filteredPlannedProducers = producers.filter((producer: any) => !producer.isExisting);
				const plannedProducers = filteredPlannedProducers.length > 0 ? filteredPlannedProducers : [{}];

				// Storage
				const storages: any = ComponentUtils.getStorages(await dispatch(loadComponentsByType('storage')));
				const filteredExistingStorages = storages.filter((storage: any) => storage.isExisting);
				const filteredPlannedStorages = storages.filter((storage: any) => !storage.isExisting);

				const storageInitialValues:any = {};
				const producerInitialValues:any = {};
        const financialInitialValues:any = {};

				if (activeProject.models) {
					let model = activeProject.models.find((item: any) => item.uuid === activeModelId);
					if (model && model.parameters && model.parameters.storage) {
						for(const [key, value] of Object.entries(model.parameters.storage)) {
							storageInitialValues[key] = value;
						}
					}

					if (model && model.parameters && model.parameters.producer) {
						for(const [key, value] of Object.entries(model.parameters.producer)) {
              producerInitialValues[key] = value;
						}
					}

          if (model && model.parameters && model.parameters.financial) {
            for(const [key, value] of Object.entries(model.parameters.financial)) {
              financialInitialValues[key] = value;
            }
          }
				}

				const initialValues = {
					projectName: activeProject.projectName,
					location: {
						address: address,
						...location,
					},
					managedBy: activeProject.managedBy,
					description: activeProject.description,
					operationsDate: activeProject.operationsDate,
					ems: activeProject.ems,
					existingEms: activeProject.parameters.hasOwnProperty('existingEms') ? activeProject.parameters.existingEms : '',
					primaryContactName: activeProject.primaryContact.field_name,
					primaryContactEmail: activeProject.primaryContact.field_email,
					primaryContactPhone: activeProject.primaryContact.field_phone,
					singleLineFiles: activeProject ? activeProject.singleLineFiles : [],
					sitePlanFiles: activeProject ? activeProject.sitePlanFiles : [],

					// Business Goal
					mainBusinessGoal: activeProject.parameters?.mainBusinessGoal,
					objectivePriorities: activeProject.parameters?.objectivePriorities ? activeProject.parameters?.objectivePriorities : defaultObjectivePriorities,
          otherBusinessObjective: activeProject.parameters?.otherBusinessObjective,

					// Load
					meteringCompany: activeProject.parameters?.meteringCompany,
					backupConsumers: activeProject.parameters?.backupConsumers,
					noBackupConsumers: activeProject.parameters?.noBackupConsumers,
					independentMeters: activeProject.parameters?.independentMeters,
					outageHoursPerYear: activeProject.parameters?.outageHoursPerYear,
					costPerHourOfOutage: activeProject.parameters?.costPerHourOfOutage,

					// D-Gen
          ...producerInitialValues,
					pvSolarPotentialArea: activeProject.parameters?.pvSolarPotentialArea,
					pvSolarPotentialShaded: activeProject.parameters?.pvSolarPotentialShaded,
					additionalProducers: activeProject.parameters?.additionalProducers,
          additionalProducersOther: activeProject.parameters?.additionalProducersOther,
					solarAnalysis: activeProject ? activeProject.solarAnalysisFiles : [],
					existingProducers: existingProducers,
					plannedProducers: plannedProducers,

					// Storage
					...storageInitialValues,
					existingStorages: filteredExistingStorages.length > 0 ? filteredExistingStorages : [{}],
					plannedStorages: filteredPlannedStorages.length > 0 ? filteredPlannedStorages : [{}],

					// Financial
					...financialInitialValues
				}

				if (!window.location.pathname.includes('project-new')) {
					dispatch(setInitialGridFormValues(initialValues));
				} else {
					dispatch(setInitialGridFormValues(null));
				}
			} else {
				dispatch(setInitialGridFormValues(null));
			}
		}
		fetch();
	}, [activeProject, activeModelId, activeProjectId]);

	const handleNext = () => {
		props.setActiveStep(props.activeStep + 1);
	};

	const handleBack = () => {
		props.setActiveStep(props.activeStep - 1);
	};

  const handleClick = async (projects: any) => {
    // Reset progress once user leaves wizard flow.
    dispatch(setProgress(''));
		history.push({ pathname: '/project/' + activeProject.id });
    await GenericUtils.timeout(800);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

	const handleModalToggle = () => {
		dispatch(setModalStatus(true))
    dispatch(setModalProps({
      title: 'Save as a new Digital Model?',
			body: 'Your new digital model will be added to your project.',
			yesBtnText: 'Yes, Save it',
			noBtnText: 'No, Not Yet',
      handleSubmit: () => handleSubmit()
    }));
	}

	const handleSubmit = async () => {
		// Save the new model.
		try {
			await dispatch(setLoading(true));
			await props.saveNewModel({ ...data?.values, project_uuid: activeProjectId });
			await dispatch(setLoading(false));
		} catch (error) {
			let variant: VariantType = 'error';
			dispatch(setLoading(false));
			enqueueSnackbar('An error occurred.', { variant });
		}
	}

	useEffect(() => {
		if (progress && progress === 'model_created') {
			let variant:VariantType = 'success';

			dispatch(setProgressModal('Model saved'));
			dispatch(setShowExtraButton({
				isShowExtraButton: true,
				buttonText: 'Go to model list',
				handleButtonClick: (projects: any) => handleClick(projects)
			}));
			enqueueSnackbar('Model ' + ' created.', { variant });
		}
	}, [progress])

	return (
		<Box sx={{width: '100%'}}>
			<Box sx={{
				paddingTop: theme.spacing(4),
				paddingBottom: theme.spacing(10),
				position: 'relative',
				[theme.breakpoints.down("lg")]: {
					pr: 5
				},
			}}>
				<StepContent
					activeStep={props.activeStep}
					{...props}
				/>
				<Box sx={{
					marginTop: theme.spacing(2),
					marginBottom: theme.spacing(2),
				}}>
					<WizardControls activeStep={props.activeStep}
													handleBack={handleBack}
													handleNext={handleNext}
					/>
				</Box>
				{(formMode !== 'create' && formMode !== 'edit') && (
					<Box>
						<LoadingButton
							variant="contained"
							color="info"
							size="medium"
							type="button"
							className="button"
							sx={{ padding: "6px 20px", textTransform: 'capitalize', position: 'fixed', bottom: 20 }}
							onClick={handleModalToggle}
						>
							Save new digital model
						</LoadingButton>
					</Box>
				)}
			</Box>
		</Box>
	);
}

const mapStateToProps = (state: any) => {
	return {
    userId: state.auth.userData.current_user.uid,
		steps: state.wizard.steps,
		projects: state.context.projects,
		activeStep: state.wizard.activeStep,
    formMode: state.context.formMode,
		activeProjectId: state.context.activeProjectId,
		categories: state.wizard.categories,
	};
};

export default connect(mapStateToProps, {
	setWizardSteps,
	setActiveStep,
	setPageTitle,
	setActiveProjectId,
	setInnerNav,
	resetWizard,
	loadCategories,
  setProjectFormMode,
  loadUserInputSchema,
	saveNewModel
})(GridWizard);
