import * as React from "react";
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from "@mui/material/MenuItem";
import ComponentUtils from "../../utility/components.utils";
import {useState} from "react";

function SelectElement(props: any) {

  const { touched, error } = props.meta;
  const [activeCategory, setActiveCategory] = useState('');
  const [selectedValue, setSelectedValue] = useState(props.defaultValue || '');

  const handleChange = (e:any) => {
    const component = ComponentUtils.getComponentByUuid(props.components, e.target.value)[0];
    if (props.categories && component && component.relationships) {
      const category = ComponentUtils.getComponentCategory(component, props.categories);
      setActiveCategory('Sub Type: ' + category.name);
    }
    setSelectedValue(e.target.value);
    props.input.onChange(e);
  };

	return (
    <FormControl>
      <InputLabel variant="outlined">{props.label}</InputLabel>
      <Select
        label={props.label}
        required={props.required}
        value={selectedValue}
        {...props.input}
        error={touched && (!!error)}
        onChange={(e:any) => handleChange(e)}
        size={props.size || "small"}
        disabled={props.disabled}
        IconComponent = {KeyboardArrowDownIcon}
      >
        {props.emptyItem && <MenuItem disabled value=''>{props.emptyItem}</MenuItem>}
        {props.components.map((component: any, index: number) => (
          <MenuItem key={component.id} value={component.id}>{component.attributes.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperTex || activeCategory}</FormHelperText>
    </FormControl>
	);
}

export default SelectElement;
