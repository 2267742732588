import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";

function CheckBoxElement(props: any) {

	return (
		<FormControlLabel
			control={<Checkbox color="primary" />}
			label={props.label}
			labelPlacement="end"
			checked={!!props.input.value}
			disabled={props.disabled}
			onChange={props.input.onChange}
		/>
	);
};

export default CheckBoxElement;
