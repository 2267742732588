import * as React from "react";
import {FormControl, FormControlLabel, Switch} from "@mui/material";

function SwitchElement(props: any) {
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={<Switch color="info"/>}
        label={props.label}
        onChange={props.input.onChange}
        checked={!!props.input.value}
      />
    </FormControl>
  );
}

export default SwitchElement;
