import * as React from "react";
import { connect } from "react-redux";
import {setActiveProjectId, setInnerNav, setPageTitle} from "../actions/context";
import {Card, CardContent, Divider, Grid, Link, Paper, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {useTheme} from "@mui/system";
import AssessmentIcon from '@mui/icons-material/Assessment';
import PhotoIcon from '@mui/icons-material/Photo';
import useReports from "../utility/reports.hooks";

function InsightsPage(props: any) {

  const {activeProjectId, activeProject} = props;
  const [insights, setInsights] = useState([]);
  const [isLoading, getReports] = useReports(activeProjectId);
  const [initialized, setInitialized] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setInitialized(false);
    if (activeProjectId) {
      getReports().then((res: any) => {
        setInsights(res);
        setInitialized(true);
      });
    }
  }, [activeProjectId]);

  const renderEmptyReports = () => {
    if (initialized) {
      return (
        <Grid item xs={10} md={5} lg={3}>
          <Typography variant={"body1"}>
            <Paper sx={{p: '20px'}}>
              Reports are being prepared, you will be notified once they are ready.
            </Paper>
          </Typography>
        </Grid>
      );
    }
    else {
      return '';
    }
  }

	return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{bgcolor: '#efefef'}}>
        <Grid container spacing={3} sx={{p: '50px'}}>
          {
            !isLoading && insights
            ?
              insights.length > 0
                ?
                insights.map((insight: any, index: number) => {
                  return (
                    <Grid item xs={10} md={5} lg={3} key={insight.id}>
                      <Link component={RouterLink} to={{
                        pathname: `/project/${activeProject.id}/reports/${insight.id}`,
                        state: {insight: insight}
                      }}
                            sx={{textDecoration: 'none'}}>
                        <Card key={insight.uuid} sx={{bgcolor: theme.palette.primary.light, minHeight: '100px'}}>
                          <CardContent>
                            <Typography variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
                              {insight.type === 'report' ? <AssessmentIcon sx={{mr: '4px'}}/> :
                                <PhotoIcon sx={{mr: '4px'}}/>}
                              Report
                            </Typography>
                            <Divider/>
                            <Typography variant="body2" sx={{mt: '10px', fontSize: 16}}>{insight.name}</Typography>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  )
                })
                :
                renderEmptyReports()
              :
              'loading...'
          }
        </Grid>
      </Grid>
    </Grid>
	);
}

const mapStateToProps = (state: any) => {
  const activeProject = state.context.projects.find((el: any) => el.uuid === state.context.activeProjectId);
	return {
    activeProject: activeProject,
    activeProjectId: state.context.activeProjectId,
		pageTitle: state.context.pageTitle,
    projects: state.context.projects,
    user: state.auth.userData,
    categories: state.wizard.categories
	};
};

export default connect(mapStateToProps, { setPageTitle, setInnerNav, setActiveProjectId })(InsightsPage);

