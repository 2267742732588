import { History } from "history";
import { combineReducers } from "redux";
import { ActionType, Auth } from "../model/model";
import { contextReducer } from "./context";
import { authReducer } from "./auth";
import { modalReducer } from "./modal";
import { reducer as formReducer } from "redux-form";
import { wizardReducer } from "./wizard";

export interface RootState {
	auth: Auth;
}

export default (history: History) =>
	combineReducers({
		auth: authReducer,
		context: contextReducer,
		modal: modalReducer,
		form: formReducer.plugin({
			GridWizardForm: (state, action) => {
				switch(action.type) {
					case ActionType.RESET_STORE:
						return undefined;
					case ActionType.RESET_WIZARD:
						return { ...state, values: {location: null}, initial: {} };
					default:
						return state;
				}
			}
		}),
		wizard: wizardReducer
});
