import * as React from 'react';
import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from '../assets/images/logo-black.svg';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress';
import { history } from "../configureStore";
import { connect } from "react-redux";
import {isLoggedIn, trySignIn} from "../actions/auth";
import {useTheme} from "@mui/material";
import GenericUtils from "../utility/generic.utils";
import { LoadingButton } from '@mui/lab';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" target="_blank" href="https://brightmerge.com/">
        Brightmerge
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LogInPage(props: any) {

  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const {trySignIn, isLoggedIn, isSignedIn} = props;

  useEffect(() => {
    if (props.isError) {
      setIsSigningIn(false);
    }
  }, [props.errors]);

  useEffect(() => {
    // Update log in status from server.
    isLoggedIn();
    // If signed in, redirect.
    if (isSignedIn) {
      setIsSigningIn(false);
      history.push('/');
    }
  }, [isSignedIn, isLoggedIn]);

  const onFormSubmit = async (evt: any) => {
    evt.preventDefault();
    setIsDirty(true);
    if (!email || !password) {
      return false;
    }
    setIsSigningIn(true);
    await GenericUtils.timeout(700);
    trySignIn(email, password);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className="paper" sx={{
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: '#fff',
        padding: "15px 30px"
      }}>
        <Avatar className="avatar" sx={{
          margin: theme.spacing(1),
          backgroundColor: "#454c5b",
        }}>
          <LockOutlinedIcon />
        </Avatar>
        <img className="logo" src={logo} alt="Brightmerge" />
        {!isSigningIn ?
            <form onSubmit={onFormSubmit} className="form" noValidate autoComplete="off" style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: theme.spacing(1),
            }}>
              <TextField
                  error={props.isError || (isDirty && !props.email)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address or User Name"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                  error={props.isError || (isDirty && !props.password)}
                  helperText={props.errors ? props.errors.message : ''}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
              />
              <LoadingButton
                variant="contained"
                color="info"
                size="medium"
                type="submit"
                className="button"
                sx={{ padding: "6px 20px", textTransform: 'uppercase', width: 1, mb: 2, mt: 4, fontWeight: 700, }}
              >
                Sign In
              </LoadingButton>
              <Grid container>
{/*                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>*/}
                <Grid item>
{/*                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>*/}
                </Grid>
              </Grid>
            </form>
            :
            <Typography variant="h5" className="signingin" sx={{textAlign: "center", marginBottom: 10, fontWeight: 400}}>
              Logging in
              <br/>
              <CircularProgress />
            </Typography>
        }
      </Box>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">Jan22</Typography>
        <Copyright />
      </Box>
    </Container>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userData: state.auth.userData,
    errors: state.auth.errors,
    isError: !(Object.keys(state.auth.errors).length === 0 && state.auth.errors.constructor === Object)
  };
};

export default connect(mapStateToProps, { trySignIn, isLoggedIn })(LogInPage);
