import React from 'react';
import ProjectDetails from './ProjectDetails';
import Load from './Load';
import DGen from './DGen';
import BusinessGoals from "./BusinessGoals";
import Financial from "./Financial"
import StorageComponent from './Storage';

import {
  Box
} from '@mui/material';

function getStepContent(step: number, props: any) {
	switch (step) {
		case 0:
      return <ProjectDetails />;
    case 1:
      return <BusinessGoals />;
		case 2:
			return  <Load />;
		case 3:
			return <DGen />;
		case 4:
			return <StorageComponent />;
    case 5:
      return <Financial />;
		default:
			return 'Unknown step';
	}
}

const StepContent = (props: any) => {
  return (
    <Box>
      {getStepContent(props.activeStep, props)}
    </Box>
  )
}

export default StepContent;