import * as React from "react";
import {reduxForm, FieldArray, Field} from "redux-form";
import { connect } from "react-redux";
import { loadBaseComponents, setActiveStep } from "../../actions/wizard";
import {useCallback, useEffect, useState} from "react";
import {loadComponentsByType} from "../../actions/context";
import {Typography, useTheme} from "@mui/material";
import MultipleComponents from "../form/multiplecomponents";
import {Grid} from "@mui/material";
import RadioGroupElement from "../form/radiogroup";
import TextWithUnitsElement from "../form/textwithunits";
import CheckBoxElement from "../form/checkbox";

const validate = (values: any) => {
  const errors: any = {};

  if (!values.storage) {
    errors.storage = 'Please select storage.';
  }

  if (!values.loadProfile || !(values.loadProfile[0] instanceof File)) {
    errors.loadProfile = 'Load profile is missing.';
  }

  return errors;
};

const StorageComponent = (props:any) => {

  const [activeGroup, setActiveGroup] = useState('existing');
  const theme = useTheme();

  const toggleActiveGroup = (e:any) => {
    setActiveGroup(e.target.value);
  };

	const {context, categories, loadBaseComponents, components, loadComponentsByType} = props;
	const {activeProjectId, activeProject} = context;

  useEffect(() => {
    loadBaseComponents('storage')
  }, [activeProjectId]);

	return (
	  <>
			<form noValidate autoComplete="off" style={{
        textAlign: "left",
        paddingLeft: 15,
        paddingRight: 15,
      }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={7} lg={5} xl={4}>
            <RadioGroupElement
              defaultValue='existing'
              row={true}
              onChange={(e:any) => toggleActiveGroup(e)}
              radios={[
                {label: 'Existing', value: "existing" },
                {label: 'Planned', value: "planned" }
              ]}
            >
            </RadioGroupElement>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={3} xl={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant={"h6"}>
              {activeGroup === 'existing' ? 'Existing Storage Components' : 'Planned Storage Components'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} lg={8} xl={6} sx={{ display: activeGroup === 'existing' ? 'block' : 'none' }} >
            <FieldArray name="existingStorages" component={MultipleComponents} components={props.components}
                        label="Select Storage" buttonLabel="Add Another Storage" categories={props.categories}/>
          </Grid>
          <Grid item xs={12} lg={8} xl={6} sx={{ display: activeGroup === 'planned' ? 'block' : 'none' }}>
            <FieldArray name="plannedStorages" component={MultipleComponents} components={components}
                        label="Select Storage" buttonLabel="Add Another Storage" categories={categories}/>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Storage Parameters
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Field name="bessHoursToLoad"
                   component={TextWithUnitsElement}
                   label="BESS hours to backup load"
                   units="Hours"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Field name="depthOfDischarge"
                   component={TextWithUnitsElement}
                   label="Depth of Discharge (DoD) for the project"
                   units="%"
            />
          </Grid>
          <Grid
            item lg={4} xl={6}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              }
            }}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Field name="estimatedBatterySize"
                   component={TextWithUnitsElement}
                   label="Estimated Battery Size"
                   units="KWh"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Field name="degradationPerCycle"
                   component={TextWithUnitsElement}
                   label="BESS Degradation per recharge cycle"
                   units="%"
            />
          </Grid>
          <Grid
            item lg={4} xl={6}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              }
            }}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Field name="roundtripEfficiency"
                   component={TextWithUnitsElement}
                   label="Round-trip efficiency"
                   units="%"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Field
              name="useBRMrecommendedStorage"
              component={CheckBoxElement}
              label="Use Brightmerge AI Recommendation"
              defaultValue={false}
            />
          </Grid>
          <Grid
            item lg={4} xl={6}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              }
            }}>
          </Grid>
        </Grid>
			</form>
      </>
	);
}

const mapStateToProps = (state: any) => {

  let init;
  if (state.context.formMode === 'create') {
    init = {
      bessHoursToLoad: 4,
      depthOfDischarge: 20,
      degradationPerCycle: 0.01,
      roundtripEfficiency: 80
    };
  }

	return {
    activeStep: state.wizard.activeStep,
    components: state.wizard.storage.data || [],
    categories: state.wizard.categories || [],
    formMode: state.context.formMode,
    context: state.context,
    activeProjectId: state.context.activeProjectId,
    projects: state.context.projects,
    initialValues: { ...state.form.GridWizardForm?.values, ...init }
  };
};

export default connect(mapStateToProps, { loadBaseComponents, setActiveStep, loadComponentsByType })
(reduxForm({
	form: 'GridWizardForm',
  validate,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(StorageComponent));
