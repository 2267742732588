import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  InputAdornment, Link,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams
} from '@mui/x-data-grid';
import debounce from 'lodash.debounce';
import DocumentModal from '../components/document/documentModal';
import { loadDocuments } from '../actions/context';
import { setShowDocumentModal } from '../actions/modal';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    renderCell: (params: GridValueGetterParams) => (
      <Link href={params.row.link} target='_blank'>
        {params.value}
      </Link>
    )
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'fileType',
    headerName: 'File Type',
    width: 100,
    headerAlign: 'center',
    renderCell: (params: GridValueGetterParams) => (
      <Box sx={{ textAlign: 'center', width: 1/1 }}>
        {params.value}
      </Box>
    )
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    width: 150,
    headerAlign: 'center',
    renderCell: (params: GridValueGetterParams) => (
      <Box sx={{ textAlign: 'center', width: 1/1 }}>
        {params.value}
      </Box>
    )
  },
  {
    field: 'owner',
    headerName: 'Owner',
    width: 150,
    headerAlign: 'center',
    renderCell: (params: GridValueGetterParams) => (
      <Box sx={{ textAlign: 'center', width: 1/1 }}>
        {params.value}
      </Box>
    )
  },
];

const DocumentationPage = () => {

  const [rows, setRows] = useState([]);
  const [model, setModel] = useState();
  const [searchKey, setSearchKey] = useState('');
  const { activeProject, documents, documentTypes, documentsCount, isLoading } = useSelector((state: any) => state.context);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const theme = useTheme();
  const dispatch =useDispatch();

  const openDocumentModal = () => {
    dispatch(setShowDocumentModal(true));
  }

  const handleSortModel = (model: any) => {
    setModel(model[0]);
    dispatch(loadDocuments(currentPage * pageSize, pageSize, model[0], searchKey));
  }

  const debouncedLoadDocuments = useCallback(
		debounce((nextValue: string) => dispatch(loadDocuments(currentPage * pageSize, pageSize, model, nextValue)), 500),
		[], // will be created only once initially
	);

  const handleSearchKey = (e: any) => {
    setSearchKey(e.target.value);
    debouncedLoadDocuments(e.target.value);
  }

  useEffect(() => {
    dispatch(loadDocuments(currentPage * pageSize, pageSize, model, searchKey));
  }, []);

  useEffect(() => {
    dispatch(loadDocuments(currentPage * pageSize, pageSize, model, searchKey));
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (documents) {
      const docs = documents.map((doc: any) => ({
        ...doc,
        fileType: (documentTypes && documentTypes.length > 0) ? documentTypes.find((t: any) => t.id === doc.type)?.name : '',
        createdDate: new Date(doc.createdDate).toDateString()
      }));
      setRows(docs);
    }
  }, [documents, documentTypes]);

  function renderToolbar() {
    return (
      <>
          <Grid container spacing={2} sx={{p: 3}}>
            <Grid item xs={8} md={6} lg={4}>
              <TextField
                label="Search Document"
                placeholder="Search Document"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={searchKey}
                onChange={handleSearchKey}
              />
            </Grid>
            <Grid item xs={4} md={6} lg={8}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, mb: 2 }}>
              <LoadingButton
                loadingPosition="start"
                variant="contained"
                color="info"
                size="small"
                type="submit"
                className="button"
                sx={{padding: "6px 20px", textTransform: 'capitalize'}}
                onClick={openDocumentModal}
              >
                Add New Document
              </LoadingButton>
              </Box>
            </Grid>
            {/*<GridToolbarExport />*/}
          </Grid>
      </>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <Typography variant={"body1"}>
          There are no documents for this project yet. Add a new document above.
        </Typography>
      </GridOverlay>
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{bgcolor: '#efefef'}}>
          <Grid container spacing={3} sx={{p: '50px'}}>
            <Grid item xs={12}>
              <Typography
                variant={"h5"}
                sx={{
                  marginBottom: 4,
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.info.main,
                  [theme.breakpoints.down("md")]: {
                    fontSize: '20px'
                  },
                }}>
                Project {activeProject && activeProject.projectName} Documentation:
              </Typography>
              <Box sx={{ bgcolor: 'white', borderRadius: 1 }}>
                <Box sx={{width: '100%'}}>
                  {renderToolbar()}
                  {
                    !isLoading
                    ?
                      <DataGrid
                        autoHeight={true}
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 20]}
                        onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
                        onPageChange={(page) => setCurrentPage(page)}
                        onSortModelChange={(model) => handleSortModel(model)}
                        rowCount={documentsCount}
                        sortingMode="server"
                        paginationMode="server"
                        disableSelectionOnClick
                      />
                      :
                      ''
                  }
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DocumentModal />
    </>
  );
}

export default DocumentationPage;
