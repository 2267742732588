import * as React from "react";
import {reduxForm, FieldArray, Field} from "redux-form";
import { connect, useSelector } from "react-redux";
import { loadBaseComponents } from "../../actions/wizard";
import {useEffect, useState} from "react";
import {FormHelperText, Typography} from "@mui/material";
import {Grid} from "@mui/material";
import TextWithUnitsElement from "../form/textwithunits";
import RadioGroupElement from "../form/radiogroup";
import FormControl from "@mui/material/FormControl";
import FileDropZone from "../form/dropzone";
import MultipleComponents from "../form/multiplecomponents";
import {Box} from "@mui/system";
import FileUtils from "../../utility/file.utils";
import {loadComponentsByType} from "../../actions/context";
import SimpleSelectElement from "../form/simpleselect";
import TextElement from "../form/textfield";
import CheckBoxElement from "../form/checkbox";

const validate = (values: any) => {
  const errors: any = {};

  if (!values.dgen) {
    errors.dgen = 'Please select a producer.';
  }
  return errors;
};

const DGen = (props:any) => {

  const {loadBaseComponents, activeProjectId, initialValues} = props;
  const { formMode } = useSelector((state: any) => state.context);
  const {values} = useSelector((state: any) => state.form.GridWizardForm);

  const [dropOpenSolarAnalysis, setSolarAnalysisDropZoneOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState('existing');
  let i: any = [];
  const [blobs, setBlobs] = useState(i);

  const toggleActiveGroup = (e:any) => {
    setActiveGroup(e.target.value);
  };

  useEffect(() => {
    loadBaseComponents('producer').then(() => {
      if (activeProjectId && initialValues.solarAnalysis.length > 0) {
          FileUtils.getFileBlobs(initialValues.solarAnalysis).then((res) => {
            setBlobs(res);
          });
      } else if (initialValues.solarAnalysis) {
        setBlobs(initialValues.solarAnalysis);
      }
    });
  }, [activeProjectId, initialValues.solarAnalysis]);

  return (
    <>
      <Box sx={{ p: '15px' }}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} md={7} lg={5} xl={4}>
              <RadioGroupElement
                defaultValue='existing'
                row={true}
                onChange={(e:any) => toggleActiveGroup(e)}
                radios={[
                  { label: 'Existing', value: "existing" },
                  { label: 'Planned', value: "planned" }
                ]}
              >
              </RadioGroupElement>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} xl={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant={"h6"}>
                {activeGroup === 'existing' ? 'Existing D-GEN Components' : 'Planned D-GEN Components'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={8} xl={6} sx={{ display: activeGroup === 'existing' ? 'block' : 'none' }}>
              <FieldArray name="existingProducers" component={MultipleComponents} components={props.components}
                          label="Select D-Gen" buttonLabel="Add Another D-Gen" categories={props.categories} />
            </Grid>
            <Grid item xs={12} lg={8} xl={6} sx={{ display: activeGroup === 'planned' ? 'block' : 'none' }}>
              <FieldArray name="plannedProducers" component={MultipleComponents} components={props.components}
                          label="Select D-Gen" buttonLabel="Add Another D-Gen" categories={props.categories} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>
                PV Solar Potential
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Field
                name="pvSolarPotentialArea"
                component={TextWithUnitsElement}
                units="m²"
                label="PV solar potential areas"
                props={{
                  disabled: formMode !== 'create'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Field
                name="pvSolarPotentialShaded"
                component={TextWithUnitsElement}
                units="%"
                label="Estimated System Losses"
                props={{
                  disabled: formMode !== 'create'
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Field
                name="additionalProducers"
                component={SimpleSelectElement}
                options={
                  [
                    {value: "Wind", label: "Wind"},
                    {value: "CHP", label: "CHP"},
                    {value: "Green Hydrogen", label: "Green Hydrogen"},
                    {value: "Geothermal", label: "Geothermal"},
                    {value: "CNG", label: "CNG"},
                    {value: "Other", label: "Other"}
                  ]
                }
                label="Other D-GEN"
                size={"medium"}
                props={{
                  disabled: formMode !== 'create'
                }}
              />
              <FormHelperText>Any D-GEN not on the list, please specify here.</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              {values && values?.additionalProducers === 'Other' &&
                <Field
                  name="additionalProducersOther"
                  component={TextElement}
                  label="Other D-GEN"
                  props={{
                    disabled: formMode !== 'create'
                  }}
                />
              }
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Field
                name="useBRMrecommendedDGEN"
                component={CheckBoxElement}
                label="Use Brightmerge AI Recommendation"
                defaultValue={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h6"}>
                Upload External Solar Analysis
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl component="fieldset">
                <Field name="solarAnalysis"
                      component={FileDropZone}
                      dropOpen={dropOpenSolarAnalysis}
                      setDropZoneOpen={setSolarAnalysisDropZoneOpen}
                      dialogTitle="Upload Solar Analysis Data"
                      dropzoneText="Drag & Drop a file, or click to select one."
                      buttonLabel="Upload data"
                      acceptedFiles={['.txt','.xls','.xlsx','.csv','.zip','.7z']}
                      maxFileSize={25000000}
                      filesLimit={1}
                      initialFiles={blobs}
                      disabled={formMode !== 'create'}
                  // validate={fileDropped}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const activeProject = state.context.projects.find((el: any) => el.uuid === state.context.activeProjectId);

  let solarAnalysis: any = [];
  let pvSolarPotentialShaded: any = null;

  if (activeProject) {
    solarAnalysis = activeProject ? activeProject.solarAnalysisFiles : [];
  }
  else{
    pvSolarPotentialShaded = 10;
  }

	return {
	  components: state.wizard.producer.data || [],
    categories: state.wizard.categories || [],
    formMode: state.context.formMode,
    context: state.context,
    activeProjectId: state.context.activeProjectId,
    initialValues: { ...state.form.GridWizardForm?.values, solarAnalysis, pvSolarPotentialShaded }
	};
};

export default connect(mapStateToProps, { loadBaseComponents, loadComponentsByType })
(reduxForm({
	form: 'GridWizardForm',
  validate,
	destroyOnUnmount: false,
  enableReinitialize: true,
	forceUnregisterOnUnmount: true,
  keepDirtyOnReinitialize: true,
})(DGen));
