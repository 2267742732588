// Modules
import React from "react";
import GoogleMapReact, {Props} from 'google-map-react';
import * as Constant from "../../model/constant";
// Material UI Components
import {Box} from "@mui/system";

// Simple Map Marker
const Marker = (props:any) => {
  return (
    <Box
      sx={{
        position: "absolute",
        borderRadius: "50% 50% 50% 0",
        border: "4px solid #343A40",
        width: "20px",
        height: "20px",
        transform: "rotate(-45deg)",
        "&:after": {
          position: "absolute",
          content: "''",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          top: "50%",
          left: "50%",
          marginLeft: "-5px",
          marginTop: "-5px",
          backgroundColor: "#ffc107"
        }
      }}
    />
  );
}

const GMap = (props: any) => {

  const {lat, lon, height} = props;

  const defaultProps:Props = {
    center: {
      lat: lat,
      lng: lon
    },
    zoom: 11
  };

  return (
    // Important! Always set the container height explicitly
    <Box sx={{ height: height, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Constant.GOOGLE_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
      <Marker
        lat={lat}
        lng={lon}
      />
      </GoogleMapReact>
    </Box>
  );
};

export default GMap;
