import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {reduxForm, Field} from "redux-form";
import FileDropZone from "../form/dropzone";
import {connect, useSelector} from "react-redux";
import {loadBaseComponents, loadTariffs} from "../../actions/wizard";
import {useCallback, useEffect, useRef, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import SelectElement from "../form/select";
import {loadComponentsByType, loadConsumers} from "../../actions/context";
import {Card, CardContent, Divider, Link, Typography} from "@mui/material";
import {FormHelperText, Grid, MenuItem, Select} from "@mui/material";
import TextElementNew from "../form/textfieldnew";
import TextWithUnitsElement from "../form/textwithunits";
import {Box} from "@mui/system";
import connector from "../../api/connector";
import ComponentUtils from "../../utility/components.utils";
import FileUtils from "../../utility/file.utils";

const validate = (values: any) => {

  const errors: any = {};

  if (!values.loadProfile) {
    errors.loadProfile = 'Please provide a load profile.';
  }
  return errors;
};

function Load(props: any) {

  const [dropOpen, setDropZoneOpen] = useState(false);
  let i: any = [];
  const [blobs, setBlobs] = useState(i);
  const {activeProjectId, loadComponentsByType, consumers} = props;
  const { formMode } = useSelector((state: any) => state.context);

  // Field level validations.
  const fileDropped = (value: any) => !value || !(value[0] instanceof File) ? 'Load profile is missing.' : undefined;
  const required = (value: any) => value ? undefined : 'This field is required.';

  useEffect(() => {
    if (activeProjectId) {
      loadComponentsByType('consumer');
      // props.initialize(props.initialValues);
    }
    else {
      // setBlobs(i);
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (activeProjectId && consumers && consumers.length > 0) {
      const initFiles = FileUtils.getFileBlobs(consumers[0].files);
      initFiles.then((res: any) => {
        setBlobs(res);
      });
    }
  }, [consumers]);

  useEffect(() => {
    if (props.initialValues && props.initialValues.loadProfile && blobs.length == 0) {
      setBlobs(props.initialValues.loadProfile);
    }
  }, [props.initialValues])

  return (
    <Box>
      <form noValidate autoComplete="off" style={{
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
      }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item sm={5} md={4} lg={3} xl={2}>
            <Select
              labelId="simple-select-disabled-label"
              id="simple-select-disabled"
              value={0}
              label="Select Metering Company"
              disabled={true}
              size={"small"}
              sx={{ width: 1/1 }}
            >
              <MenuItem value={0}>Select Metering Company</MenuItem>
            </Select>
          </Grid>
          <Grid item sm={5} md={4} lg={3} xl={2}>
            <Select
              labelId="simple-select-disabled-label"
              id="simple-select-disabled-2"
              value={1}
              label="Select Load Profile Data"
              disabled={true}
              size={"small"}
              sx={{ width: 1/1 }}
            >
              <MenuItem value={1}>Select Load Profile Data</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Upload your own load profile data
            </Typography>
          </Grid>
          <Grid item xs={5} sm={6} md={5} lg={3} xl={3}>
            <Field
              name="meteringCompany"
              component={TextElementNew}
              label="Metering Company Name"
              validate={required}
              required
              disabled={formMode !== 'create'}
            />
          </Grid>
          <Grid item xs={5} sm={4} md={3} lg={3} xl={2}>
            <Field name="loadProfile"
                   component={FileDropZone}
                   dropOpen={dropOpen}
                   setDropZoneOpen={setDropZoneOpen}
                   acceptedFiles={['.csv', '.xls', 'xlsx', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                   maxFileSize={5000000}
                   filesLimit={5}
                   dialogTitle="Add load profile data"
                   dropzoneText="Drag & Drop a CSV file, or click to select one."
                   buttonLabel="Upload load profile data"
                   validate={fileDropped}
                   initialFiles={blobs}
                   sx={{ width: '180px' }}
            />
            {/*<Card key={consumers[0].uuid}>*/}
            {/*  <CardContent>*/}
            {/*    <Typography variant="subtitle2"><strong>Name:</strong> {consumers[0].name}</Typography>*/}
            {/*    <Typography variant="body2"><strong>File:</strong> {consumers[0].loadFilename}</Typography>*/}
            {/*    <Typography variant="body2"><strong>Size:</strong> {consumers[0].loadSize} KB</Typography>*/}
            {/*    <Divider />*/}
            {/*    <Typography variant="body2"><Link href={process.env.REACT_APP_BASE_URL + consumers[0].downloadUrl}>Download</Link></Typography>*/}
            {/*  </CardContent>*/}
            {/*</Card>*/}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={10} md={8} lg={6} xl={5}>
            <Field
              name="backupConsumers"
              component={TextElementNew}
              label="Non-deferrable Consumers"
              disabled={formMode !== 'create'}
            />
            <FormHelperText>List of electricity consumers that should be backups</FormHelperText>
          </Grid>
          <Grid item xs={1} md={4} lg={6} xl={7}>
          </Grid>
          <Grid item xs={10} md={8} lg={6} xl={5}>
            <Field
              name="noBackupConsumers"
              component={TextElementNew}
              label="Deferrable Consumers"
              disabled={formMode !== 'create'}
            />
            <FormHelperText>List of electricity consumers that don't need backups</FormHelperText>
          </Grid>
          <Grid item xs={1} md={4} lg={6} xl={7}>
          </Grid>
          <Grid item xs={10} md={8} lg={6} xl={5}>
            <Field
              name="independentMeters"
              component={TextElementNew}
              label="Consumers with Independent Meters"
              disabled={formMode !== 'create'}
            />
            <FormHelperText>Electricity consumers that have an independent meter</FormHelperText>
          </Grid>
          <Grid item xs={1} md={4} lg={6} xl={7}>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Outages
            </Typography>
          </Grid>
          <Grid item xs={5} md={4} lg={3} xl={3}>
            <Field
              name="outageHoursPerYear"
              component={TextWithUnitsElement}
              units="Hours"
              label="Estimated number of outage hours per year"
              disabled={formMode !== 'create'}
            />
          </Grid>
        </Grid>
        <div>
          {
            props.tariffs && props.tariffs.length > 0 ?
              <div>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Select Tariff</InputLabel>
                  <Field name="tariff"
                         component={SelectElement}
                         components={props.tariffs}
                         label="Select Tariff"
                         disabled={formMode !== 'create'}
                  />
                </FormControl>
              </div>
              :
              ''
          }
        </div>
      </form>
    </Box>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activeProjectId: state.context.activeProjectId,
    consumers: state.context.consumers,
    initialValues: state.form.GridWizardForm?.values
  }
};

export default connect(mapStateToProps, {loadBaseComponents, loadTariffs, loadComponentsByType})
(reduxForm({
  form: 'GridWizardForm',
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Load));
