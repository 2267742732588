import * as React from 'react';
import { connect } from 'react-redux';
import { setActiveStep } from '../../actions/wizard';
import { List, ListItem, ListItemText } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import {Box} from "@mui/system";
import { useTheme } from "@mui/material";

function SideNav(props: any) {
  const theme = useTheme();
	const {steps} = props;

	const handleClick = (e: any, index: number) => {
	  props.setActiveStep(index);
	};

	return (
		<Box
      sx={{
        width: "230px",
        display: "flex",
        backgroundColor: 'white',
        transform: 'translate(-230px, 0)',
        transition: 'transform 200ms ease-out',
        marginTop: {sm: '64px', md: '56px'},
        "& > ul": {
          width: '100%',
          ">div.active": {
            backgroundColor: '#efefef'
          },
          "& > div.MuiListItem-gutters": {
            padding: '8px 30px 8px 30px',
            borderBottom: '1px solid #efefef'
          }
        },
        "&.open": {
          transform: 'translate(0, 0)',
          visibility: 'visible'
        },
        "&.closed": {
          maxWidth: 0,
          transition: 'max-width 500ms ease-out',
          visibility: 'hidden'
        }
      }}
			className={props.innerNav ? 'sideNav open' : 'sideNav closed'
			}
		>
			<List>
        {
          steps
          ?
            <>
            {steps.map((label: string, index: number) => (
                <ListItem
                  button
                  key={index}
                  onClick={e => handleClick(e, index)}
                  className={index === props.activeStep ? 'active' : ''}
                  disabled={props.formMode === 'create' && index > props.activeStep}
                >
                  <ListItemText primary={label} />
                  {props.activeStep > index && <DoneIcon sx={{ color: theme.palette.info.main}} />}
                </ListItem>
              ))}
            </>
              :
            ''
        }
			</List>
		</Box>
	);
}

const mapStateToProps = (state: any) => {
	return {
		steps: state.wizard.steps,
		activeStep: state.wizard.activeStep,
		innerNav: state.context.innerNav,
    formMode: state.context.formMode
	};
};

export default connect(mapStateToProps, { setActiveStep })(SideNav);
