import * as React from "react";
import { reduxForm, Field } from "redux-form";
import {connect, useSelector, useDispatch} from "react-redux";
import SelectElement from "../form/select";
import {
  clearOptimization,
  runOptimization
} from "../../actions/context";
import Button from "@mui/material/Button";
import { VariantType, useSnackbar } from 'notistack';
import {useEffect, useState} from "react";
import {loadObjectives} from "../../actions/wizard";
import TextWithUnitsElement from '../form/textwithunits';
import InputSlider from '../form/slider';
import {
  Grid,
  Box,
  Typography,
  useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import Utils from "../../utility/optimization.utils";
import useSoe from "../../utility/optimization.hooks";
import {ActionType} from "../../model/model";
import TextElementNew from "../form/textfieldnew";

function BatteryOptimizer(props: any) {

	const initialState: any[] = [];
	const { enqueueSnackbar } = useSnackbar();
  const [objectivesOptions, setObjectivesOptions] = React.useState(initialState);
	const {objectives, loadObjectives, model} = props;
  const { soeModelId, soeConfig } = model;
  const { optimizations } = useSelector((state: any) => state.context);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isLoading, sendRequest] = useSoe();
  const [result, setResult] = useState({});
  const [initBounds, setInitBounds] = useState([0, 8000]);

  useEffect(() => {

    // Get constraint bounds.
    // const bounds = Utils.getOptimizationBounds(model, 'optimize', 'bounds_for_decision_vars_bounds');
    // if (bounds) {
    //   setInitBounds(bounds);
    // }

    loadObjectives();
    const objs:any[] = [];
    objectives.map((field:any, index:any) => {
      return objs.push({id: field.name, attributes: { name: field.name } });
    });
    setObjectivesOptions(objs);
    return function cleanUp() {
      setObjectivesOptions([]);
    }
  }, [objectives, loadObjectives]);

  // Optimization form submission handler.
	const onSubmit = async (formValues: any) => {

		let variant:VariantType = 'success';

    if (model.soeModelId) {
      sendRequest(Utils.getSimulationPayload(model)).then((res: any) => {
        setResult(res);
        // Store last optimization result.
        dispatch({ type: ActionType.SAVE_OPTIMIZATION_RESULT, payload: res });
      })
    }

    // try {
    //   const res: any = await dispatch(runSimulationAndOptimization(data));
    //   if (res && res.status === 200) {
    //     enqueueSnackbar('Optimization successfully started.', { variant });
    //   }
    //   else {
    //     variant = 'error';
    //     const code = (res && res.status) ? res.status : 'Unknown';
    //     const msg = (res && res.data.message) ? res.data.message : 'SOE Connection Error.';
    //     enqueueSnackbar('An error occurred. Error Code: ' + code + ' Error Message: ' + msg, { variant });
    //   }
    // } catch (error) {
    //   variant = 'error';
		// 		enqueueSnackbar('An error occurred when trying to trigger the optimization.', { variant });
    // }
	};

  const handleClearOptimization = () => {
    dispatch(clearOptimization());
  }

	return (
    <Grid item xs={12} lg={8} xl={6} sx={{'& .MuiFormControl-root': { paddingBottom: '20px'}}}>
      <form
        onSubmit={props.handleSubmit(onSubmit)}
        className="optimization-form"
        noValidate
        autoComplete="off"
        style={{
          height: "100%",
          textAlign: "left",
          paddingLeft: 15,
          paddingRight: 15
        }}
      >
        {(false && optimizations && optimizations.length > 0) ? (
          <Box>
            <Typography variant={"h6"} sx={{ marginBottom: 1 }}>New Result</Typography>
            <Box sx={{ overflow: 'auto' }}>
              {Object.keys(optimizations).map((item: any, i) => (
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "4px" }} key={item}>
                  <ArrowUpwardIcon sx={{ color: 'black', mr: 1 }} />
                  <Typography sx={{ marginRight: 1 }}>{item}: </Typography>
                  <Typography>{optimizations[item]} </Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <LoadingButton
                variant="contained"
                color="info"
                size="medium"
                type="button"
                className="button"
                sx={{ padding: "6px 20px", textTransform: 'capitalize', width: 1, mb: 2, mt: 4 }}
                disabled
              >
                Save new optimization snapshot
              </LoadingButton>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={handleClearOptimization}
                sx={{ padding: "6px 20px", textTransform: 'capitalize', width: 1, mb: 2, mt: 4 }}
              >
                Run a New Optimization
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Grid item xs={12}>
              <Field name="name"
                      component={TextElementNew}
                      placeholder="Select Name"
                      label="Optimization Name"
                      size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Field name="objective"
                      component={SelectElement}
                      components={objectivesOptions}
                      label="Objective"
                      defaultValue={"IRR"}
                      required
                      size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" sx={{ color: theme.palette.info.main, mb: 2 }}>
                Parameters
              </Typography>
              <Field name="pvSize"
                      component={TextWithUnitsElement}
                      units="KW"
                      label="PV Size"
                      size="medium"
                      defaultValue={200}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 5 }}>
              <Typography variant="h6" component="h6" sx={{ color: theme.palette.info.main, mb: 2 }}>
                Constraints
              </Typography>
              <Field name="battery_size"
                      component={InputSlider}
                      label="Battery Size (KWh)"
                      minBound={initBounds[0]}
                      maxBound={initBounds[1]}
                      minDefaultValue={initBounds[0]}
                      maxDefaultValue={initBounds[1]}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={isLoading === true}
                loadingPosition="start"
                variant="contained"
                color="info"
                size="medium"
                type="submit"
                className="button"
                sx={{ padding: "6px 20px", textTransform: 'capitalize', width: 1/1 }}
                startIcon={<ModelTrainingIcon/>}
              >
                {
                  isLoading === true
                  ?
                    "Optimization in progress"
                  :
                    "Run Optimization"
                }

              </LoadingButton>
            </Grid>
          </>
        )}
      </form>
    </Grid>
	);
}

const mapStateToProps = (state: any) => {

  let model: any = null;
  if (state.context.activeProject) {
    model = state.context.activeProject.models.find((el:any) => el.uuid === state.context.activeModelId);
  }

	return {
		activeProjectId: state.context.activeProjectId,
		lastOptimizationResult: state.context.lastOptimizationResult,
    objectives: state.wizard.objectives,
    model: model,
    selectedTariff: state.form.GridWizardForm?.values.tariff,
		// initialValues: {
    //   name: '',
		// 	pvSize: 0,
    //   objective: '',
    //   battery_size: [0, 8000]
		// }
	};
};

export default connect(mapStateToProps, { runOptimization, loadObjectives })
(reduxForm({
	form: 'OptimizationForm',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(BatteryOptimizer));
