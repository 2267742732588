// prettier-ignore
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import {Route, Router} from "react-router-dom";
import { history } from "./configureStore";
import withRoot from "./withRoot";
import logo from './assets/images/logo-black.svg';
import LogInPage from "./pages/LogInPage";
import Auth from "./components/auth/Auth";
import SideNav from './components/navigation/SideNav';
import RouterBreadcrumbs from './components/RouterBreadcrumbs';
import GridWizardPage from './pages/GridWizardPage';
import { isLoggedIn } from "./actions/auth";
import PrivateRoute from "./components/auth/PrivateRoute";
import { setActiveProjectId, setPageTitle } from "./actions/context";
import { resetWizard } from "./actions/wizard";
import Spinner from "./components/common/Spinner.component";
import SuppliersListPage from "./pages/SuppliersListPage";
import DashboardPage from "./pages/DashboardPage";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import MainMenuElement from "./components/navigation/MainMenu";
import ModelListPage from "./pages/ModelListPage";
import NewModelPage from "./pages/NewModelPage";
import GridWizardModelCreatePage from "./pages/GridWizardModelCreatePage";
import CommonModal from "./components/common/Modal";
import ReportPage from "./pages/ReportPage";
import ReportsPage from "./pages/ReportsPage";
import DocumentationPage from "./pages/DocumentationPage";

function Routes() {
	return (
		<>
			<Route exact={true} path="/login" component={LogInPage} />
			<PrivateRoute exact={true} path="/" component={DashboardPage} />
			<PrivateRoute exact={true} path="/project-new" component={GridWizardPage} />
      <PrivateRoute exact={true} path="/suppliers" component={SuppliersListPage} />
			<PrivateRoute exact={true} path="/project/:projectId" component={ModelListPage} />
      <PrivateRoute exact={true} path="/project/:projectId/reports" component={ReportsPage} />
      <PrivateRoute exact={true} path="/project/:projectId/reports/:reportId" component={ReportPage} />
      <PrivateRoute exact={true} path="/project/:projectId/documents" component={DocumentationPage} />
      <PrivateRoute exact={true} path="/project/:projectId/models/new-model" component={NewModelPage} />
      <PrivateRoute exact={true} path="/project/:projectId/models/create" component={GridWizardModelCreatePage} />
		</>
	);
}

/**
 * Main Application Component.
 * @param props
 * @constructor
 */
function App(props: any) {

	const [mobileOpen, setMobileOpen] = React.useState(true);
  const { isModalOpen } = useSelector((state: any) => state.modal);
  const theme = useTheme();
	if (!props) {
		return null;
	}

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<>
		{
			props.isSignedIn ?
				<Router history={history}>
					<Box
            className={"appWrapper"}
          sx={{
            width: "100%",
            height: "100%",
            zIndex: 1,
            overflow: "hidden",
          }}
          >
						<Box
              className={"relativeWrapper"}
              sx={{
                position: "relative",
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                height: "100vh"
              }}
            >
              {
                props.isLoading ?
                  <Spinner />
                  :
                  ''
              }
							<AppBar
                // Header
              sx={{
                zIndex: theme.zIndex.drawer + 1,
                position: "absolute",
                height: "60px",
                flexShrink: "unset",
                flexDirection: "unset",
              }}
              >
								<Box
                sx={{
                  display: "flex",
                  flexBasis: "250px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "#f8f8f8",
                  borderRight: 'solid 1px #cfcfcf',
                  alignItems: "center",
                  justifyContent: "center",
                  '>img': {
                    maxWidth: '100%',
                  },
                  maxHeight: 60
                }}
                >
									<img src={logo} alt="Brightmerge Ali"/>
								</Box>
								<Toolbar
                sx={{
                  flex: "auto",
                  minHeight: "60px",
                  backgroundColor: "#fff",
                }}
                >
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={handleDrawerToggle}
										sx={{
                      [theme.breakpoints.up("lg")]: {
                        display: "none",
                      },
                    }}
									>

									</IconButton>
									<RouterBreadcrumbs/>
									<Auth/>
								</Toolbar>
							</AppBar>
              <Hidden smDown>
								<MainMenuElement />
              </Hidden>
              <SideNav />
							<Box
              sx={{
                display: "flex",
                overflowY: "auto",
                backgroundColor: theme.palette.background.default,
                width: "100%",
                height: '100vh',
                flex: 1,
                paddingTop: '62px',
              }}
              >
								<Routes/>
							</Box>
						</Box>
					</Box>
				</Router>
				:
				<Router history={history}>
					<Box
          sx={{
            display: "flex",
            backgroundColor: '#f5f5f5',
            width: "100%",
          }}
          >
						<Routes/>
					</Box>
				</Router>
		}
      <CommonModal
        open={isModalOpen}
      />
		</>
	);
}

const mapStateToProps = (state: any) => {
	return {
    activeProjectId: state.context.activeProjectId,
		isSignedIn: state.auth.isSignedIn,
    isLoading: state.context.isLoading
	};
};

export default connect(mapStateToProps, { isLoggedIn, setPageTitle, setActiveProjectId, resetWizard })(withRoot((App)));
