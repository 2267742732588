import * as React from "react";
import { connect, useSelector } from "react-redux";
import {List, ListItemIcon, ListItemText, MenuItem, Typography} from "@mui/material";
import { history } from "../../configureStore";
import { loadUserProjects, setActiveProjectId } from "../../actions/context";
import { useEffect, useState } from "react";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import {Project} from "../../model/model";
import Fade from "@mui/material/Fade";
import {useTheme} from "@mui/system";

function RecentProjectsList(props: any) {

	const [isLoaded, setIsLoaded] = useState(false);
	const [currentPath, setCurrentPath] = useState(window.location.pathname);
	const {userData, loadUserProjects} = props;
  const [isClick, setIsClick] = useState(false);
  const theme = useTheme();
  const { activeProjectId } = useSelector((state: any) => state.context);

	// Update current path for menu active state.
	useEffect(() => {
		const unListen = history.listen((location, action) => {
			setCurrentPath(location.pathname);
		});
		return function cleanUp() {
			unListen();
		}
	}, []);

	// Reload the projects every time the active project changes.
	useEffect(() => {
		if (userData && userData.current_user && !isClick) {
      loadUserProjects().then(() => {
        setIsLoaded((isLoaded) => true);
        setIsClick((isClick) => false);
      });
		}
	}, [userData, loadUserProjects, props.activeProjectId]);

	const handleClick = (e: any, project: Project) => {
    setIsClick(true);
    history.push({pathname: '/project/' + project.id, state: {projectId: project.id}} );
	};

	return(
    <>
      {
      props.projects.length > 0
        ?
        <div className={"recentProjects"}>
          <Typography variant="body1" sx={{marginTop: '30px', paddingLeft: '20px', color: theme.palette.secondary.light}}>Recent Projects</Typography>
          <Fade in={isLoaded}>
            <List style={props.style}>
              {props.projects.slice(0, 5).map((project: Project, index: number) => (
                <MenuItem key={index} onClick={(e) => handleClick(e, project)}
                          selected={currentPath === '/project/' + project.id || activeProjectId === project.uuid}
                          sx={{
                            whiteSpace: "pre-wrap"
                          }}>
                  <ListItemIcon>
                    <EmojiObjectsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={project.projectName} />
                </MenuItem>
              ))}
            </List>
          </Fade>
        </div>
        :
        ''
    }
    </>

	);

}

const mapStateToProps = (state: any) => {
  let activeProject = null;
  if (state.context.activeProjectId) {
    activeProject = state.context.projects.find((el: any) => el.uuid === state.context.activeProjectId);
  }
	return {
		userData: state.auth.userData,
		projects: state.context.projects,
		activeProjectId: state.context.activeProjectId,
    activeProject: activeProject,
		isSignedIn: state.auth.isSignedIn
	};
};

export default connect(mapStateToProps, { loadUserProjects, setActiveProjectId })(RecentProjectsList);
