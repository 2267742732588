import React, {useEffect} from 'react';
import {connect} from "react-redux";
import { useHistory } from "react-router";
import {setInnerNav} from "../../actions/context";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function InsightComponent(props: any) {

  const {setInnerNav} = props;
  const {insight, projectId} = props;
  const history = useHistory();

  // Update current path so that we can use it for active states.
  useEffect(() => {
    setInnerNav(false);
  }, []);

  const getEmbedCode = () => {
    if (insight && insight.hasOwnProperty('embed')) {
      return insight.embed;
    }
    return '<span></span>';
  }

  const goBack = () => {
    history.push({ pathname: '/project/' + projectId + '/reports'});
  }

  return (
    <Box sx={{ width: 1/1 }}>
      <Box sx={{ marginTop: 2, marginLeft: 3 }}>
        <Button
          variant="text"
          color="info"
          size="medium"
          className="button"
          sx={{
            padding: '6px 0',
            justifyContent: 'flex-start',
            textTransform: 'capitalize',
            fontWeight: 600
          }}
          onClick={goBack}
          startIcon={<ArrowBackIosIcon />}
        >
          Back to reports
        </Button>
      </Box>
      {
        insight
          ?
          (
            insight.type && insight.type === 'image'
              ?
              <Box sx={{ textAlign: 'center', padding: "20px", width: '100%', '&> img': { maxWidth: '100%', maxHeight: '100vh' }}}>
                <img src={process.env.REACT_APP_BASE_URL + insight.imageUrl}  alt={insight.name}/>
              </Box>
              :
              <Box sx={{ padding: "20px", width: '100%', '&> iframe': { width: '100%' }}} dangerouslySetInnerHTML={{__html: getEmbedCode()}}/>
          )
          :
          <></>
      }
    </Box>
  );
}

const mapStateToProps = (state: any) => {
  return {
    context: state.context,
  };
};

export default connect(mapStateToProps, {setInnerNav})(InsightComponent);
