import connector from "../api/connector";
import {FileEntity} from "../model/model";

export default class FileUtils {

  /**
   * Maps UI file fields to upload API endpoint.
   */
  static schema: any = {
    loadProfile: {
      entityType: 'component',
      bundle: 'consumer',
      field: 'load_profile'
    },
    singleLine: {
      entityType: 'node',
      bundle: 'project',
      field: 'field_single_line'
    },
    sitePlan: {
      entityType: 'node',
      bundle: 'project',
      field: 'field_site_plan'
    },
    solarAnalysis: {
      entityType: 'node',
      bundle: 'project',
      field: 'field_solar_analysis'
    }
  }

  static getFileEntities(files: []) {
    const fileEntities: FileEntity[] = [];
    files.length > 0 && files.forEach((file:any) => {
      fileEntities.push(
        {
          name: file?.attributes?.filename,
          size: Math.round(file?.attributes?.filesize / 1000),
          downloadUrl: file?.attributes?.uri?.url,
          uuid: file?.id
        }
      )
    });
    return fileEntities;
  }

  /**
   * Fetches the blobs for a given array of files - for setting initial values.
   * @param fileEntities
   */
  static getFileBlobs = async (fileEntities: FileEntity[]) => {
    let files:any = [];
    if (fileEntities && fileEntities.length > 0) {
      const config:any = { responseType: 'blob' };
      for (const fileEntity of fileEntities) {
        const res = await connector.get(process.env.REACT_APP_BASE_URL + fileEntity.downloadUrl, config);
        if (res.data) {
          files.push(new File([res.data], fileEntity.name));
        }
      }
    }
    return files;
  }

  // handleFileUploads(values); returns value array with updated fids where applicable
  static handleFileUploads = async (values: any, csrfToken: string) => {
    // Process all known file fields.
    for (const [key, value] of Object.entries(FileUtils.schema)) {
      //values.loadProfile[0]
      if (values.hasOwnProperty(key)) {
        let fids = [];
        for (const file of values[key]) {
          const fid: any = await FileUtils.uploadFile(key, file, csrfToken);
          if (fid) {
            fids.push(fid[0]['value']);
          }
        }
        values[key] = fids;
      }
    }
    return values;
  }


  static uploadFile = async (fieldKey: string, file: any, csrfToken: string) => {

    const data = await FileUtils.readFile(file);
    const schema = FileUtils.getFileSchema(fieldKey);
    const response = await connector.post(`/file/upload/${schema.entityType}/${schema.bundle}/${schema.field}?_format=json`,
      data,
      {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json',
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': 'file; filename="' + file.name + '"'
        }
      }
    );
    return (response && response.data.fid ? response.data.fid : false);
  }

  static readFile = (file: any) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    // Upload the file and get the created file's fid.
    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        resolve(reader.result);
      }
    });
  }

  static getFileSchema = (key: string): any => {
    return FileUtils.schema[key];
  }

}
