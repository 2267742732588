import { ActionType } from "../model/model";
import { setProgress } from "./context";

export const setModalProps = (payload: any) => {
  return {
    type: ActionType.SET_MODAL_PROPS,
    payload: payload
  }
}

export const setIsModalOpen = (payload: boolean) => {
  return {
    type: ActionType.SET_MODAL_STATUS,
    payload: payload
  }
}

export const setProgressModal = (payload: string) => {
  return {
    type: ActionType.SET_PROGRESS_MODAL_TEXT,
    payload: payload
  }
}

export const setShowExtraButton = (payload: any) => {
  return {
    type: ActionType.SET_SHOW_EXTRA_BUTTON,
    payload
  }
}

export const setShowDocumentModal = (payload: any) => {
  return {
    type: ActionType.SET_SHOW_DOCUMENT_MODAL,
    payload
  }
}

export const setModalStatus = (payload: boolean) => async (dispatch: Function, getState: Function) => {
  try {
    dispatch(setProgressModal(''));
    dispatch(setProgress(''));
    dispatch(setIsModalOpen(payload));
  } catch (error) {
    console.log(error);
  }
};