import { Typography } from "@mui/material";
import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from 'notistack';
import App from "./App";
import configureStore from "./configureStore";

const { persistor, store } = configureStore();

function ReduxRoot() {
	return (
		<Provider store={store}>
			<PersistGate
				loading={<Typography>Loading...</Typography>}
				persistor={persistor}
			>
				<SnackbarProvider maxSnack={3}>
					<App />
				</SnackbarProvider>
			</PersistGate>
		</Provider>
	);
}

export default ReduxRoot;
