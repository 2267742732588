import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import AddressSearch from '../form/address';
import {connect, useSelector, useDispatch} from "react-redux";
import {FormHelperText, Typography, useTheme} from "@mui/material";
import Grid from '@mui/material/Grid';
import CheckBoxElement from '../form/checkbox';
import {useSnackbar, VariantType} from "notistack";
import axios from "axios";
import {getUnixTime} from "date-fns";
import {setActiveProjectId, setLoadingType, setPageTitle, updateProject} from "../../actions/context";
import useScript from 'react-script-hook';
import {useEffect, useState} from "react";
import * as Constant from "../../model/constant";
import TextElementNew from "../form/textfieldnew";
import DatePickerElement from "../form/datepicker";
import RadioBoolElement from "../form/radiobool";
import FormControl from "@mui/material/FormControl";
import FileDropZone from "../form/dropzone";
import {FormLabel, MenuItem, Select} from "@mui/material";
import RadioGroupElement from "../form/radiogroup";
import CheckBoxElementNew from "../form/checkboxnew";
import {resetWizard} from "../../actions/wizard";
import FileUtils from "../../utility/file.utils";

// Define validation functions here.
const validate = (values: any) => {

  const errors: any = {};

  if (!values.projectName) {
    errors.projectName = 'Site name is required';
  }
  if (!values.location) {
    errors.location = 'Address is required';
  }
  else if (typeof values.location !== 'object') {
    errors.location = 'Please search and select a valid address.';
  }

  return errors;
};

function ProjectDetails(props: any) {

    const {enqueueSnackbar} = useSnackbar();
    const [isGMAPLoaded, setIsGMAPLoaded] = useState(true); // Loaded in index.html
    const [dropOpenSingeLine, setSingeLineDropZoneOpen] = useState(false);
    const [dropOpenSitePlan, setSitePlanDropZoneOpen] = useState(false);
    const [isExistingEms, setIsExistingEms] = useState(false);
    const {activeProjectId, loadingType} = props;
    const [isEMS, setIsEMS] = useState('no'); // @todo init for edit case.
    let i: any = [];
    const [singleLineBlobs, setSingleLineBlobs] = useState(i);
    const [sitePlanBlobs, setSitePlanBlobs] = useState(i);
    const { formMode, gridInitialValues } = useSelector((state: any) => state.context);

    useScript({
      src: 'https://maps.googleapis.com/maps/api/js?key=' + Constant.GOOGLE_MAP_API_KEY + '&libraries=places',
      checkForExisting: true,
      onload: () => {setIsGMAPLoaded(true)},
      onError: () => {setIsGMAPLoaded(false)}
    });

    // Update UI state to display or hide EMS field.
    const toggleEMS = (e:any) => {
      setIsEMS(e.target.value === 'yes' ? 'no' : 'yes');
    };

    const toggleEnergySystem = (input:any) => {
      setIsExistingEms(input);
    };

    //Init EMS UI only fields based on initialValues for existing project.
    useEffect(() => {
      if (!activeProjectId && loadingType === 'reload') {
        props.initialize({location: null}); // @todo Run this only when furthest wizard step is 1.
        props.setLoadingType('stay');
        setSingleLineBlobs(i);
        setSitePlanBlobs(i);
      }
    },[activeProjectId, loadingType]);

    useEffect(() => {
      if (gridInitialValues) {
        FileUtils.getFileBlobs(gridInitialValues.singleLineFiles).then((res) => {
          setSingleLineBlobs(res);
        });
        FileUtils.getFileBlobs(gridInitialValues.sitePlanFiles).then((res) => {
          setSitePlanBlobs(res);
        });
        props.initialize(gridInitialValues);
      }
    }, [gridInitialValues]);

  /**
   * Submit handler for new projects.
   * Also makes a call to google's api to retrieve the timezone for the address.
   * @param formValues
   */
  const onSubmit = (formValues: any) => {
    let variant:VariantType = 'success';
    // Notify user if location is missing.
    if (!formValues.location) {
      variant = 'error';
      enqueueSnackbar('An address for the project must be provided.', { variant });
    }
    else {
      // Get project timezone and add it to payload.
      (async () => {
        try {
          formValues.location.lon = formValues.location.longitude ? formValues.location.longitude : formValues.location.lon;
          formValues.location.lat = formValues.location.latitude ? formValues.location.latitude : formValues.location.lat;
          const timezone:any = await axios.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + formValues.location.lat + "," + formValues.location.lon + "&timestamp=" + getUnixTime(new Date()) +  "&key=AIzaSyDNWU1O-o17HmaZFbloLGXL_5ym-bbJB7s");
          formValues.timezone = timezone.data.timeZoneId ? timezone.data.timeZoneId : '';
        }
        catch(error) {
          variant = 'error';
          enqueueSnackbar('Address is missing or invalid. Please choose one on the "Project Details" step.', { variant });
          return;
        }
      })().then(
        () => {
          if (formValues.timezone) {
            // Save the new project.
            props.updateProject(props.activeProjectId, formValues).then(
              () => {
                enqueueSnackbar('Project ' + formValues.projectName + ' updated.', { variant });
			    props.setActiveProjectId(props.activeProjectId);
              },
              () => {
                variant = 'error';
                enqueueSnackbar('An error occurred.', { variant });
              }
            );
          }
        }
      );
    }
  };

	return (
		<div>
			<form onSubmit={props.handleSubmit(onSubmit)} className="form" noValidate autoComplete="off"
        style={{
          height: '100%',
          textAlign: 'left',
          paddingTop: 20,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >

        <Grid container spacing={2} sx={{ mb: 2 }}>
          {
            formMode == 'create' && (
              <>
                <Grid item lg={4} xs={6}>
                  <RadioGroupElement
                    defaultValue="startNewModel"
                    radios={[
                      {label: 'Start project from existing model or template', value: "startFromExisting", disabled: true },
                      {label: 'Start a new model', value: "startNewModel" }
                    ]}
                  >
                  </RadioGroupElement>
                </Grid>
                <Grid item lg={2} xs={3}>
                  <Select
                    labelId="simple-select-disabled-label"
                    id="simple-select-disabled"
                    value={0}
                    label="Select Model"
                    disabled={true}
                    size={"small"}
                    sx={{ width: 1/1 }}
                  >
                    <MenuItem value={0}>Select Model</MenuItem>
                  </Select>
                </Grid>
                <Grid item lg={2} xs={3}>
                  <Select
                    labelId="simple-select-disabled-label"
                    id="simple-select-disabled-2"
                    value={1}
                    label="Select Template"
                    disabled={true}
                    size={"small"}
                    sx={{ width: 1/1 }}
                  >
                    <MenuItem value={1}>Select Template</MenuItem>
                  </Select>
                </Grid>
              </>
            )
          }
        </Grid>
        <Grid container spacing={2}  sx={{ mb: 2 }}>
          <Grid item xs={5} lg={3}>
            <Field
              name="projectName"
              component={TextElementNew}
              label="Project name"
              placeholder="Choose your project name."
              required
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
          <Grid item xs={7} lg={5}>
            {
              isGMAPLoaded
                ?
                <Field
                  name="location"
                  component={AddressSearch}
                  label="Site Address"
                  placeholder="Search for site's location."
                  required
                  props={{
                    disabled: formMode !== 'create'
                  }}
                />
                :
                ''
            }
          </Grid>
        </Grid>
        <Grid container spacing={2}  sx={{ mb: 4 }}>
          <Grid item lg={4} xs={6}>
            <Field
              name="description"
              component={TextElementNew}
              multiline={true}
              minRows={4}
              placeholder="Describe your project."
              label="Project Description"
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
          <Grid item lg={4} xs={6}>
            <Field
              name="managedBy"
              component={TextElementNew}
              label="Managed By"
              placeholder="Set the project's manager."
              props={{
                disabled: formMode !== 'create'
              }}
            />
            <Field
              sx={{
                marginTop: '20px',
                fontSize: 13
              }}
              name="operationsDate"
              component={DatePickerElement}
              label="Expected Commercial Operations Date"
              placeholder="Set the project's manager."
              key={activeProjectId}
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}  sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Primary Contact
            </Typography>
          </Grid>
          <Grid item xs={4} lg={3}>
            <Field
              name="primaryContactName"
              component={TextElementNew}
              label="Name"
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Field
              name="primaryContactEmail"
              component={TextElementNew}
              label="Email"
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <Field
              name="primaryContactPhone"
              component={TextElementNew}
              label="Phone"
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}  sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>
              Energy Systems
            </Typography>
          </Grid>
          <Grid item xs={6} lg={4}>
            <RadioBoolElement
              label="Are you using an Energy Management System (EMS)?"
              onChange={(e:any) => toggleEMS(e)}
              defaultValue={isEMS}
              disabled={formMode !== 'create'}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            {
              isEMS ?
                <Field
                  name="ems"
                  component={TextElementNew}
                  label="EMS being used (Model, Provider, Year)"
                  props={{
                    disabled: formMode !== 'create'
                  }}
                />
                :
                ''
            }
          </Grid>
        </Grid>
        <Grid container spacing={2}  sx={{ mb: 2 }}>
          <Grid item xs={12} xl={4}>
            <Field
              name="isGridTied"
              component={CheckBoxElement}
              label="Connected to the grid"
              props={{
                disabled: formMode !== 'create'
              }}
            />
            <FormHelperText>Are you connected to the grid, or is this an Islanded microgrid?</FormHelperText>
          </Grid>
          <Grid item md={4} lg={3} xl={2}>
            <Typography variant={"h6"}>
              Attach Files
            </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">Single Line Diagram</FormLabel>
              <Field name="singleLineFiles"
                     component={FileDropZone}
                     dropOpen={dropOpenSingeLine}
                     setDropZoneOpen={setSingeLineDropZoneOpen}
                     dialogTitle="Upload a single line diagram"
                     dropzoneText="Drag & Drop a file, or click to select one."
                     buttonLabel="Upload diagram"
                     acceptedFiles={['.doc', '.docx', '.pdf', '.jpg', '.png']}
                     maxFileSize={10000000}
                     filesLimit={1}
                     initialFiles={singleLineBlobs}
                     props={{
                      disabled: formMode !== 'create'
                    }}
                     //validate={fileDropped}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} lg={3} xl={2}>
            <Typography variant={"h6"}>
              &nbsp;
            </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">Site Plan</FormLabel>
              <Field name="sitePlanFiles"
                     component={FileDropZone}
                     dropOpen={dropOpenSitePlan}
                     setDropZoneOpen={setSitePlanDropZoneOpen}
                     dialogTitle="Upload a site plan"
                     dropzoneText="Drag & Drop a file, or click to select one."
                     buttonLabel="Upload plan"
                     acceptedFiles={['.pdf', '.jpg', '.png']}
                     maxFileSize={10000000}
                     filesLimit={1}
                     initialFiles={sitePlanBlobs}
                     props={{
                      disabled: formMode !== 'create'
                    }}
                     // validate={fileDropped}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}  sx={{ mb: 2 }}>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              name="isExistingEms"
              component={CheckBoxElementNew}
              defaultChecked={false}
              updateState={toggleEnergySystem}
              label="Any existing / in progress energy system related to this site?"
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {
              isExistingEms ?
                <Field
                  name="existingEms"
                  component={TextElementNew}
                  label="Energy System related to this site"
                  props={{
                    disabled: formMode !== 'create'
                  }}
                />
                :
                ''
            }
          </Grid>
        </Grid>

        {/*{*/}
        {/*  dirty && valid && formMode === 'edit' ?*/}
        {/*    <>*/}
        {/*      <Button*/}
        {/*        variant="contained"*/}
        {/*        color="primary"*/}
        {/*        size="medium"*/}
        {/*        type="submit"*/}
        {/*        className="button"*/}
        {/*        sx={{marginTop: '20px'}}*/}
        {/*        startIcon={<SaveOutlinedIcon />}*/}
        {/*        disabled={!valid}*/}
        {/*      >*/}
        {/*        Update details*/}
        {/*      </Button>*/}
        {/*      <Typography variant="overline" display="block" className="changes" sx={{color: 'red'}}>*/}
        {/*        *You have unsaved changes.*/}
        {/*      </Typography>*/}
        {/*      {*/}
        {/*        props.submitting*/}
        {/*          ?*/}
        {/*          <CircularProgress />*/}
        {/*          :*/}
        {/*          ''*/}
        {/*      }*/}
        {/*    </>*/}
        {/*    :*/}
        {/*    ''*/}
        {/*}*/}
			</form>
		</div>
	);
}

const mapStateToProps = (state: any) => {
  return {
    context: state.context,
    activeProjectId: state.context.activeProjectId,
    loadingType: state.context.loadingType,
    initialValues: state.form.GridWizardForm?.values || {}
  };
};

export default connect(mapStateToProps, {updateProject, setActiveProjectId, resetWizard, setPageTitle, setLoadingType})
(reduxForm({
  form: 'GridWizardForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true,
})(ProjectDetails));
