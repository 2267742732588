import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect, useSelector} from "react-redux";

import {setActiveProjectId, updateProject} from "../../actions/context";
import {Grid, Typography} from "@mui/material";
import TextElementNew from "../form/textfieldnew";
import SortableField from "../form/SortableField";
import {useEffect} from "react";

const validate = (values: any) => {
	const errors: any = {};
	return errors;
};

function BusinessGoals(props: any) {

  const {activeProjectId} = props;
  const { formMode } = useSelector((state: any) => state.context);

	return (
		<div>
			<form className="form" noValidate autoComplete="off"
        style={{
          height: '100%',
          textAlign: 'left',
          paddingTop: 20,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} md={8} xl={5}>
            <Field name="mainBusinessGoal"
                   component={TextElementNew}
                   multiline
                   minRows={2}
                   label="Enter the project's Goal"
                   helperText="Test"
                   props={{
                    disabled: formMode !== 'create'
                  }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={4} xl={7}>
          </Grid>
          <Grid item xs={12} sm={10} md={8} xl={5}>
            <Typography variant={"h6"}>
              Business Objectives Prioritization
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} md={4} xl={7}>
          </Grid>
          <Grid item xs={12} sm={10} md={8} xl={5}>
            <Field
              name="objectivePriorities"
              component={SortableField}
              items={props.initialValues.objectivePriorities || []}
              key={activeProjectId}
              props={{
                disabled: formMode !== 'create'
              }}
            >
            </Field>
          </Grid>
          <Grid item xs={12} sm={2} md={4} xl={7}>
          </Grid>
          <Grid item xs={12} sm={10} md={8} xl={5}>
            <Field
              name="otherBusinessObjective"
              component={TextElementNew}
              label="Other business objective"
              placeholder="Specify any other business objective"
              props={{
                disabled: formMode !== 'create'
              }}
            />
          </Grid>
        </Grid>
			</form>
		</div>
	);
}

const mapStateToProps = (state: any) => {
  const defaultObjectivePriorities: any = [
    "Reduce Costs",
    "Reduce Emissions",
    "Improve Reliability and Resiliency",
  ];

  const initialValues: any = {
    ...state.form.GridWizardForm?.values,
    objectivePriorities: state.form.GridWizardForm?.values?.objectivePriorities || defaultObjectivePriorities
  }

  return {
    activeProjectId: state.context.activeProjectId,
    initialValues: initialValues
  };
};

export default connect(mapStateToProps, {updateProject, setActiveProjectId})
(reduxForm({
  form: 'GridWizardForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true,
})(BusinessGoals));
