import { Action, ActionType, Auth } from "../model/model";
import createReducer from "./createReducer";

const initialState = {
	isSignedIn: false,
	sessionToken: '',
	userData: {},
	errors: {}
};

export const authReducer = createReducer<Auth>(initialState, {
	[ActionType.LOGIN_STATUS](state: Auth, action: Action<any>) {

    let newUserData:any = {};
	  if (action.payload.data === 1) {
      newUserData = action.payload.userData;
    }

		return {...state, isSignedIn: (action.payload.data === 1), userData: newUserData, errors: {}};
	},
	[ActionType.TRY_SIGN_IN](state: Auth, action: Action<any>) {
		if (action.payload && action.payload.status === 200) {
			return {...state, isSignedIn: true, userData: action.payload.data, errors: {} };
		}
		else if (action.payload && action.payload.data.message) {
			return {...state, userData: {}, isSignedIn: false, errors: { message: action.payload.data.message, code: action.payload.status }};
		}
		else {
			return {...state, userData: {}, isSignedIn: false, errors: { message: 'An unknown error occurred.', code: 500 }};
		}
	},
	[ActionType.TRY_SIGN_OUT](state: Auth, action: Action<Auth>) {
		return {...state, isSignedIn: false, userData: {}};
	},
	[ActionType.RESET_STORE](state: Auth, action: Action<Auth>) {
		return {...state, ...initialState};
	}
});
