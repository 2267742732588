import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {Box, useTheme} from "@mui/system";

const Spinner = (props: any) => {

  const theme = useTheme();

	return (
		<Box className="spinnerLayout"
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        position: 'absolute',
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.info.main,
        zIndex: 99999
      }}
    >
			<CircularProgress sx={{color: theme.palette.info.main}} />
		</Box>
	);
};

export default Spinner;
