import {useState} from "react";
import axios from "axios";

export default function useSoe(): any {
  const [isLoading, setIsLoading] = useState(false);
  const endpoint = process.env.REACT_APP_SOE_ENDPOINT ? process.env.REACT_APP_SOE_ENDPOINT : '';

  async function sendRequest(params: any) {
    try {
      setIsLoading(true);
      const response = await axios.post(endpoint,
        {
          ...params,
        });
      setIsLoading(false);
      return response.data;
    } catch {
      const err = {error: "Soe could not be called."};
      setIsLoading(false);
      return err;
    }
  }

  return [isLoading, sendRequest];
}
