import * as React from 'react';
import TextField from '@mui/material/TextField';
import Sortable from "./Sortable";
import {useEffect, useState} from "react";

function SortableField(props: any) {
	// const { touched, error } = props.meta;
	// const {disabled} = props || false;
  const [sortedItems, setSortedItems] = useState(props.items);

  const handleChange = (items:any) => {
    props.input.onChange(items);
    setSortedItems(items);
  }

  useEffect(() => {
    handleChange(props.items);
  },[]);

	return (
	  <>
		<TextField
      {...props.input}
      value={sortedItems}
      type="hidden"
      className={"hidden"}
		/>
      <Sortable {...props} items={props.items} useDragOverlay={false} handleChange={handleChange} handle />
    </>
	);
}

export default SortableField;
