import {
  Action,
  ActionType,
  Auth,
  Context,
  Consumer,
  Optimization,
  Project,
  FileEntity,
  Producer,
  Storage,
  Model
} from "../model/model";
import createReducer from "./createReducer";
import ComponentUtils from "../utility/components.utils";
import FileUtils from "../utility/file.utils";

const initialState = {
  loadingType: '',
	pageTitle: '',
	innerNav: false,
	activeProjectId: '',
  activeModelId: '',
  formMode: 'create',
	projects: [],
  consumers: [],
  producers: [],
  storages: [],
	optimizations: [],
  lastOptimizationResult: {},
  isLoading: false,
  inputSchema: {body:[]},
  progress: '',
  activeProject: null,
  newModelId: '',
  modalProps: {},
  isModalOpen: false,
  gridInitialValues: null,
  documentTypes: [],
  documents: [],
  documentsCount: 0
};

export const contextReducer = createReducer<Context>(initialState, {
  [ActionType.SET_ADD_PROJECT_LOADING_TYPE](state: Context, action: Action<any>) {
		return {...state, loadingType: action.payload.type};
	},
	[ActionType.SET_PAGE_TITLE](state: Context, action: Action<any>) {
		return {...state, pageTitle: action.payload.title};
	},
  [ActionType.SET_PROGRESS](state: Context, action: Action<any>) {
    return {...state, progress: action.payload.progress};
  },
	[ActionType.SET_INNER_NAV](state: Context, action: Action<any>) {
		return {...state, innerNav: action.payload.innerNav};
	},
	[ActionType.SAVE_NEW_PROJECT](state: Context, action: Action<any>) {
		if (action.payload.data) {
			return {...state, activeProjectId: action.payload.data.activeProjectId, errors: {}};
		}
		return {...state, errors: { message: action.payload.response.message }};

	},
  [ActionType.UPDATE_PROJECT](state: Context, action: Action<any>) {
    const projectData = action.payload.data.project;
    const isGridTied = action.payload.data.isGridTied;
    const projectToUpdate = state.projects.find((el: any) => el.uuid === state.activeProjectId);
    if (projectToUpdate) {
      projectToUpdate.projectName = projectData.attributes.title;
      projectToUpdate.location = projectData.attributes.field_location;
      projectToUpdate.address = projectData.attributes.field_address;
      projectToUpdate.timezone = projectData.attributes.field_timezone;
      projectToUpdate.managedBy = projectData.attributes.field_managed_by;
      const gridToUpdate = ComponentUtils.getComponentByType(projectToUpdate.gridNetwork, 'component--grid');
      gridToUpdate[0].attributes.is_grid_tied = isGridTied;
    }

    if (action.payload.data) {
      return {...state, projects: state.projects, activeProjectId: action.payload.data.activeProjectId, pageTitle: projectData.attributes.title, errors: {}};
    }
    return {...state, errors: { message: action.payload.response.message }};
  },
	[ActionType.SET_ACTIVE_PROJECT_ID](state: Context, action: Action<any>) {
    if (action.payload.id) {
      return {...state, activeProjectId: action.payload.id, errors: {}};
    } else {
      return {...state, activeProjectId: action.payload.id, activeProject: null, errors: {}};
    }
	},
  [ActionType.SET_ACTIVE_MODEL_ID](state: Context, action: Action<any>) {
		return {...state, activeModelId: action.payload.id, errors: {}};
	},
  [ActionType.SET_PROJECT_FORM_MODE](state: Context, action: Action<any>) {
    return {...state, formMode: action.payload.mode, errors: {}};
  },
	[ActionType.RESET_STORE](state: Context, action: Action<Auth>) {
		return {...state, ...initialState};
	},
  [ActionType.LOADING](state: Context, action: Action<any>) {
    return { ...state, isLoading: action.payload };
  },
	[ActionType.LOAD_USER_PROJECTS](state: Context, action: Action<any>) {
		if (action.payload.data && action.payload.data.data) {
			// Map to project interface (@todo move this to factory)
			const projects = action.payload.data.data.map((project: any, index: any) => {

        // Fetch the project parameters.
        const projectParameters = project.attributes.field_parameters ? JSON.parse(project.attributes.field_parameters) : {};

        // Fetch the primary contact details.
        const primaryContact = ComponentUtils.getRelatedDataByUuid(action.payload.data, project.relationships?.field_primary_contact?.data?.id);

        // Fetch project Models.
        // @todo: generalize this to multiple models.
        const projectBaselineModel = ComponentUtils.getRelatedDataByUuid(action.payload.data, project.relationships?.field_models?.data[0]?.id);
        const projectPlannedModel = ComponentUtils.getRelatedDataByUuid(action.payload.data, project.relationships?.field_models?.data[1]?.id);

        const singleLine = ComponentUtils.getRelatedDataByUuid(action.payload.data, project.relationships?.field_single_line?.data?.id);
        const sitePlan = ComponentUtils.getRelatedDataByUuid(action.payload.data, project.relationships?.field_site_plan?.data?.id);
        const solarAnalysis = ComponentUtils.getRelatedDataByUuid(action.payload.data, project.relationships?.field_solar_analysis?.data?.id);

        const singleLineFiles: FileEntity[] = FileUtils.getFileEntities(singleLine);
        const sitePlanFiles: FileEntity[] = FileUtils.getFileEntities(sitePlan);
        const solarAnalysisFiles: FileEntity[] = FileUtils.getFileEntities(solarAnalysis);

        const models = [
          {
            id: projectBaselineModel[0]?.attributes.drupal_internal__id,
            uuid: projectBaselineModel[0]?.id,
            parameters: projectBaselineModel[0] ? JSON.parse(projectBaselineModel[0]?.attributes.field_parameters) : null,
            name: projectBaselineModel[0]?.attributes.name,
            user_id: projectBaselineModel[0]?.relationships?.user_id?.data?.meta?.drupal_internal__target_id,
            soeModelId: '', // Cannot have an SOE model id.
            soeConfig: projectBaselineModel[0] ? JSON.parse(projectBaselineModel[0]?.attributes.field_soe_config) : null,
            gridNetworkId: project.relationships?.field_primary_contact?.data?.id
          },
          {
            id: projectPlannedModel[0]?.attributes.drupal_internal__id,
            uuid: projectPlannedModel[0]?.id,
            parameters: projectPlannedModel[0] ? JSON.parse(projectPlannedModel[0]?.attributes.field_parameters) : null,
            name: projectPlannedModel[0]?.attributes.name,
            user_id: projectPlannedModel[0]?.relationships?.user_id?.data?.meta?.drupal_internal__target_id,
            soeModelId: projectPlannedModel[0]?.attributes.field_soe_model_id,
            soeConfig: projectPlannedModel[0] ? JSON.parse(projectPlannedModel[0]?.attributes.field_soe_config) : null,
            gridNetworkId: project.relationships?.field_primary_contact?.data?.id
          },
        ];

        // Fetch the grid network from the planned model.
        // @todo this will need to come from the currently "Active" or latest version model in V0.3.
        let gridNetwork:any = {};
        if (models[1]) {
          const grid:any = ComponentUtils.getRelatedDataByUuid(action.payload.data, projectPlannedModel[0]?.relationships?.field_microgrid?.data?.id);
          gridNetwork.id = grid[0]?.id;
          gridNetwork.name = grid[0]?.attributes?.name;
          gridNetwork.components = grid[0]?.relationships?.links?.data;
        }

				const proj:Project = {
					id: project.attributes.drupal_internal__nid,
					uuid: project.id,
          created: project.attributes.created,
          changed: project.attributes.changed,
					projectName: project.attributes.title,
          location: project.attributes.field_location,
					address: project.attributes.field_address,
          timezone: project.attributes.field_timezone,
					managedBy: project.attributes.field_managed_by,
          hasInsights: project.relationships.field_insights.data.length > 0,
          gridNetwork: gridNetwork,
          description: project.attributes.field_description,
          ems: project.attributes.field_ems,
          operationsDate: project.attributes.field_operations_date,
          primaryContact: primaryContact.length > 0 ? {...primaryContact[0].attributes} : {},
          parameters: projectParameters,
          models: models,
          singleLineFiles: singleLineFiles,
          sitePlanFiles: sitePlanFiles,
          solarAnalysisFiles: solarAnalysisFiles,
          soe: project.attributes.field_soe || ''
				};

				return proj;
			});
			return {...state, projects: projects, errors: {}};
		}
		return {...state, projects: [], activeProjectId: ''};
	},
  [ActionType.LOAD_SPECIFIC_PROJECT](state: Context, action: Action<any>) {
    if (
      action.payload &&
      action.payload.response &&
      action.payload.response.data
    ) {
      const { response, hasLast } = action.payload;
      const project = response.data.data[0];

      // Fetch the project parameters.
      const projectParameters = project.attributes.field_parameters ? JSON.parse(project.attributes.field_parameters) : {};

      // Fetch the primary contact details.
      const primaryContact = ComponentUtils.getRelatedDataByUuid(response.data, project.relationships?.field_primary_contact?.data?.id);

      const singleLine = ComponentUtils.getRelatedDataByUuid(response.data, project.relationships?.field_single_line?.data?.id);
      const sitePlan = ComponentUtils.getRelatedDataByUuid(response.data, project.relationships?.field_site_plan?.data?.id);
      const solarAnalysis = ComponentUtils.getRelatedDataByUuid(response.data, project.relationships?.field_solar_analysis?.data?.id);

      const singleLineFiles: FileEntity[] = FileUtils.getFileEntities(singleLine);
      const sitePlanFiles: FileEntity[] = FileUtils.getFileEntities(sitePlan);
      const solarAnalysisFiles: FileEntity[] = FileUtils.getFileEntities(solarAnalysis);

      let latestModel = response.data.included
        .filter((data: any, index: number) => data.type === 'model--model')
        .sort((a: any, b:any) => (new Date(b.attributes.changed) as any) - (new Date(a.attributes.changed) as any));

      latestModel = (latestModel.length > 2 || latestModel.length === 1) ? latestModel[0] : latestModel[1];

      const models = response.data.included
        .filter((data: any, index: number) => data.type === 'model--model')
        .map((model: any, index: number) => {
          const model_parameter = model.attributes.field_parameters ? JSON.parse(model.attributes.field_parameters) : {};
          const modelObj: Model = {
            id: model.attributes.drupal_internal__id,
            uuid: model.id,
            parameters: model_parameter,
            name: model.attributes.name,
            user_id: model.relationships?.user_id?.data?.meta?.drupal_internal__target_id,
            soeModelId: model.attributes.field_soe_model_id,
            soeConfig: JSON.parse(model.attributes.field_soe_config),
            gridNetworkId: model.relationships.field_microgrid.data.id
          }
          return modelObj;
        });

      // Fetch the grid network from the planned model.
      // @todo this will need to come from the currently "Active" or latest version model in V0.3.
      let gridNetwork:any = {};

      if (models && models.length > 0) {
        const selectedModel: any = state.activeModelId ? models.find((model: any, i: number) => model.uuid === state.activeModelId) : models[0];
        if (selectedModel) {
          const grid:any = ComponentUtils.getRelatedDataByUuid(response.data, selectedModel.gridNetworkId);
          gridNetwork.id = grid[0]?.id;
          gridNetwork.name = grid[0]?.attributes?.name;
          gridNetwork.components = grid[0]?.relationships?.links?.data;
        }
      }

      const activeProject:Project = {
        id: project.attributes.drupal_internal__nid,
        uuid: project.id,
        created: project.attributes.created,
        changed: project.attributes.changed,
        projectName: project.attributes.title,
        location: project.attributes.field_location,
        address: project.attributes.field_address,
        timezone: project.attributes.field_timezone,
        managedBy: project.attributes.field_managed_by,
        hasInsights: project.relationships.field_insights.data.length > 0,
        gridNetwork: gridNetwork,
        description: project.attributes.field_description,
        ems: project.attributes.field_ems,
        operationsDate: project.attributes.field_operations_date,
        primaryContact: primaryContact.length > 0 ? {...primaryContact[0].attributes} : {},
        parameters: projectParameters,
        models: models,
        singleLineFiles: singleLineFiles,
        sitePlanFiles: sitePlanFiles,
        solarAnalysisFiles: solarAnalysisFiles,
        soe: project.attributes.field_soe || ''
      };

      return { ...state, activeProject, activeProjectId: activeProject.uuid, activeModelId: (hasLast && latestModel) ? latestModel.id : state.activeModelId };
    }
    return { ...state, activeProject: null, activeProjectId: null };
  },
  [ActionType.LOAD_SPECIFIC_PROJECT_WITH_MODEL](state: Context, action: Action<any>) {
    if (
      action.payload &&
      action.payload.data
    ) {

      const models = action.payload.data.included.map((model: any, index: number) => {
        const model_parameter = model.attributes.field_parameters ? JSON.parse(model.attributes.field_parameters) : {};
        return {
          id: model.attributes.drupal_internal__id,
          uuid: model.id,
          parameters: model_parameter,
          name: model.attributes.name,
          user_id: model.relationships?.user_id?.data?.meta?.drupal_internal__target_id
        }
      });

      const activeProject:Project = {
        ...state.activeProject,
        models: models
      };

      return { ...state, activeProject };
    }
    return { ...state };
  },
  [ActionType.LOAD_USER_INPUT_SCHEMA](state: Context, action: Action<any>) {

    try {
      const response = action.payload;
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        const data = response.data.data[0];
        if (data.attributes.field_input_schema) {
          return {...state, inputSchema: JSON.parse(data.attributes.field_input_schema)};
        }
        else if (data.attributes.field_json_value) {
          return {...state, inputSchema: JSON.parse(data.attributes.field_json_value)};
        }
      }
      else {
        return {...state, errors: {}};
      }
    }
    catch (err) {
      console.error(err);
      return {...state};
    }
  },
  [ActionType.LOAD_PROJECT_OPTIMIZATIONS](state: Context, action: Action<any>) {
    if (action.payload && action.payload.length > 0) {
      // Map to optimization interface (@todo move this to factory)
      const optimizations = action.payload.map((optimization: any, index: any) => {
        const res:Optimization = {
          id: optimization.attributes.drupal_internal__id,
          uuid: optimization.id,
          name: optimization.attributes.name,
          created: optimization.attributes.created,
          changed: optimization.attributes.changed,
          status: optimization.attributes.internal_status,
          statusReason: optimization.attributes.status_reason,
          revisionCreated: optimization.attributes.revision_created,
          revisionLogMessage: optimization.attributes.revision_log_message,
          externalId: optimization.attributes.external_id,
        };
        return res;
      });
      return {...state, optimizations: optimizations, errors: {}};
    }
    return {...state, optimizations: []};
  },
  [ActionType.RUN_OPTIMIZATION](state: Context, action: Action<any>) {
    if (action.payload && action.payload.status === 200) {
      return {...state, lastOptimizationResult: action.payload.data, errors: {} };
    }
    else if (action.payload && action.payload.data.message) {
      return {...state, lastOptimizationResult: {}, errors: { message: action.payload.data.message, code: action.payload.status }};
    }

    return {...state, errors: { message: 'Unknown error occurred.' }};
  },
  [ActionType.LOAD_SAVED_CONSUMERS](state: Context, action: Action<any>) {

    if (action.payload && action.payload.data) {
      const consumers = action.payload.data;
      let result: Consumer[] = [];
      consumers.map((consumer: any, index: any) => {
        const component = consumer.data.data[0];
        let files: FileEntity[] = [];
        // Process files.
        if (consumer.data.included) {
          files = FileUtils.getFileEntities(consumer.data.included);
        }
        const item: Consumer = {
          id: component.attributes.drupal_internal__id,
          uuid: component.id,
          name: component.attributes.name,
          created: component.attributes.created,
          changed: component.attributes.changed,
          files: files
        };
        result.push(item);
        return result;
      });
      return {...state, consumers: result, errors: {}};
    }
    return {...state};
  },
  [ActionType.LOAD_SAVED_PRODUCERS](state: Context, action: Action<any>) {
    if (action.payload && action.payload.data) {
      const producers = action.payload.data;
      let result: Producer[] = [];
      producers.map((producer: any, index: any) => {
        const component = producer.data.data[0];
        const item: Producer = {
          id: component.attributes.drupal_internal__id,
          uuid: component.relationships.created_from.data.id,
          name: component.attributes.name,
          created: component.attributes.created,
          changed: component.attributes.changed,
          isBackup: component.attributes.is_backup,
          isExisting: component.attributes.is_existing,
          manufacturer: component.attributes.manufacturer,
          model: component.attributes.model,
          sku: component.attributes.sku,
          count: component.attributes.count,
          category: '', //component.attirbutes.category,
          sizing: component.attributes.sizing,
        };

        result.push(item);
      });
      return {...state, producers: result, errors: {}};
    }
    return {...state};
  },
  [ActionType.LOAD_SAVED_STORAGES](state: Context, action: Action<any>) {
    if (action.payload && action.payload.data) {
      const storages = action.payload.data;
      let result: Storage[] = [];
      storages.map((storage: any, index: any) => {
        const component = storage.data.data[0];
        const custom = JSON.parse(component.attributes.custom_properties);
        const item: Storage = {
          id: component.attributes.drupal_internal__id,
          uuid: component.relationships.created_from.data.id,
          name: component.attributes.name,
          created: component.attributes.created,
          changed: component.attributes.changed,
          isExisting: component.attributes.is_existing,
          manufacturer: component.attributes.manufacturer,
          model: component.attributes.model,
          sku: component.attributes.sku,
          count: component.attributes.count,
          category: '',
          capacity: component.attributes.capacity,
          dischargeRate: component.attributes.discharge_rate,
        };
        if (custom && custom.hasOwnProperty('ratedOutput')) {
          item.ratedOutput = custom.ratedOutput;
        }

        result.push(item);
      });
      return {...state, storages: result, errors: {}};
    }
  },
  [ActionType.CLEAR_NEW_MODEL_ID](state: Context, action: Action<any>) {
    return {...state, newModelId: ''};
  },
  [ActionType.SAVE_NEW_MODEL](state: Context, action: Action<any>) {
    if (action.payload.data) {
      return {...state, newModelId: action.payload.data.newModelId};
    }
    return {...state, newModelId: ''};
  },
  [ActionType.RUN_MODEL_OPTIMIZATION](state: Context, action: Action<any>) {
    if (action.payload && action.payload.data) {
      return {...state, optimizations: action.payload.data, errors: {}};
    }
    return {...state, optimizations: {}};
  },
  [ActionType.SAVE_OPTIMIZATION_RESULT](state: Context, action: Action<any>) {
    if (action.payload) {
      let opts = [...state.optimizations];
      if (opts.length < 2) {
        opts.unshift(action.payload);
      }
      else {
        opts.pop();
        opts.unshift(action.payload);
      }
      return {...state, optimizations: opts, errors: {}};
    }
    return {...state, optimizations: []};
  },
  [ActionType.CLEAR_OPTIMIZATION](state: Context, action: Action<any>) {
    return {...state, optimizations: {}};
  },
  [ActionType.SET_MODAL_STATUS](state: Context, action: Action<any>) {
    return {...state, isModalOpen: action.payload};
  },
  [ActionType.SET_MODAL_PROPS](state: Context, action: Action<any>) {
    return {...state, modalProps: action.payload};
  },
  [ActionType.DELETE_MODEL](state: Context, action: Action<any>) {
    if (action.payload) {
      const models = state.activeProject.models.filter((model: any) => model.id !== action.payload);

      const activeProject:Project = {
        ...state.activeProject,
        models: models
      };

      if (models.length > 0) {
        return { ...state, activeProject, activeModelId: models[models.length - 1].uuid };
      }

      return { ...state, activeProject };
    }

    return { ...state };
  },
  [ActionType.SET_INITIAL_GRIDFORM_VALUES](state: Context, action: Action<any>) {
    return {...state, gridInitialValues: action.payload};
  },
  [ActionType.LOAD_DOCUMENT_TYPES](state: Context, action: Action<any>) {
    if (action.payload.data) {
      const doc_types: any[] = [];
      action.payload.data.data.map((doc_type: any, index: number) => {
        doc_types.push({
          id: doc_type.attributes.drupal_internal__tid,
          name: doc_type.attributes.name
        });
      });
      return {...state, documentTypes: doc_types};
    }
    return {...state, documentTypes: []};
  },
  [ActionType.LOAD_DOCUMENTS](state: Context, action: Action<any>) {
    if (action.payload.data) {
      const docs: Array<Object> = [];
      const {meta} = action.payload.data;
      action.payload.data.data.map((doc: any, index: number) => {
        docs.push({
          id: doc.id,
          name: doc.attributes.title,
          link: doc.attributes.field_url.uri,
          description: doc.attributes.field_description,
          owner: doc.attributes.field_owner,
          type: doc.relationships.field_type.data.meta.drupal_internal__target_id,
          createdDate: doc.attributes.created,
        });
      });
      return {...state, documents: docs, documentsCount: parseInt(meta.count)};
    }
    return {...state, documents: [], documentsCount: 0};
  },
  [ActionType.SAVE_DOCUMENT](state: Context, action: Action<any>) {
    if (action.payload) {
      let docs = state.documents;
      docs.push({
        id: action.payload.id,
        name: action.payload.title[0],
        link: action.payload.field_url[0],
        description: action.payload.field_description[0],
        owner: action.payload.field_owner[0],
        type: action.payload.field_type[0],
        createdDate: action.payload.created,
      });
      return {...state, documents: [...docs]};
    }
    return {...state};
  },
});
