import {Modal, ActionType, Action} from "../model/model";
import createReducer from "./createReducer";

const initialState = {
  modalProps: {},
  isModalOpen: false,
  progressModalText: '',
  extraModalProps: {},
  isDocumentModalOpen: false,
};

export const modalReducer = createReducer<Modal>(initialState, {
  [ActionType.SET_MODAL_STATUS](state: Modal, action: Action<any>) {
    return {...state, isModalOpen: action.payload, extraModalProps: {}};
  },
  [ActionType.SET_MODAL_PROPS](state: Modal, action: Action<any>) {
    return {...state, modalProps: action.payload};
  },
  [ActionType.SET_PROGRESS_MODAL_TEXT](state: Modal, action: Action<any>) {
    return {...state, progressModalText: action.payload};
  },
  [ActionType.SET_SHOW_EXTRA_BUTTON](state: Modal, action: Action<any>) {
    return {...state, extraModalProps: action.payload};
  },
  [ActionType.SET_SHOW_DOCUMENT_MODAL](state: Modal, action: Action<any>) {
    return {...state, isDocumentModalOpen: action.payload};
  },
});
