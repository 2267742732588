import * as React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { setInnerNav, setPageTitle } from "../actions/context";
import {loadSuppliers} from "../actions/wizard";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Grid, Link,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {Supplier} from "../model/model";
import {styled} from "@mui/system";

const StyledTableCell = styled(TableCell)(
  ({ theme }) => (
    {
      head: {
        backgroundColor: '#343A40',
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }
  )
);

const StyledTableRow = styled(TableRow)(
  ({ theme }) => (
    {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }
  )
);

const getAddress = (address:any) => {
  return address.address_line1
  + ', ' + address.address_line2
  + address.locality + ', ' + address.postal_code
  + ', ' + address.country_code;
};

function Suppliers(props: any) {

  const {suppliers, loadSuppliers, setPageTitle, setInnerNav} = props;

	// Set the page title.
	useEffect(() => {
		setPageTitle('Supplier directory');
		setInnerNav(false);
    loadSuppliers();
	}, [setPageTitle, setInnerNav, loadSuppliers]);

	return (
    <TableContainer component={Paper} sx={{p: '50px'}}>
      <Table sx={{minWidth: 700}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>Company name</StyledTableCell>
            <StyledTableCell align="left">Website</StyledTableCell>
            <StyledTableCell align="left">Address</StyledTableCell>
            <StyledTableCell align="left">Contact info.</StyledTableCell>
            <StyledTableCell align="left">Services offered</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers.map((supplier:Supplier) => (
            <StyledTableRow key={supplier.uuid}>
              {
                supplier.logo ?
                  <>
                  <StyledTableCell sx={{
                    maxWidth: '90px',
                    '& > img': {
                      maxWidth: '100%'
                    }
                  }} component="th" scope="row">
                      <img src={supplier.logo} alt={supplier.name}/>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {supplier.name}
                    </StyledTableCell>
                  </>
                  :
                <StyledTableCell colSpan={2} component="th" scope="row">
                {supplier.name}
                </StyledTableCell>
              }
              <StyledTableCell align="left">
                <Link href={supplier.website} target="_blank" rel="noreferrer">
                  {supplier.website}
                </Link>
              </StyledTableCell>
              <StyledTableCell sx={{
                whiteSpace: 'normal',
                width: '20%'
              }} align="left">{getAddress(supplier.address)}</StyledTableCell>
              <StyledTableCell>
                  <Grid container>
                    <Grid item xs={2}>
                      <MailOutlineIcon fontSize="small" />
                    </Grid>
                    <Grid item xs={8}>
                      <Link href={`mailto:${supplier.email}`}>
                        {supplier.email}
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2}>
                      <PhoneIcon fontSize="small" />
                    </Grid>
                    <Grid item xs={8}>
                      <Link href={`tel:${supplier.phone}`}>
                        {supplier.phone}
                      </Link>
                    </Grid>
                  </Grid>
              </StyledTableCell>
              <StyledTableCell align="left">{supplier.servicesOffered.join(', ')}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
	);
}

const mapStateToProps = (state: any) => {
	return {
		pageTitle: state.context.pageTitle,
    suppliers: state.wizard.suppliers
	};
};

export default connect(mapStateToProps, { setPageTitle, setInnerNav, loadSuppliers })(Suppliers);

