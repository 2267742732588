import {useEffect, useState} from "react";
import {history} from "../../configureStore";
import { makeStyles } from '@material-ui/core';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ListItemText from "@mui/material/ListItemText";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Divider from "@mui/material/Divider";
import * as React from "react";
import {Collapse, Drawer, Link, ListItemButton, Typography} from "@mui/material";
import {connect} from "react-redux";
import {setActiveProjectId, setPageTitle} from "../../actions/context";
import {resetWizard} from "../../actions/wizard";
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import {useTheme} from "@mui/system";
import RecentProjectsList from "./RecentProjectsList";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';

const useStyles = (props: any) => makeStyles( theme => ({
  root: {
    position: "relative",
    transition: "width 300ms cubic-bezier(0.2,0,0,1) 0s",
    backgroundColor: "#343A40",
    width: props.isNavOpen ? "250px" : "20px",
    height: "100vh"
  },
  drawer: {
    flexBasis: "250px",
    visibility: props.isNavOpen ? "visible" : "hidden",
    opacity: props.isNavOpen ? 1 : 0,
    height: "100vh",
    overflow: "hidden auto",
    transition: props.isNavOpen ? "none" : "opacity 0ms linear 200ms, visibility 0ms linear",
  },
  navWrapper: {
    height: "100vh",
    minWidth: "240px"
  },
  btnWrapper: {
    position: "absolute",
    right: "-24px",
    top: "60px",
  },
  btnWrapperInner: {
    display: "flex",
    width: "24px",
    height: "24px",
    background: "white",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px",
    position: "absolute",
    top: "30px",
    right: "12px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.success.main,

      '& svg': {
        color: "white"
      }
    }
  },
  arrowBtn: {
    transform: props.isNavOpen ? "rotate(0) translateX(2px)" :"rotate(180deg) translateX(2px)"
  }
}));

function MainMenuElement(props: any) {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const {activeProject, activeProjectId, pageTitle, resetWizard} = props;
  const [open, setOpen] = React.useState(false);
  const location: any = useLocation();
  const styleProps = {
    isNavOpen: isNavOpen
  };
  const classes = useStyles(styleProps)();

  const handleCollapse = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    // @todo: Init furthest step to 1 here.
    // Will be used to determine if wizard should be reset in project details.
  }

  const theme = useTheme();

  // Update current path so we can use it for active states.
  useEffect(() => {
    const unListen = history.listen((location, action) => {
      setCurrentPath(location.pathname);
    });
    return function cleanUp() {
      unListen();
    }
  }, []);

  useEffect( () => {
    setOpen(false);
    if (location.state && location.state.hasOwnProperty('insight')) {
      setOpen(true);
    }
    const path = location.pathname.split("/").filter((item: any) => item);
    if (path.length === 2 && path[0] === 'project') {
      setOpen(true);
    }
  }, [activeProjectId]);

  return (
    <Box
      className={classes.root}
    >
      <Drawer
        variant={"permanent"}
        className={classes.drawer}
      >
        <Box className={classes.navWrapper}>
          <List className="mainMenu">
            <Link component={RouterLink} to="/project-new" onClick={handleClick} style={{ textDecoration: 'none' }}>
              <MenuItem selected={currentPath === '/project-new'}>
                <ListItemIcon>
                  <AddCircleOutlineSharpIcon />
                </ListItemIcon>
                <ListItemText primary="Add Project" />
              </MenuItem>
            </Link>
            <Link component={RouterLink} to="/" style={{ textDecoration: 'none' }}>
              <MenuItem selected={currentPath === '/'}>
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="My Projects" />
              </MenuItem>
            </Link>
            {
              activeProjectId && activeProject
              ?
                <>
                  <Divider />
                  <Typography variant="h6"
                              style={{padding: '20px', color: theme.palette.secondary.light, fontWeight: 300}}>
                    {pageTitle}
                  </Typography>
                  <MenuItem
                    style={{ padding: "12px 30px" }}
                    selected={activeProject && (currentPath === '/project/' + activeProject.id)}
                    onClick={() => {
                      history.push({pathname: '/project/' + activeProject.id});
                    }}
                  >
                    <ListItemIcon>
                      <SettingsApplicationsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Project Insights" />
                  </MenuItem>
                  <MenuItem
                    style={{ padding: "12px 30px" }}
                    selected={activeProject && (currentPath.includes('/project/' + activeProject.id + '/reports'))}
                    onClick={() => {
                      history.push({pathname: '/project/' + activeProject.id + '/reports'} );
                    }}
                  >
                    <ListItemIcon>
                      <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Project Reports" />
                  </MenuItem>
                  <MenuItem
                    style={{ padding: "12px 30px" }}
                    selected={activeProject && (currentPath.includes('/project/' + activeProject.id + '/documents'))}
                    onClick={() => {
                      history.push({pathname: '/project/' + activeProject.id + '/documents'} );
                    }}
                  >
                    <ListItemIcon>
                      <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Project Documentation" />
                  </MenuItem>
                </>
                :
                ''
            }
          </List>
          <Divider />
          <RecentProjectsList />
        </Box>
      </Drawer>
      <Box className={classes.btnWrapper}>
        <Box
          className={classes.btnWrapperInner}
          sx={{ zIndex: 9999 }}
          onClick={() => setIsNavOpen(value => !value)}
        >
          <ArrowBackIosIcon sx={{ fontSize: 12 }} className={classes.arrowBtn} />
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: any) => {
  let activeProject = null;
  if (state.context.activeProjectId) {
    activeProject = state.context.projects.find((el: any) => el.uuid === state.context.activeProjectId);
  }
  return {
    isSignedIn: state.auth.isSignedIn,
    isLoading: state.context.isLoading,
    activeProjectId: state.context.activeProjectId,
    activeProject: activeProject,
    pageTitle: state.context.pageTitle
  };
};

export default connect(mapStateToProps, { resetWizard, setPageTitle, setActiveProjectId })(MainMenuElement);
