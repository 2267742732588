import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validator from 'validator';
import {
  Grid,
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import TextElementNew from '../form/textfieldnew';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useTheme } from "@mui/material";
import { setShowDocumentModal, setProgressModal } from '../../actions/modal';
import { loadDocumentTypes, saveDocument } from '../../actions/context';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  py: 6,
  px: 12,
  width: 760,
  textAlign: 'center' as 'center'
};

const validate = (values: any) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = 'Name is required.';
  }

  if (!values.link) {
    errors.link = 'File URL is required.';
  }
  else if (!validator.isURL(values.link)) {
    errors.link = 'Please provide a valid URL.';
  }

  if (!values.owner) {
    errors.owner = 'Owner is required.';
  }
  if (!values.fileType) {
    errors.fileType = 'File Type is required.';
  }

  return errors;
};

const SelectComponent = (props: any) => {
  const { touched, error } = props.meta;

  const handleChange = (event:any) => {
    props.input.onChange(event.target.value);
  };

  return (
    <FormControl fullWidth size={props.size} error={touched && (!!error)}>
      <InputLabel variant={"outlined"}>{props.label}</InputLabel>
      <Select
        required={props.required}
        value={props.input.value || []}
        onChange={handleChange}
        label={props.label}
        style={{textAlign: 'left'}}
      >
        {props.components.map((component: any, index: number) => (
          <MenuItem key={component.id} value={component.id}>{component.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{touched && (!!error) && error}</FormHelperText>
    </FormControl>
  );
}


const DocumentModal = (props: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isDocumentModalOpen, progressModalText } = useSelector((state: any) => state.modal);
  const { documentTypes, isLoading, activeProject } = useSelector((state: any) => state.context);

  const handleClose = () => {
    props.initialize({});
    dispatch(setProgressModal(''));
    dispatch(setShowDocumentModal(false));
  }

  const onSubmit = async (formValues: any) => {
    const { name, link, description, owner, fileType } = formValues;
    await dispatch(saveDocument({
      title: [name],
      field_url: [link],
      field_type: [fileType],
      field_description: [description],
      field_owner: [owner],
      field_project: [activeProject.id]
    }));
    props.initialize({});
    await dispatch(setShowDocumentModal(false));
  }

  useEffect(() => {
    dispatch(loadDocumentTypes());
  }, []);

  useEffect(() => {
    // if (documentTypes && documentTypes.length > 0) {
    //   setFileType(documentTypes[0].id as string);
    // }
  }, [documentTypes]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isDocumentModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isDocumentModalOpen}>
        <Box sx={style}>
          <ReportGmailerrorredOutlinedIcon sx={{ color: theme.palette.success.main, fontSize: 80, mb: 2 }} />

          <Typography id="transition-modal-title" variant="h3" component="h3" sx={{ mb: 4}}>
            Add New Document
          </Typography>
          {progressModalText &&
            <Typography id="transition-modal-description" variant="body2" sx={{ mb: 2 }}>
              {progressModalText}
            </Typography>
          }
          <form className="form" noValidate autoComplete="off" onSubmit={props.handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Field
                  name="name"
                  component={TextElementNew}
                  placeholder="Name"
                  label="Name"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="link"
                  component={TextElementNew}
                  placeholder="https://google.com"
                  label="File URL"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="fileType"
                  component={SelectComponent}
                  components={documentTypes}
                  label="File Type"
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="owner"
                  component={TextElementNew}
                  placeholder="John"
                  label="Owner"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  component={TextElementNew}
                  placeholder=""
                  label="Description"
                  multiline={true}
                  minRows={4}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 4}}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  textTransform: 'capitalize',
                  mr: 3,
                  px: 6,
                  py: 1
                }}
                type="submit"
                disabled={isLoading}
              >
                Create
              </Button>
              <Button
                variant="outlined"
                color="info"
                sx={{
                  textTransform: 'capitalize',
                  px: 6,
                  py: 1
                }}
                onClick={handleClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default (reduxForm({
  form: 'DocumentForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true,
})(DocumentModal));;
