import React from "react";
import {Grid} from "@mui/material";
import {Typography} from "@mui/material";
import DynamicField from "./DynamicField";
import { v4 as uuid_v4 } from "uuid";

function DynamicParameter(props:any) {
  const { body } = props.content;

  return (
    <>
      {
        body && body.length > 0
        ?
          <>
            {body.map((parameterGroup: any, index: number) => {
              return (
                <Grid container spacing={1} key={index}>
                  <Grid item xs={12} lg={6}>
                    <Typography variant={"h6"} className={index === 0 ? 'groupNameFirst' : 'groupName'}>
                      {parameterGroup.groupName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                  </Grid>
                  {parameterGroup.params.map((parameter: any, index: number) => {
                    return (
                      <DynamicField
                        parameter={parameter}
                        key={uuid_v4()}
                        gridBase={props.gridBase ?? 3}
                        gridsmBase={props.gridsmBase ?? 12}
                        labelPositionTop={props.labelPositionTop ?? false}
                      />
                    );
                  })}
                </Grid>
              );
            })}
          </>
          :
          <Typography variant={"body2"} style={{color: "red", maxWidth: '30%', padding: 20}}>
            An error occurred. Please try to refresh/reload the application or contact support.
          </Typography>
      }
    </>
    );
}

export default DynamicParameter;
