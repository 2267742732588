import * as React from "react";
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from "@mui/material/MenuItem";
import {useState} from "react";

function SimpleSelectElement(props: any) {

  const { touched, error } = props.meta;
  const [selectedValue, setSelectedValue] = useState(props.defaultValue || '');

  const handleChange = (e:any) => {
    setSelectedValue(e.target.value);
    props.input.onChange(e);
  };

	return (
    <FormControl>
      <InputLabel variant="outlined">{props.label}</InputLabel>
      <Select
        label={props.label}
        required={props.required}
        value={selectedValue}
        {...props.input}
        error={touched && (!!error)}
        onChange={(e:any) => handleChange(e)}
        size={props.size || "small"}
        disabled={props.disabled}
        IconComponent = {KeyboardArrowDownIcon}
      >
        {props.emptyItem && <MenuItem disabled value=''>{props.emptyItem}</MenuItem>}
        {props.options.map((option: any, index: number) => (
          <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
	);
}

export default SimpleSelectElement;
