import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import RadioGroupElement from '../components/form/radiogroup';
import { setActiveModelId } from "../actions/context";

const NewModelPage = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedModel, setSelectedModel] = useState('empty');
  const [projectName, setProjectName] = useState('');
  const [activeGroup, setActiveGroup] = useState('startFromExisting');
  const [isAdd, setIsAdd] = useState(false);
  const [models, setModels] = useState([]);
  const { activeProject } = useSelector((state: any) => state.context);
  const { projectId } = useParams() as any;

  const handleChange = (event: SelectChangeEvent) => {
    setIsAdd(event.target.value != 'empty');
    setSelectedModel(event.target.value as string);
  }

  const toggleActiveGroup = (e:any) => {
    setIsAdd(e.target.value == 'startFromScratch');
    setSelectedModel('empty');
    setActiveGroup(e.target.value);
  };

  const createNewModel = () => {
    dispatch(setActiveModelId(selectedModel));
    history.push('create');
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          padding: 4
        }}
      >
        <Typography variant={"h6"} sx={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
          Add New Model for: <Typography variant={"h6"} sx={{ fontWeight: 'bold', marginLeft: 2 }}>{ activeProject ? activeProject.projectName : '' }</Typography>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <RadioGroupElement
              defaultValue="startFromExisting"
              onChange={(e:any) => toggleActiveGroup(e)}
              radios={[
                {label: 'Duplicate & Modify existing model', value: "startFromExisting" },
                {label: 'Start model from scratch', value: "startFromScratch" }
              ]}
            >
            </RadioGroupElement>
            <Button
              variant="contained"
              disableElevation
              size="large"
              sx={{
                color: 'white',
                textTransform: 'none',
                marginTop: '40px',
                width: 100
              }}
              onClick={createNewModel}
              disabled={!isAdd}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Select
              labelId="simple-select-disabled-label"
              id="simple-select-disabled"
              value={selectedModel}
              label="Select Model"
              size={"small"}
              sx={{
                width: 2/2
              }}
              disabled={activeGroup === "startFromScratch"}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='empty'>Select Model</MenuItem>
              {activeProject && activeProject.models.map((model: any, i: number) => (
                <MenuItem value={model.uuid} key={i}>{ model.name }</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default NewModelPage;