import React, {useEffect, useState} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import TextField from "@mui/material/TextField";

const AddressSearch = (props:any) => {

  const [address,setAddress] = useState('');
  const { dirty, touched, error } = props.meta;

  const formatAddress = (address: any) => {
    return address.length > 49 ? address.substring(0,50) + "..." : address;
  };

  useEffect(() => {
    if (props.input.value === "") {
      setAddress('');
    }
  }, [props.input.value]);

  useEffect(() => {
    if (props.input.value && props.input.value.address && props.input.value.address.address_line1) {
      setAddress(formatAddress(props.input.value.address.address_line1));
    }
  }, [props.input.value]);

  const handleChange = (address: string) => {
    setAddress(address);
    props.input.onChange(address);
  };

  const handleSelect = (address:string) => {
    geocodeByAddress(address)
      .then((results:any) => {
        // Extract the country code.
        const countryCode = results[0].address_components.find((el:any) => el.types.includes('country')).short_name;
        getLatLng(results[0]).then(
          (latLng:any) => {
            const location = {
              latitude: latLng.lat,
              longitude: latLng.lng,
              address: {
                address_line1: address,
                country_code: countryCode
              }
            };
            setAddress(formatAddress(address));
            props.input.onChange(location);
          }
        );
      })
      .catch(error => console.error('Error', error));
  };

    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <TextField
              {...getInputProps({
                placeholder: props.placeholder,
                label: props.label,
                className: 'location-search-input',
              })}
              required={props.required}
              error={(touched || dirty) && (!!error)}
              helperText={(touched || dirty) && error}
              size={"small"}
              disabled={props.disabled}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                    className={className}
                    style={style}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    );
};

export default AddressSearch;
