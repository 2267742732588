import { ActionType } from "../model/model";
import connector from "../api/connector";
import FileUtils from "../utility/file.utils";
import GenericUtils from "../utility/generic.utils";
import { setProgressModal } from "./modal";
import axios from 'axios';

/**
 * Clear new model id
 * @param
 */
export const clearNewModelId = () => {
	return {
		type: ActionType.CLEAR_NEW_MODEL_ID,
	}
};

/**
 * Sets the page title.
 * @param title
 */
export const setPageTitle = (title: string) => {
	return {
		type: ActionType.SET_PAGE_TITLE,
		payload: { title: title }
	}
};

/**
 * Sets the active project uuid.
 * @param id
 */
export const setActiveProjectId = (id: string) => {
	return {
		type: ActionType.SET_ACTIVE_PROJECT_ID,
		payload: { id: id }
	}
};

export const setActiveModelId = (id: string) => {
	return {
		type: ActionType.SET_ACTIVE_MODEL_ID,
		payload: { id: id }
	}
};

/**
 * Sets the project form mode.
 * @param mode
 */
export const setProjectFormMode = (mode: string) => {
  return {
    type: ActionType.SET_PROJECT_FORM_MODE,
    payload: { mode: mode }
  }
};

/**
 * Sets the add new page loading type.
 * @param type
 */
 export const setLoadingType = (type: string) => {
  return {
    type: ActionType.SET_ADD_PROJECT_LOADING_TYPE,
    payload: { type }
  }
};

/**
 * Sets the loading state.
 * @param progress
 */
export const setProgress = (progress: string) => {
  return {
    type: ActionType.SET_PROGRESS,
    payload: { progress: progress }
  }
};

/**
 * Sets the loading state.
 * @param isLoading
 */
export const setLoading = (isLoading: boolean) => {
  return {
    type: ActionType.LOADING,
    payload: isLoading
  }
};

/**
 * Sets whether to show the inner navigation or not.
 * @param status
 */
export const setInnerNav = (status: boolean) => {
	return {
		type: ActionType.SET_INNER_NAV,
		payload: { innerNav: status }
	}
};

/**
 * Clear optimized values
 * @param status
 */
export const clearOptimization = () => {
	return {
		type: ActionType.CLEAR_OPTIMIZATION
	}
};

export const setInitialGridFormValues = (payload: any) => {
  return {
		type: ActionType.SET_INITIAL_GRIDFORM_VALUES,
		payload
	}
}

/**
 * Attempts to save a new project.
 * @param values
 *   The wizard form values passed in.
 */
export const saveNewProject = (values: any) => async (dispatch: Function, getState: Function) => {
	try {
    dispatch(setProgressModal('Please wait...'));
    await GenericUtils.timeout(1000);

    dispatch(setProgress('uploading_files'));
    dispatch(setProgressModal('Uploading files...'));
    values = await FileUtils.handleFileUploads(values,getState().auth.userData.csrf_token);
    await GenericUtils.timeout(1000);

    dispatch(setProgress('generating_models'));
    dispatch(setProgressModal('Generating baseline model...'));

    await GenericUtils.timeout(500);
    dispatch(setProgressModal('Saving project...'));

    const projectResponse = await connector.post("/api/project/create?_format=json",
      values, {headers: {'X-CSRF-Token': getState().auth.userData.csrf_token}}
    );

    dispatch(loadUserProjects());
    await GenericUtils.timeout(1000);
    dispatch(setProgress('project_created'));

    dispatch({ type: ActionType.SAVE_NEW_PROJECT, payload: projectResponse });
	}
	catch (err) {
		dispatch({ type: ActionType.SAVE_NEW_PROJECT, payload: { response: err.message } });
	}
};

export const saveNewModel = (values: any) => async (dispatch: Function, getState: Function) => {
	try {
    dispatch(setProgressModal('Please wait...'));
    await GenericUtils.timeout(1000);

    dispatch(setProgress('uploading_files'));
    dispatch(setProgressModal('Uploading files...'));
    values = await FileUtils.handleFileUploads(values,getState().auth.userData.csrf_token);
    await GenericUtils.timeout(1000);

    dispatch(setProgress('generating_models'));
    dispatch(setProgressModal('Saving model...'));
    const modelResponse = await connector.post("/api/model/create?_format=json",
      values, {headers: {'X-CSRF-Token': getState().auth.userData.csrf_token}}
    );
    dispatch(setProgress('model_created'));

    dispatch({ type: ActionType.SAVE_NEW_MODEL, payload: modelResponse });
	} catch (err) {
		dispatch({ type: ActionType.SAVE_NEW_MODEL, payload: { response: err.message } });
	}
};

/**
 * Loads the user input schema definition for dynamic parameters.
 * @param uid
 */
export const loadUserInputSchema = (uid: number) => async (dispatch: Function, getState: Function) => {
  try {
    const [response, fallbackResponse] = await Promise.all(
      [
        connector.get("/jsonapi/user/user?filter[drupal_internal__uid]=" + uid + "&fields[user--user]=field_input_schema"),
        connector.get("/jsonapi/taxonomy_term/settings?fields[taxonomy_term--settings]=drupal_internal__tid,name,weight,description,field_json_value,changed")
      ]
    );

    let res: any = {};
    if (response && response.data.data && response.data.data.length > 0) {
      const data = response.data.data[0];
      if (data.attributes.field_input_schema) {
        res = response;
      }
    }
    if (!res.data && fallbackResponse && fallbackResponse.data.data && fallbackResponse.data.data.length > 0) {
      const fallbackData = fallbackResponse.data.data[0];
      if (fallbackData.attributes.field_json_value) {
        res = fallbackResponse;
      }
    }
    dispatch({ type: ActionType.LOAD_USER_INPUT_SCHEMA, payload: res});
  }
  catch (err) {
    console.error(err);
    dispatch({ type: ActionType.LOAD_USER_INPUT_SCHEMA, payload: err.response });
  }
};

/**
 * Loads a user's projects.
 */
export const loadUserProjects = () => async (dispatch: Function, getState: Function) => {
	try {
    dispatch(setLoading(true));
		const response = await connector.get("/jsonapi/node/project?include=field_solar_analysis,field_single_line,field_site_plan,field_insights,field_models.field_microgrid,field_primary_contact&filter[uid.drupal_internal__uid]=" + getState().auth.userData.current_user.uid + "&sort=-created");
    dispatch(setLoading(false));
    if (response && response.data) {
      dispatch({ type: ActionType.LOAD_USER_PROJECTS, payload: response });
    }
	}
	catch (err) {
    console.error(err);
		dispatch({ type: ActionType.LOAD_USER_PROJECTS, payload: err.response });
	}
};

export const loadSpecificProject = (projectId: string, hasLast: boolean = true) => async (dispatch: Function, getState: Function) => {
	try {
    dispatch(setLoading(true));
		const response = await connector.get(`/jsonapi/node/project?include=field_solar_analysis,field_single_line,field_site_plan,field_insights,field_models.field_microgrid,field_primary_contact&filter[id]=${projectId}`);
    dispatch(setLoading(false));
    if (response && response.data) {
      dispatch({ type: ActionType.LOAD_SPECIFIC_PROJECT, payload: { response, hasLast } });
    }
	}
	catch (err) {
    console.error(err);
		dispatch({ type: ActionType.LOAD_SPECIFIC_PROJECT, payload: err.response });
	}
};

/**
 * Loads a project's optimizations.
 *   The internal project Id.
 * @param activeProjectId
 */
export const loadProjectOptimizations = (activeProjectId: string) => async (dispatch: Function, getState: Function) => {
  try {
    const response = await connector.get('/jsonapi/optimization/optimization?include=project_id&filter[project_id.id]=' + activeProjectId + '&fields[optimization--optimization]=name,drupal_internal__id,created,changed,internal_status,status_reason,revision_log_message,revision_created,external_id&sort=-changed');
    dispatch({ type: ActionType.LOAD_PROJECT_OPTIMIZATIONS, payload: response.data.data });
  }
  catch (err) {
    dispatch({ type: ActionType.LOAD_PROJECT_OPTIMIZATIONS, payload: err.response });
  }
};

/**
 * Runs a Simulation / Optimization for the currently active model.
 */
 export const runSimulationAndOptimization = (formParams: any) => async (dispatch: Function, getState: Function) => {
   const endpoint = process.env.REACT_APP_SOE_ENDPOINT ?  process.env.REACT_APP_SOE_ENDPOINT : '';
  try {
    const response = await axios.post(endpoint,
      {
        ...formParams,
      });
    dispatch({ type: ActionType.RUN_MODEL_OPTIMIZATION, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ActionType.RUN_MODEL_OPTIMIZATION, payload: err.response });
    return err.response;
  }
};

/**
 * Runs an optimization for the currently active project.
 */
export const runOptimization = (formParams: any) => async (dispatch: Function, getState: Function) => {
  try {
    const response = await connector.post("/api/optimize?_format=json",
      {
        ...formParams,
        activeProjectId: getState().context.activeProjectId,
      }, { headers: { "X-CSRF-Token": getState().auth.userData.csrf_token } });
    dispatch({ type: ActionType.RUN_OPTIMIZATION, payload: response });
    return response;
  } catch (err) {
    dispatch({ type: ActionType.RUN_OPTIMIZATION, payload: err.response });
    return err.response;
  }
};

/**
 * Loads project consumers.
 */
export const loadConsumers = () => async (dispatch: Function, getState: Function) => {
  try {
    let promisesArray: Array<any>;
    promisesArray = [];
    const activeProjectId = getState().context.activeProjectId;
    const activeProject = getState().context.projects.find((el:any) => el.uuid === activeProjectId);
    if (activeProjectId && activeProject) {
      const components = activeProject.gridNetwork.components.filter((el:any) => el.type === 'component--consumer');
      if (components) {
        components.map((component: any, index: any) => {
          promisesArray.push(loadConsumer(component.id));
          return component;
        });
      }
    }
    const res = await Promise.all(promisesArray);
    dispatch({ type: ActionType.LOAD_SAVED_CONSUMERS , payload: {data: res} });
  }
  catch (err) {
    dispatch({ type: ActionType.LOAD_SAVED_CONSUMERS, payload: err.response });
  }
};

/**
 * Loads project consumers.
 */
const loadConsumer = (uuid: string) => {
  return connector.get(
    `/jsonapi/component/consumer?include=load_profile&filter[is_base]=0&filter[id]=${uuid}&fields[file--file]=filename,uri,filesize`
  );
}


const loadProducer = (uuid: string) => {
  return connector.get(
    `/jsonapi/component/producer?filter[is_base]=0&filter[id]=${uuid}`
  );
}

const loadStorage = (uuid: string) => {
  return connector.get(
    `/jsonapi/component/storage?filter[is_base]=0&filter[id]=${uuid}`
  );
}

const getConfig = (type: string) => {
  switch (type) {
    case 'consumer':
      return {
        type: 'component--consumer',
        dispatch: ActionType.LOAD_SAVED_CONSUMERS,
        callback: loadConsumer
      }
    case 'producer':
      return {
        type: 'component--producer',
        dispatch: ActionType.LOAD_SAVED_PRODUCERS,
        callback: loadProducer
      }
    case 'storage':
      return {
        type: 'component--storage',
        dispatch: ActionType.LOAD_SAVED_STORAGES,
        callback: loadStorage
      }
  }
};

/**
 * Loads project components of type "type".
 */
export const loadComponentsByType = (type: string) => async (dispatch: Function, getState: Function) => {

  const config:any = getConfig(type);
  try {
    let promisesArray: Array<any>;
    promisesArray = [];
    const activeProjectId = getState().context.activeProjectId;
    const activeProject = getState().context.activeProject;

    if (activeProjectId && activeProject && activeProject.gridNetwork && activeProject.gridNetwork.components) {
      const components = activeProject.gridNetwork.components.filter((el:any) => el.type === config.type);
      if (components) {
        components.map((component: any, index: any) => {
          promisesArray.push(config.callback(component.id));
          return component;
        });
      }
    }
    const res = await Promise.all(promisesArray);
    dispatch({ type: config.dispatch , payload: {data: res} });
    return res;
  }
  catch (err) {
    dispatch({ type: config.dispatch , payload: err.response });
  }
};




/**
 * Attempts to update an existing project.
 * @param activeProjectId
 * @param values
 *   The wizard form values passed in.
 */
export const updateProject = (activeProjectId: string, values: any) => async (dispatch: Function, getState: Function) => {
  try {
    const updateResponse = await connector.patch("/api/project/" + activeProjectId + "?_format=json",
      values, {headers: {'X-CSRF-Token': getState().auth.userData.csrf_token}}
    );
    const projectResponse = await connector.get("/jsonapi/node/project/" + activeProjectId + "?include=field_grid_network.links&sort=-created");
    updateResponse.data.project = projectResponse.data.data;
    updateResponse.data.isGridTied = values.isGridTied;
    dispatch({ type: ActionType.UPDATE_PROJECT, payload: updateResponse });
  }
  catch (err) {
    dispatch({ type: ActionType.UPDATE_PROJECT, payload: { response: err.message } });
  }
};

/**
 * Attempts to delete an existing model.
 * @param drupalId
 * user Drupal internal id
 */
export const deleteModel = (drupalId: string) => async (dispatch: Function, getState: Function) => {
  try {
    dispatch(setProgressModal('Please wait...'));
    await GenericUtils.timeout(1000);

    dispatch(setProgressModal('Deleting Model...'));
    await GenericUtils.timeout(1000);

    const deleteResponse = await connector.delete(
      "/admin/structure/model/" + drupalId,
      {
        headers: {'X-CSRF-Token': getState().auth.userData.csrf_token}
      }
    );
    dispatch({ type: ActionType.DELETE_MODEL, payload: drupalId });
  }
  catch (err) {
    dispatch({ type: ActionType.UPDATE_PROJECT, payload: { response: err.message } });
  }
};

export const loadDocumentTypes = () => async (dispatch: Function, getState: Function) => {
  try {
    const res = await connector.get("/jsonapi/taxonomy_term/document_types");
    dispatch({ type: ActionType.LOAD_DOCUMENT_TYPES, payload: res});
  }
  catch (err) {
    console.error(err);
    dispatch({ type: ActionType.LOAD_DOCUMENT_TYPES, payload: err.response });
  }
};

export const loadDocuments = (offset: number, limit: number, model: any = null, searchKey: string = '') => async (dispatch: Function, getState: Function) => {
  try {
    dispatch(setLoading(true));
    let res;
    let query = `jsonapi/node/document?page[offset]=${offset}&page[limit]=${limit}
    &filter[field_project.id][value]=${getState().context.activeProjectId}`;

    if (model) {
      const sortField = ({
        'name': 'title',
        'fileType': 'field_type.name',
        'description': 'field_description',
        'createdDate': 'created',
        'owner': 'field_owner'
      } as any)[model.field];
      const sort = model.sort === 'asc' ? `sort=${sortField}` : `sort=-${sortField}`;
      query = query + `&${sort}`;
    }

    if (searchKey) {
      query = query + `&filter[title][operator]=CONTAINS&filter[title][value]=${searchKey}`;
    }

    res = await connector.get(query);
    dispatch({ type: ActionType.LOAD_DOCUMENTS, payload: res});
    dispatch(setLoading(false));
  } catch (err) {
    console.error(err);
    dispatch({ type: ActionType.LOAD_DOCUMENTS, payload: err.response });
  }
}

export const saveDocument = (payload: any) => async (dispatch: Function, getState: Function) => {
  try {
    dispatch(setLoading(true));
    dispatch(setProgressModal('Please wait...'));
    await GenericUtils.timeout(500);

    dispatch(setProgressModal('Saving Document...'));
    await GenericUtils.timeout(500);

    const res: any = await connector.post(
      `node?_format=json`,
      {
        ...payload,
        "type": "document"
      },
      {
        headers: {
          'X-CSRF-Token': getState().auth.userData.csrf_token
        }
      }
    );
    dispatch({ type: ActionType.SAVE_DOCUMENT, payload: { ...payload, created: res.data.created[0].value, id: res.data.uuid[0].value } });
    dispatch(setLoading(false));
  } catch (err) {
    console.error(err);
    // dispatch({ type: ActionType.SAVE_DOCUMENT, payload: err.response });
  }
}
