import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material';
import {useEffect} from "react";

export default function InputSlider(props: any) {
  const theme = useTheme();
  const [value, setValue] = React.useState<number | number[]>([props.minDefaultValue, props.maxDefaultValue]);

  useEffect(() => {
    props.input.onChange(value);
  }, []);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    props.input.onChange(newValue);
    setValue(newValue);
  };

  return (
    <Box>
      <Typography id="input-slider" gutterBottom>
        {props.label}
      </Typography>
      <Grid container alignItems="center">
        <Grid sx={{ fontSize: 12, mt: -1, mr: '10px' }} item>
          {props.minBound}
        </Grid>
        <Grid item xs>
          <Slider
            value={value}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            step={10}
            sx={{
              color: theme.palette.info.main,
              height: 2,

              '& .MuiSlider-thumb': {
                height: 12,
                width: 12
              },

              '& .MuiSlider-track': {
                border: 'none'
              }
            }}
            min={props.minBound}
            max={props.maxBound}
          />
        </Grid>
        <Grid sx={{ fontSize: 12, mt: -1, ml: '10px' }} item>
          {props.maxBound}
        </Grid>
      </Grid>
    </Box>
  );
}
