import Button from "@mui/material/Button";
import * as React from "react";
import {isInvalid, isPristine, isSubmitting, isValid} from "redux-form";
import {connect} from "react-redux";
import {Grid} from "@mui/material";

function WizardControls(props: any) {

  const {isNextEnabled, activeStep, handleNext, handleBack, formMode} = props;

  return (
    <>
      {formMode !== 'edit' && activeStep < 5 ? (
        <Grid container sx={{ml: 2}}>
          <Grid xs={4} sm={4} md={2} lg={2} xl={1} item>
            <Button
              disabled={
                activeStep === 0
              }
              onClick={handleBack}
              variant="outlined"
              color="primary"
              size="medium"
              className="button"
              sx={{
                width: 1,
                mb: 2, mt: 4,
                padding: '6px 20px',
                textTransform: 'capitalize',
                fontWeight: 600,
                border: '2px solid #343A40',
                '&:hover': {
                  border: '2px solid #343A40'
                }
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid xs={4} sm={4} md={2} lg={2} xl={1} item>
            <Button
              variant="contained"
              color="success"
              sx={{ padding: "6px 20px", textTransform: 'capitalize', width: 1, mb: 2, mt: 4, ml: 2 }}
              onClick={handleNext}
              disabled={!isNextEnabled}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )
      : ''
      }
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isNextEnabled: isValid('GridWizardForm')(state)
       && !isSubmitting('GridWizardForm')(state),
    formMode: state.context.formMode,
  };
};

export default connect(mapStateToProps, {isPristine, isInvalid, isValid, isSubmitting})(WizardControls);

