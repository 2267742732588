import * as React from "react";
import {reduxForm} from "redux-form";
import {Grid} from "@mui/material";
import {connect, useDispatch} from "react-redux";
import DynamicParameter from "../form/DynamicParameter";
import ActionControls from "./ActionControls";
import {useSnackbar, VariantType} from "notistack";
import axios from "axios";
import {getUnixTime} from "date-fns";
import * as Constant from "../../model/constant";
import {saveNewModel, saveNewProject, setLoading, setProgress} from "../../actions/context";
import { setModalStatus, setModalProps, setProgressModal, setShowExtraButton } from "../../actions/modal";
import {useEffect, useState} from "react";
import GenericUtils from "../../utility/generic.utils";
import {history} from "../../configureStore";
import {resetWizard} from "../../actions/wizard";

const FinancialComponent = (props:any) => {

  const { enqueueSnackbar } = useSnackbar();
  const {inputSchema, progress, activeProjectId, activeModelId, setProgress} = props;
  const [showModal,setShowModal] = useState(false);
  const [showButton,setShowButton] = useState(false);
  const [projectName, setProjectName] = useState('');
  const dispatch = useDispatch();

  const handleClick = async (projects: any) => {
    // Reset progress once user leaves wizard flow.
    setProgress('');
    history.push({pathname: '/project/' + projects[0].id, state: {projectId: projects[0].id}});
    await GenericUtils.timeout(800);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  /**
   * Submit handler for new projects.
   * Also makes a call to google's api to retrieve the timezone for the address.
   * @param formValues
   */

  const handleModalToggle = (formValues: any) => {
    dispatch(setModalStatus(true))
    dispatch(setModalProps({
      title: 'Generate Baseline Model?',
			body: 'Your new project will be added',
			yesBtnText: 'Yes, Save it',
			noBtnText: 'No, Not Yet',
      handleSubmit: () => onSubmit(formValues),
    }));
  }

  const onSubmit = async (formValues: any) => {
    let variant:VariantType = 'success';

    // Notify user if location is missing.
    if (!formValues.location) {
      variant = 'error';
      enqueueSnackbar('An address for the project must be provided.', { variant });
    }
    else {
      // Get project timezone and add it to payload.
      try {
        const timezone:any = await axios.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + formValues.location.latitude + "," + formValues.location.longitude + "&timestamp=" + getUnixTime(new Date()) +  "&key=" + Constant.GOOGLE_MAP_API_KEY);
        formValues.timezone = timezone.data.timeZoneId ? timezone.data.timeZoneId : '';

        if (formValues.timezone) {
          await props.setLoading(true);
          try {
            setProjectName(formValues.projectName);
            await props.saveNewProject(formValues)
          } catch (error) {
            variant = 'error';
            enqueueSnackbar('An error occurred.', { variant });
          }
          await props.setLoading(false);
        }
      }
      catch(error) {
        variant = 'error';
        enqueueSnackbar('Address is missing or invalid. Please choose one on the "Project Details" step.', { variant });
        return;
      }
    }
  };

  useEffect(() => {
    if (progress && progress === 'project_created' && projectName) {
      let variant:VariantType = 'success';

			dispatch(setProgressModal('Project saved'));
			dispatch(setShowExtraButton({
				isShowExtraButton: true,
				buttonText: 'Go to project',
				handleButtonClick: (projects: any) => handleClick(projects)
			}));
			enqueueSnackbar('Project ' + projectName + ' created.', { variant });
    }
  }, [progress, projectName])

	return (
	  <>
			<form onSubmit={props.handleSubmit(handleModalToggle)} noValidate autoComplete="off"
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          textAlign: "left",
        }}
      >
        <Grid container spacing={1}>
          <DynamicParameter gridBase={3} gridsmBase={6} content={inputSchema} labelPositionTop={true}/>
        </Grid>
        {
          !activeProjectId || showModal ? (
            <ActionControls progress={showModal && !showButton}/>
          ) : ''
        }
			</form>
    </>
	);
}

const mapStateToProps = (state: any) => {
  let initialValues: any = {};
  let initValues: any = [];

  if (state.context.inputSchema) {
    initValues = state.context.inputSchema.body.map((group:any) => {
      const temp = group.params.filter((param:any) => {
        return param.props.hasOwnProperty('defaultValue');
      }).map((param:any) => {
        return {
          [param.props.name]: param.props.defaultValue
        }
      });
      return Object.assign({}, ...temp);
    });
  }
  initialValues = Object.assign({}, ...initValues);

  return {
    isLoading: state.context.isLoading,
    inputSchema: !state.context.inputSchema ? {body: []} : state.context.inputSchema,
    activeProjectId: state.context.activeProjectId,
    activeModelId: state.context.activeModelId,
    progress: state.context.progress,
    initialValues: {...initialValues, ...state.form.GridWizardForm?.values}
  };
};

export default connect(mapStateToProps, { saveNewProject, saveNewModel, setLoading, setProgress, resetWizard })
(reduxForm({
  form: 'GridWizardForm',
  //validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FinancialComponent));
