import * as React from "react";
import Button from "@mui/material/Button";
import { DropzoneDialog } from "material-ui-dropzone/dist";
import {Chip} from "@mui/material";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";

function FileDropZone(props: any) {

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const { touched, error } = props.meta;

  useEffect( () => {
    if (props.initialFiles) {
      props.input.onChange(props.initialFiles);
      handleSave(props.initialFiles);
    }
  },[props.initialFiles]);

	const openDialog = () => {
		props.setDropZoneOpen(true);
	};

	const handleChange = (files:File[]) => {
    const names: any = [];
	  if (files.length > 0) {
      files.forEach(async function(file: File) {
        names.push({name: file.name});
      })
      props.input.value = files;
      setFileNames(names);
    }
	};

	const handleSave = (files: any) => {
		props.input.onChange(files);
    handleChange(files);
		setFiles(files);
		props.setDropZoneOpen(false);
	};

	const closeDialog = () => {
		props.setDropZoneOpen(false);
	};

  const handleDelete = (index:number, e: any) => {
    const newNames = fileNames.filter((x, i) => i !== index);
    const newFiles = files.filter((x, i) => i !== index);
    props.input.value = newFiles;
    props.input.onChange(newFiles);
    setFiles(newFiles);
    setFileNames(newNames);
  };

	return (
		<div>
      <>
        <Button disabled={props.disabled} variant="contained" onClick={openDialog} sx={{ marginBottom: '5px', ...props.sx }}>
          {props.buttonLabel}
        </Button>
        {
          touched && error
            ?
            <Typography className="errors" sx={{
              color: '#700',
              fontSize: 14,
              paddingTop: 5
            }}>
              {error}
            </Typography>
            :
            ''
        }
        <DropzoneDialog
          open={props.dropOpen}
          onClose={closeDialog}
          onSave={handleSave}
          dialogTitle={props.dialogTitle}
          dropzoneText={props.dropzoneText}
          acceptedFiles={props.acceptedFiles}
          showPreviews={true}
          maxFileSize={props.maxFileSize}
          useChipsForPreview={true}
          filesLimit={props.filesLimit}
          initialFiles={props.initialFiles}
        />
      </>
      {
        files && files.length > 0
        ?
          files.map((file: any, index: number) => {
            return (
              <Chip
                key={file.name + index}
                style={{margin: '2px 2px'}}
                label={file.name}
                onDelete={(e: any) => handleDelete(index, e)}
              />
            )
          })
          :
          ''
      }
		</div>
	);
}

export default FileDropZone;
