import * as React from 'react';
import TextField from '@mui/material/TextField';

function TextElementNew(props: any) {
	const { touched, error } = props.meta;
	const {disabled} = props || false;
	return (
	  <>
		<TextField
      {...props.input}
			label={props.label}
			type={props.type}
      multiline={props.multiline}
      minRows={props.minRows}
      placeholder={props.placeholder}
			required={props.required}
			error={touched && (!!error)}
      disabled={disabled}
			helperText={touched && error}
      size={props.size || "small"}
		/>
    </>
	);
}

export default TextElementNew;
