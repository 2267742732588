import { ActionType } from "../model/model";
import connector from "../api/connector";

/**
 * Attempts to log in to the application.
 * @param name
 * @param pass
 */
export const trySignIn = (name: string, pass: string) => async (dispatch: Function, getState: Function) => {
	try {
		const response = await connector.post("/user/login?_format=json", { name: name, pass: pass });
		dispatch({ type: ActionType.TRY_SIGN_IN, payload: response });
	}
	catch (err) {
		dispatch({ type: ActionType.TRY_SIGN_IN, payload: err.response });
	}
};

/**
 * Attempts to sign out of the application.
 */
export const trySignOut = () => async (dispatch: Function, getState: Function) => {
	// @todo Error handling
	const response = await connector.post("/user/logout?_format=json&token=" + getState().auth.userData.logout_token,
		{}, { headers: { 'X-CSRF-Token': getState().auth.userData.csrf_token } }
	);
	dispatch({ type: ActionType.TRY_SIGN_OUT, payload: response });
};

/**
 * Checks if the user is logged in.
 */
export const isLoggedIn = () => async (dispatch: Function, getState: Function) => {
	let response:any = await connector.get("/user/login_status?_format=json");

	// If user is logged in, lets make sure we have userData and tokens.
  if (response.data === 1) {
    const logout = await connector.get('/session/logouttoken');
    const csrf = await connector.get('/session/token');
    const me = await connector.get('/jsonapi');
    const user = await connector.get(me.data.meta.links.me.href + "?include=user_picture");
    response.userData = {
      current_user: {
        uid: user.data.data.attributes.drupal_internal__uid,
        name: user.data.data.attributes.display_name,
		    photo: user.data.included ? user.data.included[0].attributes.uri.url : ''
      },
      logout_token: logout.data.logout_token,
      csrf_token: csrf.data
    }
  }
	dispatch({ type: ActionType.LOGIN_STATUS, payload: response });
};

/**
 * Resets all store properties.
 */
export const resetStore = () => {
	return {
		type: ActionType.RESET_STORE
	}
};
