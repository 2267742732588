import {useState} from "react";
import connector from "../api/connector";

export default function useReports(projectId: string): any {
  const [isLoading, setIsLoading] = useState(false);
  const endpoint = "/api/project/" + projectId + "/insights?_format=json";

  async function getReports() {
    try {
      setIsLoading(true);
      const response = await connector.get(endpoint);
      setIsLoading(false);
      return response.data;
    } catch {
      const err = {error: "Soe could not be called."};
      setIsLoading(false);
      return err;
    }
  }

  return [isLoading, getReports];
}
