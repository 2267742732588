import * as React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import {setActiveProjectId, setInnerNav, setPageTitle, loadSpecificProject} from "../actions/context";
import {Avatar, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@mui/material";
import {Project} from "../model/model";
import GMap from "../components/common/gMap.component";
import {format} from "date-fns";
import ComponentUtils from "../utility/components.utils";
import {history} from "../configureStore";
import {Link as RouterLink} from "react-router-dom";
import {Link} from "@mui/material";
import {useTheme} from "@mui/system";

function DashboardPage(props: any) {

  const {loadSpecificProject, setActiveProjectId, setPageTitle, setInnerNav, projects, user, isLoading} = props;
  const theme = useTheme();

  const handleClick = (e: any, project: Project) => {
    loadSpecificProject(project.uuid);
    history.push({pathname: '/project/' + project.id, state: {projectId: project.id}});
  };

  const getInitials = (name: string) => {
    let initials:RegExpMatchArray = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '').toLowerCase());
  };

  // @todo Move this to its own component file.
  const getGridSummary = (project: Project) => {
    const {gridNetwork} = project;
    let gridLink:any = [], producers:any = [], storages:any = [];
    if (false && gridNetwork.hasOwnProperty('components') && gridNetwork.components.length > 0) {
      gridLink = ComponentUtils.getComponentByType(gridNetwork, 'component--grid');
      producers = ComponentUtils.getComponentByType(gridNetwork, 'component--producer');
      storages = ComponentUtils.getComponentByType(gridNetwork, 'component--storage');
    }

    return (
      <div>
        {
          gridLink && gridLink.length > 0 && gridLink[0].attributes.is_grid_tied === true
            ?
            <Typography variant="body2">Type: Connected to the grid</Typography>
            :
            <Typography variant="body2">Type: Island microgrid</Typography>
        }
        {/*{*/}
        {/*  gridNetwork.hasOwnProperty('components') && gridNetwork?.components?.length > 0 ?*/}
        {/*    <Typography variant="subtitle2">Components:</Typography>*/}
        {/*    :*/}
        {/*    <></>*/}
        {/*}*/}

        {
          producers.length > 0
          ?
            <Typography variant="body2">
              {producers.length} {
                producers.length > 1
              ?
                  'producers'
                  :
                  'producer'
              }
            </Typography>
            :
            ''
        }
        {
          storages.length > 0
            ?
            <Typography variant="body2">
              {storages.length} {
              storages.length > 1
                ?
                'storages'
                :
                'storage'
            }
            </Typography>
            :
            ''
        }

      </div>
    );
  }

	// Initialize parameters for this route.
	useEffect(() => {
		setPageTitle('My Projects');
		setInnerNav(false);
    setActiveProjectId('');
	}, [setPageTitle, setInnerNav]);

	return (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{bgcolor: '#efefef'}}>
            {
              projects.length > 0
              ?
                <Grid
                  container
                  justifyContent="center"
                  spacing={3}
                  sx={{p: '50px'}}
                >
                  {projects.map((project:Project, index:number) => (
                    <Grid key={project.uuid} item xs={6} md={5} lg={4} xl={3}>
                      <Card sx={{'&:hover': {cursor: 'pointer'}}} onClick={(e) => handleClick(e, project)}>
                        <CardHeader
                          disableTypography={true}
                          avatar={
                            <Avatar aria-label="recipe" sx={{bgcolor: theme.palette.info.main}}>
                              {getInitials(user.current_user.name)}
                            </Avatar>
                          }
                          title={(
                            <Typography variant="subtitle2">
                              {project.projectName}
                            </Typography>
                          )
                          }
                          subheader={(
                            <>
                              <Typography variant="body2">
                                Managed by: {project.managedBy}
                              </Typography>
                              <Typography variant="caption">
                                Created: {format(new Date(project.created), 'MMM. dd, yyyy hh:mm')}
                              </Typography>
                            </>
                          )
                          }
                        />
                        <GMap
                          lat={project.location.lat}
                          lon={project.location.lon}
                          height={170}
                        />
                        <CardContent>
                          Site Location:
                          <Typography variant="body2" color="textSecondary" component="p">
                            {project.address && project.address.address_line1}
                          </Typography>
                          <Divider sx={{marginTop: '10px', marginBottom: '10px'}}/>
                          <>{getGridSummary(project)}</>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              :
                !isLoading ? (
                  <Grid container justifyContent="center" spacing={3} sx={{paddingTop: 20}}>
                  <Typography>
                  You haven't created any projects yet.
                  <br/>
                  <Link component={RouterLink} to={'/project-new'}>
                  Create your first project
                  </Link>
                  </Typography>
                  </Grid>
              )
              : ''
            }
          </Grid>
        </Grid>
	);
}

const mapStateToProps = (state: any) => {
	return {
		pageTitle: state.context.pageTitle,
    projects: state.context.projects,
    user: state.auth.userData,
    categories: state.wizard.categories,
    isLoading: state.context.isLoading
	};
};

export default connect(mapStateToProps, { setPageTitle, setInnerNav, setActiveProjectId, loadSpecificProject })(DashboardPage);

