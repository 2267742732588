export default class GenericUtils {
  /**
   * Helpful timeout function for built-in app. delays.
    * @param delay
   */
  static timeout = (delay: number) => {
    return new Promise( res => setTimeout(res, delay) );
  }

  /**
   * Extracts the project ID for a given path, if it is present.
   * @param pathname
   */
  static getProjectIdFromUrl = (pathname: string) => {

    const path: Array<string> = pathname.split('/');
    if (path.length > 2 && path[1] === 'project' && Number.isInteger(parseInt(path[2]))) {
      return path[2];
    }
    return false;
  }

  static getProjectUuid = (id: number, projects: Array<any>) => {
    const project = projects.find((el: any) => el.id === id);
    return project ? project.uuid : false;
  }
}
