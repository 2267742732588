import {Storage, Producer} from "../model/model";

export default class ComponentUtils {
  /**
   * Fetches the grid link component for a given project.
   */
  static getComponentByType = (gridNetwork: any, type: string) => {
    const components = gridNetwork.components;
    return components.filter((el: any) => el.type === type);
  };

  /**
   * Fetches the grid link component for a given project.
   */
  static getComponentByUuid = (components: any, uuid: string) => {
    return components.filter((el: any) => el.id === uuid);
  };

  /**
   * Finds a given component's category from the list of categories.
   */
  static getComponentCategory = (component:any, categories:any) => {
    if (component && component.relationships.category && component.relationships.category.data && component.relationships.category.id) {
      return categories.find( (el:any) => el.uuid === component.relationships.category.data.id);
    }
    return [];
  };

  /**
   * Fetches related data by searching by UUID in a jsonapi included payload.
   * @param data
   * @param uuid
   */
  static getRelatedDataByUuid(data: any, uuid: string) {
    let filtered:any = {};
    if (data && data.included && uuid) {
      filtered = data.included.filter((related: any) => {
        return related.id === uuid;
      });
    }
    return filtered;
  }

  // getStorages
  static getStorages(storages: any) {
    let result: Storage[] = [];
    if (storages && storages.length > 0)
    storages.map((storage: any, index: any) => {
      const component = storage.data.data[0];
      const custom = JSON.parse(component.attributes.custom_properties);
      const item: Storage = {
        id: component.attributes.drupal_internal__id,
        uuid: component.relationships.created_from.data.id,
        name: component.attributes.name,
        created: component.attributes.created,
        changed: component.attributes.changed,
        isExisting: component.attributes.is_existing,
        manufacturer: component.attributes.manufacturer,
        model: component.attributes.model,
        sku: component.attributes.sku,
        count: component.attributes.count,
        category: '',
        capacity: component.attributes.capacity,
        dischargeRate: component.attributes.discharge_rate,
      };
      if (custom && custom.hasOwnProperty('ratedOutput')) {
        item.ratedOutput = custom.ratedOutput;
      }

      result.push(item);
    });
    return result;
  }

  // getProducers
  static getProducers(producers: any) {
    let result: Producer[] = [];
    if (producers && producers.length > 0) {
      producers.map((producer: any, index: any) => {
        const component = producer.data.data[0];
        const item: Producer = {
          id: component.attributes.drupal_internal__id,
          uuid: component.relationships.created_from.data.id,
          name: component.attributes.name,
          created: component.attributes.created,
          changed: component.attributes.changed,
          isBackup: component.attributes.is_backup,
          isExisting: component.attributes.is_existing,
          manufacturer: component.attributes.manufacturer,
          model: component.attributes.model,
          sku: component.attributes.sku,
          count: component.attributes.count,
          category: '', //component.attributes.category,
          sizing: component.attributes.sizing,
        };

        result.push(item);
      });
    }
    return result;
  }

}
