import * as React from "react";
import { connect } from "react-redux";
import { trySignOut, isLoggedIn, resetStore } from "../../actions/auth";
import Button from "@mui/material/Button";
import {useEffect} from 'react';
import { history } from "../../configureStore";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {Box, useTheme} from "@mui/system";
import {Modal} from "@mui/material";
import GenericUtils from "../../utility/generic.utils";

function Auth(props: any) {

  const {isSignedIn, isLoggedIn} = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClick = async () => {
    setOpen(true);
    await GenericUtils.timeout(700);
    props.trySignOut().then(() => {
        props.resetStore();
        setOpen(false);
      }
    );
  }

  const renderAuthButton = (props: any) => {
    if (props.isSignedIn) {
      return <Button size="small" onClick={handleClick}>Sign Out</Button>
    }
    else {
      return <Button size="small" onClick={redirectToLogin}>Sign In</Button>
    }
  };

  useEffect(() => {
		isLoggedIn();
	}, [isSignedIn, isLoggedIn]);

  const getInitials = (name: string) => {
    let initials:RegExpMatchArray = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '').toLowerCase());
  };

	return (
		<>
			{
				props.userData.current_user ?
          <Box
            sx={{
              position: "absolute",
              right: "115px",
              display: 'flex',

            }}
          >
            {props.userData.current_user.photo ? (
              <Avatar
                alt={props.userData.current_user.name}
                src={`${process.env.REACT_APP_BASE_URL}/${props.userData.current_user.photo}`}
                sx={{bgcolor: theme.palette.info.main}}
              />
            ) : (
              <Avatar
                aria-label="recipe"
                sx={{bgcolor: theme.palette.info.main}}
              >
                {getInitials(props.userData.current_user.name)}
              </Avatar>
            )}
						<Typography
              variant="h4"
              component="h4"
              gutterBottom
              style={{
                fontSize: '16px',
                marginBottom: '0.1em',
                marginLeft: '0.5rem',
                color: '#000',
                lineHeight: '37px'
              }}
            >
							{props.userData.current_user.name}
						</Typography>
					</Box>
				:
				''
			}

			<Box sx={{ position: "absolute", right: "20px"}}>
				{renderAuthButton(props)}
			</Box>

      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#000',
          color: 'white',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,}}>
          Logging out...
        </Box>
      </Modal>
		</>
	);

}

function redirectToLogin() {
	history.push("/login");
}

const mapStateToProps = (state: any) => {
	return { isSignedIn: state.auth.isSignedIn, userData: state.auth.userData };
};

export default connect(mapStateToProps, { trySignOut, isLoggedIn, resetStore })(Auth);
