import {Box, Typography} from "@mui/material";
import Utils from "../../utility/optimization.utils";
import React from "react";

const ModelParametersGroup = (props: any) => {
  const {model, parameters, group} = props;
  const computedMeta:any = model.soeConfig ? Utils.getSoeOutputMeta(model.soeConfig) : '';

  const render = () => {
    return computedMeta &&
    (<>
      {Object.keys(parameters[group]).map((key: any, i: number) => {
        return computedMeta[key] ?
          <Box sx={{display: 'flex', alignItems: 'center', marginBottom: "4px"}} key={key}>
            {computedMeta[key]['label'] && <Typography sx={{marginRight: 1}}>{computedMeta[key]['label']}:</Typography>}
            {computedMeta[key]['units'] &&
              <Typography>{Utils.formatParameter(parameters[group][key], computedMeta[key]['units'])} {computedMeta[key]['units']}</Typography>}
          </Box>
          :
          <Box key={key}>Metadata missing for key: {key}</Box>
      })
      }
    </>);
  }

  return (
    render()
  );
}

export default ModelParametersGroup;
