import * as React from "react";
import {connect} from "react-redux";
import {setActiveProjectId} from "../actions/context";
import GenericUtils from "../utility/generic.utils";
import InsightComponent from "../components/visualization/insight.component";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

function ReportPage(props: any) {

  const {projects, setActiveProjectId} = props;
  const {projectId} = props.match.params;
  const location:any = useLocation();
  const {insight} = location.state;

  useEffect(() => {
    if (Number.isInteger(parseInt(projectId))) {
      const uuid = GenericUtils.getProjectUuid(parseInt(projectId), projects);
      if (uuid) {
        setActiveProjectId(uuid);
      }
    }
  });

  return (
    <InsightComponent insight={insight} projectId={projectId}/>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activeProjectId: state.context.activeProjectId,
    projects: state.context.projects,
  };
};

export default connect(mapStateToProps, { setActiveProjectId })(ReportPage);
