import * as React from "react";
import { connect, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Box} from "@mui/system";
import Link, { LinkProps } from '@mui/material/Link';
import {Breadcrumbs} from "@mui/material";
import {useEffect, useState} from "react";
import {history} from "../configureStore";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

function RouterBreadcrumbs(props: any) {

  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [pathnames, setPathnames] = useState(currentPath.split('/').filter((x) => x));
  const location:any = useLocation();
  const { activeModelId, formMode, activeProjectId, activeProject } = useSelector((state: any) => state.context);

  const getBreadcrumbsMap = () => {
    const insight: any = location.state && location.state.hasOwnProperty('insight') ? location.state.insight : '';

    let breadcrumbNameMap: { [key: string]: string } = {
      '/': 'My Projects',
      '/project-new': 'Add Project',
      '/reports': 'Reports',
      '/suppliers': 'Supplier Directory'
    }
    if (activeProject && activeProjectId) {
      const projectKey = activeProjectId ? '/project/' + activeProject.id : '';
      const insightsProjectKey = activeProjectId ? '/project/' + activeProject.id + '/reports' : '';
      const documentsProjectKey = activeProjectId ? '/project/' + activeProject.id + '/documents' : '';
      const insightKey = insight ? '/project/' + activeProject.id + '/reports/' + insight.id : '';
      const OptimizationKey = activeProjectId ? '/project/' + activeProject.id + '/soe' : '';
      const newModel = activeProjectId ? '/project/' + activeProject.id + '/models/new-model' : '';
      const createModel = activeProjectId ? '/project/' + activeProject.id + '/models/create' : '';
      const models = activeProjectId ? '/project/' + activeProject.id + '/models' : '';

      if (projectKey) {
        breadcrumbNameMap[projectKey] = activeProject.projectName;
      }
      if(insightsProjectKey) {
        breadcrumbNameMap[insightsProjectKey] = 'Project Reports';
      }
      if(documentsProjectKey) {
        breadcrumbNameMap[documentsProjectKey] = 'Project Documents';
      }
      if (insightKey) {
        breadcrumbNameMap[insightKey] = insight.name;
      }
      if (OptimizationKey) {
        breadcrumbNameMap[OptimizationKey] = 'Simulation & Optimization';
      }
      if (models) {
        breadcrumbNameMap[models] = 'Models';
      }
      if (newModel) {
        breadcrumbNameMap[newModel] = 'New Model';
      }
      if (createModel) {
        const activeModel: any = activeProject.models.find((model: any) => model.uuid === activeModelId);
        const name = (activeModel && activeModel.name) ? activeModel.name : '';
        breadcrumbNameMap[createModel] = formMode === 'edit' ? name : activeModelId === 'empty' ? 'Create Model' : 'Duplicate Model';
      }
    }
    return breadcrumbNameMap;
  }
  const [breadcrumbs, setBreadcrumbs] = useState(getBreadcrumbsMap());

  useEffect(() => {
    const unListen = history.listen((location, action) => {
      setCurrentPath(location.pathname);
    });
    return function cleanUp() {
      unListen();
    }
  }, []);

  // Update current path for menu active state.
  useEffect(() => {
    setPathnames(currentPath.split('/').filter((x) => x));
  }, [currentPath]);

  useEffect(() => {
    setBreadcrumbs(getBreadcrumbsMap());
  }, [activeProjectId, activeProject, activeModelId, location]);

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
          <LinkRouter underline="hover" color="inherit" to="/">
            My Projects
          </LinkRouter>
          {
            pathnames.map((value, index) => {

            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            // Skip this URL fragment, as project/:id is the pattern.
            if (to === '/project') { return ''}

            return (last ? (
              <Typography color="text.primary" key={to}>
              {breadcrumbs[to]}
              </Typography>
            ) : (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                {breadcrumbs[to]}
              </LinkRouter>)
            );
          })}
        </Breadcrumbs>
      </Box>
	);
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(RouterBreadcrumbs);
