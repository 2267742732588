import * as React from "react";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";

function RadioGroupElement(props: any) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup aria-label="gender" name="radio-buttons-group" defaultValue={props.defaultValue} row={props.row}>
        {
          props.radios.map((radio: any, index: number) => {
              return (
                <FormControlLabel value={radio.value} key={index} control={
                  <Radio
                    onChange={props.onChange}
                    disabled={radio.disabled || false}
                  />
                } label={radio.label} onChange={props.onChange} />
              );
            }
          )
        }
      </RadioGroup>
    </FormControl>
  );
}

export default RadioGroupElement;
