import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from "@mui/system";

const AlwaysScrollToBottom = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current){
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  return <div ref={ref} />;
};

const CustomList = styled(List)<{ component?: React.ElementType }>(({theme}) => ({
  maxHeight: 'calc(100vh - 130px)',
  overflow: 'auto',
  paddingTop: 0,

  '& .MuiListItemButton-root': {
    borderLeft: "10px solid",
    borderLeftColor: "transparent",
    padding: "14px 32px",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary.light
  },

  '& .Mui-selected': {
    borderLeft: "10px solid",
    borderLeftColor: theme.palette.info.main,
    color: 'black',

    '& .MuiTypography-root': {
      fontWeight: 600
    }
  },
}));

const ModelListSidebar = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setIsFirstLoading(false);
    setSelectedIndex(index);
    props.changeModel(index);
  };

  useEffect(() => {
    if (props.models && props.activeModelId) {
      const index = props.models.findIndex((model: any) => model.uuid === props.activeModelId);
      setSelectedIndex(index);
      setIsScroll(true);
    }
  }, [props.activeModelId, props.models]);

  useEffect(() => {
    setIsFirstLoading(true);
  }, [props.models])

  return (
    <Box
      ref={ref}
      sx={{
        width: 1,
        bgColor: theme.palette.paper,
        overflow: 'auto'
      }}
    >
      <CustomList component="div" aria-label="secondary mailbox folder">
        {props.models && props.models.map((model: any, i: number) => (
          <ListItemButton
            key={i}
            selected={selectedIndex === i}
            onClick={(event) => handleListItemClick(event, i)}
          >
            <ListItemText primary={model.name} />
          </ListItemButton>
        ))}
        {(isScroll && isFirstLoading) && <AlwaysScrollToBottom />}
      </CustomList>
      <Box sx={{ marginTop: 2 }}>
        <Box sx={{ marginBottom: 1, textAlign: 'center' }}>
          <Button
            variant="text"
            size="medium"
            className="button"
            onClick={() => props.goNewModel()}
            sx={{
              padding: "6px 20px",
              color: theme.palette.info.main,
              textTransform: 'capitalize',
              fontWeight: 600
            }}
          >
            <AddIcon
              sx={{
                marginRight: 1,
                border: "2px solid",
                borderColor: theme.palette.info.main,
                borderRadius: 1
              }}
            />
            New Model
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ModelListSidebar;
