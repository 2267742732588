import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import { useTheme } from "@mui/material";
import { setModalStatus, setProgressModal } from '../../actions/modal';
import { setProgress } from '../../actions/context';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  py: 6,
  px: 12,
  width: 760,
  textAlign: 'center' as 'center'
};

const ProgressModal = (props: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { modalProps, extraModalProps, progressModalText } = useSelector((state: any) => state.modal);
  const { projects, isLoading } = useSelector((state: any) => state.context);

  const handleClose = () => {
    dispatch(setProgressModal(''));
    dispatch(setProgress(''));
    dispatch(setModalStatus(false));
  }

  const handleSubmit = () => {
    modalProps.handleSubmit();
  }

  const handleExtraAction = () => {
    handleClose();
    extraModalProps.handleButtonClick(projects);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          {modalProps.type === 'delete' ? (
            <GppBadOutlinedIcon sx={{ color: theme.palette.error.main, fontSize: 80, mb: 2 }} />
          ) : (
            <ReportGmailerrorredOutlinedIcon sx={{ color: theme.palette.success.main, fontSize: 80, mb: 2 }} />
          )}
          
          <Typography id="transition-modal-title" variant="h3" component="h3">
            {modalProps?.title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2, fontSize: '24px', px: 3 }}>
            {modalProps?.body}
          </Typography>
          <Typography id="transition-modal-description" variant="body2" sx={{ mt: 2 }}>
            {progressModalText}
          </Typography>
          <Box sx={{ mt: 4}}>
          {
            (extraModalProps && extraModalProps.isShowExtraButton) ? (
              <Button
                sx={{
                  textTransform: 'capitalize',
                  px: 6,
                  py: 1
                }}
                variant="contained"
                color="primary"
                onClick={handleExtraAction}
                disabled={isLoading}
              >
                {extraModalProps.buttonText}
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="info"
                  sx={{
                    textTransform: 'capitalize',
                    mr: 3,
                    px: 6,
                    py: 1
                  }}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {modalProps?.yesBtnText}
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  sx={{
                    textTransform: 'capitalize',
                    px: 6,
                    py: 1
                  }}
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  {modalProps?.noBtnText}
                </Button>
              </>
            )
          }
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProgressModal;
