import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Button, ListItem, Typography} from "@mui/material";

export function SortableItem(props: any) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: '#fff',
    boxShadow: '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)',
    outline: 'none',
    borderRadius: 'calc(4px / var(--scale-x, 1))',
    marginBottom: 10
  };

  return (
    <ListItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {
        props.handle ?
          <Button cursor="grab" data-cypress="draggable-handle" {...props} style={{cursor: 'grab', minHeight: 'auto'}}>
            <svg viewBox="0 0 20 20" width="12">
              <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
            </svg>
          </Button>
          :
          ''
      }
      <Typography variant={"body1"}>
        {props.id}
      </Typography>
    </ListItem>
  );
}
