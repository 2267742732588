import React, {useEffect} from "react";
import {Field} from "redux-form";
import TextWithUnitsElement from "./textwithunits";
import {Grid} from "@mui/material";
import SwitchElement from "./switch";

function DynamicField(props: any) {

  const {parameter} = props;

  const Components:any = {
    field: Field,
    textwithunits: TextWithUnitsElement,
    switch: SwitchElement
  };

  const parseDecimal = (value: any) => {
    return !parseFloat(value) || !Number(value) || value.endsWith('.') ? value : parseFloat(value);
  };

  return (
    <>
      {
        parameter.type === 'spacer'
          ?
          <Grid item xs={12} lg={parameter.props.space}>
          </Grid>
          :
          <Grid item xs={12} lg={props.gridBase} sm={props.gridsmBase}>
            <Field component={Components[parameter.type]} {...parameter.props} parse={parseDecimal} labelPositionTop={props.labelPositionTop ?? false} />
          </Grid>
      }
    </>
  );
}

export default DynamicField;
