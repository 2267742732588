export const ALI_WEBSOCKET_URL = "wss://tnki1xc844.execute-api.eu-central-1.amazonaws.com/Prod";
export const GOOGLE_MAP_API_KEY = "AIzaSyDNWU1O-o17HmaZFbloLGXL_5ym-bbJB7s";
export const POLLING_INTERVAL = 15;
export const INTERNAL_STATUSES:any = {
	starting: "Starting",
	running: "Running",
	on_hold: "On hold",
	failed: "Failed",
	completed: "Preparing report",
	report_ready: "Report ready"
};
