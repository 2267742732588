/**
 * Authentication store structure.
 */
export interface Auth {
  isSignedIn: boolean;
  sessionToken: string;
  userData: object;
  errors: object;
}

export interface Project {
  id: number;
  uuid: string;
  created: string;
  changed: string;
  projectName: boolean;
  location: any;
  address: any;
  timezone: string;
  managedBy: object;
  gridNetwork: any;
  hasInsights: boolean;
  description: string;
  primaryContact: object;
  ems: string;
  operationsDate: string;
  parameters: string;
  models: Model[];
  singleLineFiles: FileEntity[];
  sitePlanFiles: FileEntity[];
  solarAnalysisFiles: FileEntity[];
  soe: string;
}

export interface Model {
  id: number;
  uuid: string;
  parameters: object;
  name: string;
  user_id: number;
  soeModelId: string;
  gridNetworkId: string;
  soeConfig: object;
}

export interface Optimization {
  id: number;
  uuid: string;
  name: string;
  created: string;
  changed: string;
  status: string;
  statusReason: string;
  revisionCreated: string;
  revisionLogMessage: string;
  externalId: string;
}

export interface Consumer {
  id: number;
  uuid: string;
  name: string;
  created: string;
  changed: string;
  files: FileEntity[];
}

export interface FileEntity {
  name: string;
  size: number;
  downloadUrl: string;
  uuid: string;
}

export interface Producer {
  id: number;
  uuid: string;
  name: string;
  created: string;
  changed: string;
  isBackup: boolean;
  manufacturer: string;
  model: string;
  sku: string;
  count: number;
  category: string;
  sizing: number; // Peak hourly Kw provided by the user.
  isExisting: boolean;
}

export interface Storage {
  id: number;
  uuid: string;
  name: string;
  created: string;
  changed: string;
  manufacturer: string;
  model: string;
  sku: string;
  count: number;
  category: string;
  capacity: number;
  dischargeRate: number;
  ratedOutput?: number;
  isExisting: boolean;
}

export interface Context {
  pageTitle: string;
  innerNav: boolean;
  activeProjectId: string;
  activeModelId: string;
  formMode: string,
  projects: Project[];
  consumers: Array<object>;
  producers: Array<object>;
  storages: Array<object>;
  optimizations: Array<object>;
  lastOptimizationResult: object;
  isLoading: boolean;
  inputSchema: object;
  progress: string;
  loadingType: string;
  activeProject: any;
  newModelId: string;
  gridInitialValues: any;
  documentTypes: any[];
  documents: Array<object>;
  documentsCount: number;
}

export interface Modal {
  modalProps: any,
  isModalOpen: boolean;
  progressModalText: string;
  extraModalProps: any;
  isDocumentModalOpen: boolean;
}

export interface Category {
  id: number;
  uuid: string;
  name: string;
  description: string,
  weight: number,
  changed: string;
}

export interface Tariff {
  id: number;
  uuid: string;
  name: string;
  created: string;
  changed: string;
}

export interface Supplier {
  id: number;
  uuid: string;
  name: string;
  created: string;
  changed: string;
  address: any;
  email: string;
  phone: string;
  servicesOffered: Array<string>;
  website: string;
  logo: string;
}

export interface Wizard {
  steps: Array<any>;
  activeStep: number;
  consumer: Array<object>;
  producer: Array<object>;
  storage: Array<object>;
  categories: Array<Category>;
  objectives: Array<Category>;
  tariffs: Array<Tariff>;
  suppliers: Array<Supplier>;
}

export interface ModalProps {
  title: string;
  body: string;
  yesBtnText: string;
  noBtnText: string;
  type: string;
}

export enum ActionType {
  TRY_SIGN_IN,
  TRY_SIGN_OUT,
  LOGIN_STATUS,
  SET_PAGE_TITLE,
  SET_INNER_NAV,
  SET_WIZARD_STEPS,
  SET_ACTIVE_STEP,
  LOAD_BASE_COMPONENTS,
  SAVE_NEW_PROJECT,
  SAVE_NEW_MODEL,
  RESET_STORE,
  LOAD_USER_PROJECTS,
  SET_ACTIVE_PROJECT_ID,
  SET_ACTIVE_MODEL_ID,
  RESET_WIZARD,
  LOAD_CATEGORIES,
  SET_PROJECT_FORM_MODE,
  SET_ADD_PROJECT_LOADING_TYPE,
  RUN_OPTIMIZATION,
  LOAD_PROJECT_OPTIMIZATIONS,
  LOAD_TARIFFS,
  LOAD_SAVED_CONSUMERS,
  LOAD_OBJECTIVES,
  UPDATE_PROJECT,
  LOADING,
  LOAD_SUPPLIERS,
  LOAD_USER_INPUT_SCHEMA,
  SET_PROGRESS,
  SET_FURTHEST_STEP,
  LOAD_SAVED_PRODUCERS,
  LOAD_SAVED_STORAGES,
  LOAD_SPECIFIC_PROJECT,
  LOAD_SPECIFIC_PROJECT_WITH_MODEL,
  CLEAR_NEW_MODEL_ID,
  RUN_MODEL_OPTIMIZATION,
  CLEAR_OPTIMIZATION,
  SET_MODAL_PROPS,
  SET_MODAL_STATUS,
  SET_PROGRESS_MODAL_TEXT,
  DELETE_MODEL,
  SET_SHOW_EXTRA_BUTTON,
  SET_INITIAL_GRIDFORM_VALUES,
  SAVE_OPTIMIZATION_RESULT,
  LOAD_DOCUMENT_TYPES,
  LOAD_DOCUMENTS,
  SET_SHOW_DOCUMENT_MODAL,
  SAVE_DOCUMENT
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

/**
 * Helper function for fetching a component's category.
 * @param component
 * @param categories
 */
export const getComponentCategory = (component: any, categories: any) => {
  if (component && component.relationships.category && component.relationships.category.data && component.relationships.category.id) {
    return categories.find((el: any) => el.uuid === component.relationships.category.data.id);
  }
  return [];
};
