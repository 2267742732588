import * as React from "react";
import GridWizard from "../components/microgrid/GridWizard";
import {connect, useDispatch} from "react-redux";
import {setActiveProjectId, setInnerNav, setPageTitle, setProjectFormMode, loadSpecificProject} from "../actions/context";
import GenericUtils from "../utility/generic.utils";
import {useEffect} from "react";
import {history} from "../configureStore";

function GridWizardModelCreatePage(props: any) {

  const {projects, setInnerNav, setPageTitle, activeProject, activeProjectId, setProjectFormMode} = props;
  const {projectId} = props.match.params;
  const dispatch = useDispatch();

  // // Listen to location changes to set the active project Id.
  // useEffect(() => {
  //   const unListen = history.listen((location: any, action) => {
  //     if (location.state && location.state.projectId) {
  //       console.log(location.state.projectId);
  //       setActiveProjectFromUrl(location.state.projectId);
  //     }
  //   });
  //   return function cleanUp() {
  //     unListen();
  //   }
  // }, []);

  useEffect(() => {
    if (projectId && Number.isInteger(parseInt(projectId))) {
      const uuid = GenericUtils.getProjectUuid(parseInt(projectId), projects);
      if (uuid) {
        dispatch(setActiveProjectId(uuid));
        dispatch(loadSpecificProject(uuid, false));
      }
    }
  }, [projectId])

  // Set the page title when switching projects.
  useEffect( () => {
    if (activeProject) {
      setPageTitle(activeProject.projectName);
    }
  },[activeProjectId]);

	return (
		<GridWizard context="createModel" />
	);
}

const mapStateToProps = (state: any) => {

  const activeProject = state.context.projects.find((el:any) => el.uuid === state.context.activeProjectId);

  return {
    activeProjectId: state.context.activeProjectId,
    projects: state.context.projects,
    activeProject: activeProject
  };
};

export default connect(mapStateToProps, { setActiveProjectId, setInnerNav, setPageTitle, setProjectFormMode })(GridWizardModelCreatePage);
