import * as React from "react";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {useState} from "react";

function RadioBoolElement(props: any) {

  const [value, setValue] = useState(props.defaultValue);

  const handleChange = (e:any) => {
    props.onChange(e);
    setValue(e.target.value);
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
        <FormControlLabel disabled={props.disabled} value="yes" control={<Radio checked={value === 'yes'}/>} label="Yes" onChange={handleChange} />
        <FormControlLabel disabled={props.disabled} value="no" control={<Radio checked={value === 'no'}/>} label="No" onChange={handleChange} />
      </RadioGroup>
    </FormControl>
  );
}

export default RadioBoolElement;
