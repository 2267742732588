import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {TextField} from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import React from 'react';

function DatePickerElement(props: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props.input}
        label={props.label}
        disabled={props.disabled}
        renderInput={(params) =>
          <TextField
            {...params}
            sx={props.sx}
            size={"small"}
            error={props.error}
            type="text"
          />
        }
      />
    </LocalizationProvider>
  );
}

export default DatePickerElement;
