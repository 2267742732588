import * as React from "react";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import {LoadingButton} from "@mui/lab";
import { Grid, Box } from "@mui/material";

function ActionControls(props: any) {

  const { progress, activeModelId } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}><br/></Grid>
      {
        activeModelId ? (
          <Box>
            <LoadingButton
              loading={progress === true}
              loadingPosition="start"
              variant="contained"
              color="info"
              size="medium"
              type="submit"
              className="button"
              sx={{padding: "6px 20px", textTransform: 'capitalize'}}
            >
              Save new digital model
            </LoadingButton>
          </Box>
        ) : (
          <>
            <Grid item xs={6} lg={4} xl={3}>
              <LoadingButton
                loading={progress === true}
                loadingPosition="start"
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                className="button"
                sx={{width: '100%'}}
                startIcon={<ModelTrainingIcon/>}
              >
                Generate Baseline Model
              </LoadingButton>
            </Grid>
            <Grid item xs={4} lg={3} xl={2}>
              <LoadingButton
                variant="outlined"
                color="primary"
                size="medium"
                type="submit"
                className="button"
                sx={{width: '100%'}}
                startIcon={<SaveOutlinedIcon />}
                disabled={true}
              >
                Save as Draft
              </LoadingButton>
            </Grid>
          </>
        )
      }
    </Grid>
  );
}

export default ActionControls;
