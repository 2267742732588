import { ActionType } from "../model/model";
import connector from "../api/connector";

/**
 * Initialization of the wizard steps.
 * @param steps
 */
export const setWizardSteps = (steps: Array<string>) => {
	return {
		type: ActionType.SET_WIZARD_STEPS,
		payload: { steps: steps }
	}
};

/**
 * Sets the active wizard step.
 * @param activeStep
 */
export const setActiveStep = (activeStep: number) => {
	return {
		type: ActionType.SET_ACTIVE_STEP,
		payload: { activeStep: activeStep }
	}
};

/**
 * Sets the furthest wizard step.
 * @param furthestStep
 */
export const setFurthestStep = (furthestStep: number) => {
  return {
    type: ActionType.SET_FURTHEST_STEP,
    payload: { furthestStep: furthestStep }
  }
};

/**
 * Resets wizard form.
 */
export const resetWizard = () => {
	return {
		type: ActionType.RESET_WIZARD
	}
};

/**
 * Loads suppliers.
 */
export const loadSuppliers = () => async (dispatch: Function, getState: Function) => {
  try {
    const response = await connector.get("/jsonapi/node/supplier?include=field_logo&filter[status]=1");
    dispatch({ type: ActionType.LOAD_SUPPLIERS, payload: { data: response.data } });
  }
  catch (err) {
    dispatch({ type: ActionType.LOAD_SUPPLIERS, payload: err.response });
  }
};

/**
 * Loads base components of a given type.
 * @param type
 */
export const loadBaseComponents = (type: string) => async (dispatch: Function, getState: Function) => {
	try {
		const response = await connector.get(
			`/jsonapi/component/${type}?filter[status]=1&filter[is_base]=1&fields[component--${type}]=drupal_internal__id,created,changed,name,is_base,status,category&sort=name&[offset]=1&page[limit]=50`
		);
		dispatch({ type: ActionType.LOAD_BASE_COMPONENTS, payload: {data: response.data, type: type} });
	}
	catch (err) {
		dispatch({ type: ActionType.LOAD_BASE_COMPONENTS, payload: err.response });
	}
};

/**
 * Loads tariffs from library.
 */
export const loadTariffs = () => async (dispatch: Function, getState: Function) => {
  try {
    const response = await connector.get("/jsonapi/tariff/tariff");
    dispatch({ type: ActionType.LOAD_TARIFFS, payload: response });
  }
  catch (err) {
    dispatch({ type: ActionType.LOAD_TARIFFS, payload: err.response });
  }
};

/**
 * Loads component categories.
 */
export const loadCategories = () => async (dispatch: Function, getState: Function) => {
  try {
    const response = await connector.get(`/jsonapi/taxonomy_term/category?fields[taxonomy_term--category]=drupal_internal__tid,name,weight,description,changed`
    );
    dispatch({ type: ActionType.LOAD_CATEGORIES, payload: { data: response.data } });
  }
  catch (err) {
    dispatch({ type: ActionType.LOAD_CATEGORIES, payload: err.response });
  }
};

/**
 * Loads objectives.
 */
export const loadObjectives = () => async (dispatch: Function, getState: Function) => {
  try {
    const response = await connector.get(`/jsonapi/taxonomy_term/objectives?sort=weight`);
    dispatch({ type: ActionType.LOAD_OBJECTIVES, payload: { data: response.data } });
  }
  catch (err) {
    dispatch({ type: ActionType.LOAD_OBJECTIVES, payload: err.response });
  }
};
