import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Tabs,
  Tab, Tooltip,
} from '@mui/material';
import { styled } from '@mui/styles';
import ModelOptimization from './ModelOptimization';
import ModelDetailsTab from './ModelDetailsTab';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#75BE56'
  }
}));


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          { children }
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ModelSummary = (props: any) => {
  const [value, setValue] = useState(0);
  const { activeProjectId, activeModelId } = useSelector((state:any) => state.context);

  const tabStyle = {
    textTransform: 'none',
    fontSize: '1rem'
  } as const;

  useEffect(() => {
    setValue(0);
  }, [activeProjectId, activeModelId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: '0 40px', maxHeight: 'calc(100vh - 70px)', overflow: 'auto' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="Model Management Tabs">
          <Tab label="Model Details" sx={tabStyle} {...a11yProps(0)} />
          {/*<Tab label="Reports" sx={tabStyle} {...a11yProps(1)} />*/}
          {
            !props.model.soeModelId
            ?
              <Tooltip title={!props.model.soeModelId ? 'Not available' : 'Optimize your model'} arrow>
                <span>
                  <Tab label="Model Optimization" sx={tabStyle} {...a11yProps(1)} disabled />
                </span>
              </Tooltip>
              :
              <Tab label="Model Optimization" sx={tabStyle} {...a11yProps(1)} />
          }
          <Tab label="Sensitivity Analysis" sx={tabStyle} {...a11yProps(2)} disabled />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        {(props.model && props.activeProject) && <ModelDetailsTab />}
      </TabPanel>
      {/*<TabPanel value={value} index={1}>*/}
      {/*  <>*/}
      {/*  </>*/}
      {/*</TabPanel>*/}
      <TabPanel value={value} index={1}>
        {(props.model && props.activeProject) && <ModelOptimization model={props.model} project={props.activeProject} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
      </TabPanel>
    </Box>
  )
}

export default ModelSummary;
