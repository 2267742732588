import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import {Field} from "redux-form";
import SelectElement from "../form/select";
import {Button} from "@mui/material";
import CheckBoxElement from "../form/checkbox";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextWithUnitsElement from "./textwithunits";
import {Grid} from "@mui/material";

const MultipleComponents = (props:any) => {

  const { fields, components, categories } = props;

  type backup = {
    id: number,
    uuid: string,
    isBackup: boolean
  };

  const [items, setItems] = useState<backup[]>([]);

  /**
   * keep track of items isBackup flag.
   */
  const updateItems = useCallback(() => {
    const data = fields.getAll();
    if (data) {
      let flags:backup[] = [];
      data.map((field:any, index:any) => {
        const selectedComponent = components.find((elm:any) => elm.id === field.uuid);
        const selectedCategory = getComponentCategory(selectedComponent,categories);
        if (selectedCategory.hasOwnProperty('name')) {
          flags.push({ id: index, uuid: field.uuid, isBackup: selectedCategory.name.toLowerCase() === 'generator' });
        }
        setItems(flags);
        return flags;
      });
    }
  }, [categories, components, fields]);

  useEffect(() => {
    updateItems();
  }, [props.fields, updateItems]);

  if (fields.length === 0) {
    fields.push({});
  }

  return (
    <>
        {
          fields.map((component:any, index:any) => (
                  <Grid container spacing={1} key={index} sx={{
                    background: '#F5F5F5',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    marginLeft: 0,
                    padding: '15px 0px 10px 10px',
                    border: '1px solid #cfcfcf',
                    boxShadow: '0 1px 3px #cfcfcf',
                    '& .MuiInputLabel-root': {
                      marginTop: '-7px'
                    }
                  }}>
                    <Grid item xs={12} sm={5}>
                      <Field name={`${component}.uuid`}
                             component={SelectElement}
                             components={components}
                             categories={categories}
                             label={props.label}
                             showCategory={true}
                             required
                             disabled={props.disabled}
                      />
                    </Grid>
                    {
                      components && components.length > 0 && components[0].type === 'component--producer'
                        ?
                        <>
                          <Grid item xs={12} sm={3}>
                            <Field name={`${component}.sizing`}
                                   component={TextWithUnitsElement}
                                   label="Size"
                                   units="KW"
                                   value={0}
                                   disabled={props.disabled}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Field name={`${component}.isBackup`}
                                   component={CheckBoxElement}
                                   label="Backup?"
                                   checked={false}
                                   disabled={(items[index] && !items[index].isBackup) || props.disabled}
                            />
                          </Grid>
                        </>
                        :
                        <>
                          <Grid item xs={12} sm={3}>
                            <Field name={`${component}.capacity`}
                                   component={TextWithUnitsElement}
                                   label="Size"
                                   units="KWh"
                                   value={0}
                                   disabled={props.disabled}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Field name={`${component}.power`}
                                   component={TextWithUnitsElement}
                                   label="Power"
                                   units="KW"
                                   value={0}
                                   disabled={props.disabled}
                            />
                          </Grid>
                        </>
                    }
                      <Grid item xs={12} sm={1}>
                        <FormControl>
                          <HighlightOffIcon onClick={() => fields.remove(index)} sx={{
                            marginTop: '10px',
                            marginLeft: '40px',
                            transition: 'margin-top ease 0.2s',
                            '&:hover': {
                              cursor: 'pointer',
                              marginTop: '14px'
                            },
                            fill: '#5f5f5f'
                          }}/>
                        </FormControl>
                     </Grid>
                  </Grid>
          ))
        }
      {
        fields.length < 5
          ?
          <Grid item xs={6}>
            <Button disabled={props.disabled} variant="contained" onClick={() => fields.push({})}>{props.buttonLabel}</Button>
          </Grid>
          :
            ''
      }
    </>
  );
}

/**
 * Helper function for fetching a component's category.
 * @param component
 * @param categories
 * @todo Use the util. provided one.
 */
const getComponentCategory = (component:any, categories:any) => {
  if (component && component.relationships.category && component.relationships.category.data && component.relationships.category.id) {
    return categories.find( (el:any) => el.uuid === component.relationships.category.data.id);
  }
  return [];
};

export default MultipleComponents;
