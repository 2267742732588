import * as React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

function CheckBoxElementNew(props: any) {

    const [checked, setChecked] = React.useState(props.defaultChecked);

    const handleChange = (event: any) => {
      setChecked(event.target.checked);
      props.updateState(event.target.checked);
    };

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
    );
}

export default CheckBoxElementNew;
