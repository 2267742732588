import {Model} from "../model/model";
import {Box, Typography} from "@mui/material";
import React from "react";

export default class Utils {

  static getSoeParams = (soeConfig: any): [] => {
    let soeParams:any = [];
    const {body} = soeConfig;
    soeParams = body.map((parameterGroup: any, index: number) => {
      return parameterGroup.params.filter((x: any) => x.type !== 'spacer').map((param: any) => param.props.name);
    });
    soeParams = soeParams.reduce((a:any, b:any) => a.concat(b), []);
    return soeParams;
  }

  static getSoeOutput = (soeConfig: any): [] => {
    let soeParams:any = [];
    const {output} = soeConfig;
    soeParams = output.params.map((param: any) => param.props.name);
    return soeParams;
  }

  static getSoeOutputMeta = (soeConfig: any): [] => {
    let soeParams:any = [];
    const {output} = soeConfig;

    output.params.forEach((param: any, index: number) => {
      soeParams[param.props.name] = {
        'label': param.props.label,
        'units': param.props.units
      };
    });
    return soeParams;
  }

  static getSoeParamsMeta = (soeConfig: any): [] => {
    let soeParams:any = [];
    const {body} = soeConfig;
    body.forEach((parameterGroup: any, index: number) => {
      parameterGroup.params.forEach((param: any, index: number) => {
        soeParams[param.props.name] = {
          'label': param.props.label,
          'units': param.props.units
        };
      });
    });
    return soeParams;
  }

  /**
   * Constructs the payload for an optimization call.
   * @param model
   * @param formValues
   */
  static getOptimizationPayload = (model: Model, formValues: any) => {

    let payload = {
      "model_id": model.soeModelId,
      "operation": "optimize",
      "decision_vars": [
        "battery_size"
      ],
      "decision_vars_init": [
        0
      ],
      "decision_vars_bounds": [
        formValues.battery_size
      ],
      "objective_fn": formValues.objective.toLowerCase(),
      "objective_target": "maximize",
      "algorithm": {
        "method": "TNC",
        "options": {"xtol": 0.01, "ftol": 0.001}
      },
      "parameters": [
        {
          "pv_size": formValues.pvSize,
        }
      ],
      "response_includes": ["irr", "npv", "battery_size"],
      "constraints": []
    };

    // Load the SOE config. and apply it to the payload.
    const config:any = model.soeConfig ? model.soeConfig : false;
    if (config) {
      payload.parameters = config.optimize.parameters ?? payload.parameters;
      payload.response_includes = config.optimize.response_includes ?? payload.response_includes;
      payload.decision_vars_init = config.optimize.decision_vars_init ?? payload.decision_vars_init;
    }
    return payload;
  }

  /**
   * TBD
   * @param model
   * @param key
   * @param property
   */
  static getOptimizationBounds = (model: Model, key: string, property: string) => {
    const config:any = model.soeConfig ? model.soeConfig : false;
    return config.optimize[property][0] ? config.optimize[property][0] : false;
  }

  /**
   * Formats parameters for display.
   * @param param
   * @param units
   */
  static formatParameter = (param: any, units: string) => {
      switch (units) {
        case '%':
          param *= 100;
          param = Number.parseFloat(param).toFixed(2);
          return param;
        default:
          param = Number.parseFloat(param).toFixed(2);
          const nf = Intl.NumberFormat();
          return nf.format(param);
      }
  }

  static formatInputParams = (params: any, config: any): [] => {
      const meta:any = Utils.getSoeParamsMeta(config);
      for (const [key, value] of Object.entries(params)) {
        if (meta[key].units === '%') {
          params[key] = params[key] * 100;
        }
      }
      return params;
  }

  static reverseFormatInputParams = (params: any, config: any): [] => {
    const meta:any = Utils.getSoeParamsMeta(config);
    for (const [key, value] of Object.entries(params)) {
      if (meta[key].units === '%') {
        params[key] = params[key] / 100;
      }
    }
    return params;
  }

  /**
   * Constructs the payload for a simulation call.
   * @param model
   * @param init
   * @param parameters
   */
  static getSimulationPayload = (model: Model, init: boolean = false, parameters: any = []) => {

    let payload = {
      "model_id": model.soeModelId,
      "operation": "simulate",
      "decision_vars": [],
      "decision_vars_init": [],
      "decision_vars_bounds": [],
      "objective_fn": "",
      "objective_target": "",
      "parameters": parameters,
      "algorithm": {},
      "response_includes": ["irr", "npv", "capex"],
      "constraints": []
    };

    // Load the SOE config. and apply it to the payload.
    const config:any = model.soeConfig ? model.soeConfig : false;
    if (config) {
      payload.response_includes = init ? Utils.getSoeParams(config) : Utils.getSoeOutput(config);
    }
    return payload;
  }

  static formValuesToParams = (formValues: any) => {
    return Object.keys(formValues).map(key => {
      let obj:any = {};
      obj[key] = formValues[key];
      return obj;
    });
  };

  /**
   * Extracts the optimization progress, from the SOE's
   * progress messages. @todo to be changed when SOE returns
   * a more structured object.
   * @param progressMsg
   * @param override
   */
  static getOptimizationProgress = (progressMsg: string, override?: number) => {
    let progress = {
      currentPasses: 0,
      totalPasses: 1
    };

    progress.currentPasses = (progressMsg
      .match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || [] )
      .map(function (v) {return +v;}).shift() || 0;
    progress.totalPasses = (progressMsg
      .match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || [] )
      .map(function (v) {return +v;}).pop() || 0;

    if (override) {
      return override;
    }
    return 0.97 * Math.round(progress.currentPasses / progress.totalPasses * 100);
  };

  static getOptimizationPollingUrl = (activeProjectId: string) => {
    return '/jsonapi/optimization/optimization?include=project_id&filter[project_id.id]=' + activeProjectId + '&fields[optimization--optimization]=name,drupal_internal__id,created,changed,internal_status,status_reason,revision_log_message,revision_created,external_id&sort=-changed';
  };
}
