import {Action, ActionType, Auth, Context, Supplier, Wizard} from "../model/model";
import createReducer from "./createReducer";

const initialState = {
	steps: [],
	activeStep: 0,
	consumer: [],
	producer: [],
	storage: [],
  categories: [],
  objectives: [],
  tariffs: [],
  suppliers: []
};

export const wizardReducer = createReducer<Wizard>(initialState, {
	[ActionType.SET_WIZARD_STEPS](state: Wizard, action: Action<any>) {
		return {...state, steps: action.payload.steps};
	},
	[ActionType.SET_ACTIVE_STEP](state: Wizard, action: Action<any>) {
		return {...state, activeStep: action.payload.activeStep};
	},
	[ActionType.LOAD_BASE_COMPONENTS](state: Wizard, action: Action<any>) {
		const type = action.payload.type;
		switch (type) {
			case 'consumer':
				return {...state, consumer: action.payload.data};
			case 'producer':
				return {...state, producer: action.payload.data};
			case 'storage':
				return {...state, storage: action.payload.data};
			default:
				return {...state};
		}
	},
  [ActionType.LOAD_CATEGORIES](state: Wizard, action: Action<any>) {

    let categories:any = [];
    if (action.payload && action.payload.data.data) {
      categories = action.payload.data.data.map((category: any, index: any) => {
        return {
          id: category.attributes.drupal_internal__tid,
          uuid: category.id,
          name: category.attributes.name,
          description: category.attributes.description,
          weight: category.attributes.weight,
          changed: category.attributes.changed,
        }
      });
    }

    return {...state, categories: categories};

  },
  [ActionType.LOAD_OBJECTIVES](state: Wizard, action: Action<any>) {
    const objectives = action.payload.data.data.map((objective: any, index: any) => {
      return {
        id: objective.attributes.drupal_internal__tid,
        uuid: objective.id,
        name: objective.attributes.name,
        description: objective.attributes.description,
        weight: objective.attributes.weight,
        changed: objective.attributes.changed,
      }
    });

    return {...state, objectives: objectives};

  },
  [ActionType.LOAD_TARIFFS](state: Context, action: Action<any>) {
    if (action.payload.data.data) {
      return {...state, tariffs: action.payload.data.data, errors: {}};
    }
  },
  [ActionType.LOAD_SUPPLIERS](state: Wizard, action: Action<any>) {
	  let suppliers = [];
    suppliers = action.payload.data.data.map((supplier: any, index: any) => {
      const supplierInstance:Supplier = {
        id: supplier.attributes.drupal_internal__nid,
        uuid: supplier.id,
        name: supplier.attributes.title,
        created: supplier.attributes.created,
        changed: supplier.attributes.changed,
        address: supplier.attributes.field_address,
        email: supplier.attributes.field_email,
        phone: supplier.attributes.field_phone,
        servicesOffered: supplier.attributes.field_services_offered,
        website: supplier.attributes.field_website.uri,
        logo: action.payload.data.included ? process.env.REACT_APP_BASE_URL + action.payload.data.included[0].attributes.uri.url : ''
      };
      return supplierInstance;
    });
    return {...state, suppliers: suppliers};
  },
	[ActionType.RESET_STORE](state: Wizard, action: Action<Auth>) {
		return {...state, ...initialState};
	}
});
