import FormControl from "@mui/material/FormControl";
import {FormHelperText, InputAdornment, OutlinedInput} from "@mui/material";
import * as React from "react";

function TextWithUnitsElement(props: any) {

  const {error} = props.meta;

  return (
      <FormControl>
        { props.labelPositionTop && <FormHelperText sx={{ml: 0}}>{props.label}</FormHelperText>}
        <OutlinedInput
          {...props.input}
          size={props.size || "small"}
          endAdornment={<InputAdornment position="end" style={{fontSize: '11px !important'}}>{props.units}</InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': props.units,
          }}
          disabled={props.disabled}
          error={error}
        />
        { !props.labelPositionTop && <FormHelperText sx={{ml: 0}}>{props.label}</FormHelperText>}
        {
          error ?
            <FormHelperText sx={{color: '#D32F2F'}}>{error}</FormHelperText>
            :
            ''
        }
      </FormControl>
  );
}

export default TextWithUnitsElement;
